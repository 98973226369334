import React from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { EXHIBITOR_API, VISITOR_API } from "../../../constants/constants";
const fetchItems = (page = 1, searchQ, filterQ = "") => {
  return axios
    .get(
      `${VISITOR_API}xz-tm-cv-vss?page=${page}&sq=${searchQ}&usertype=${filterQ}`
    )
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useSpotVisitors = (page, searchQ, usertype) => {
  const queryInfo = useQuery(
    ["get-visitors-onspot", page, searchQ, usertype],
    () => fetchItems(page, searchQ, usertype),
    {
      staleTime: 0, // Data will be considered stale after 5 minutes
      retry: 3,
    }
  );
  return {
    ...queryInfo,
    refetchVisitors: queryInfo.refetch, // Extract refetch function from queryInfo
  };
};

export default useSpotVisitors;
