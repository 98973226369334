import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RdDesignationsSelect from "../../../../../visitorregt/components/previewpage/RdDesignationsSelect";
import PhoneInput from "react-phone-number-input";
import { TITLES, getHeader } from "../../../../../utility/utility";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { isValidEmail } from "../../../../../visitorregt/utils/utils";
import axios from "axios";
import { VISITOR_API } from "../../../../../constants/constants";
import { Editor } from "react-draft-wysiwyg";
import { ContentBlock, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
const EditLookingFor = ({ profile, setProfile, setShow }) => {
  const [saving, setSaving] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    let myqn = profile.lookingFor;
    if (myqn) {
      let finalMyQn = "";
      if (myqn.includes("<img src") && myqn.includes("<p></p>")) {
        finalMyQn = myqn;
      } else if (myqn.includes("<img src")) {
        let pos = myqn.indexOf("<img src");
        let mqnA = myqn.split("");
        mqnA.splice(pos, 0, "<p></p>");
        finalMyQn = mqnA.join("");
      } else {
        finalMyQn = myqn;
      }
      const contentBlock = htmlToDraft(finalMyQn);
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(contentBlock.contentBlocks)
        )
      );
    }
  }, []);
  const handleInputChange = (e) => {
    setProfile((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleStateChange = (key, value) => {
    setProfile((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const save = () => {
    const checks = [{ attr: "lookingFor", message: "Please type something" }];

    for (let check of checks) {
      const value = profile[check.attr];
      if (
        (check.validator && !check.validator(value)) ||
        value === "" ||
        value === undefined
      ) {
        toast.error(check.message);
        return;
      }
    }
    setSaving(true);
    axios
      .post(
        VISITOR_API + "update-visitor",
        {
          lookingFor: profile.lookingFor,
        },
        getHeader()
      )
      .then((res) => {
        setSaving(false);
        toast.success(res.data.message);
        setShow((prev) => !prev);
      })
      .catch((error) => {
        setSaving(false);
        if (error.response) {
          // The request was made and the server responded with a status code outside the range of 2xx
          console.error("Error Data:", error.response.data);
          console.error("Error Status:", error.response.status);
          console.error("Error Headers:", error.response.headers);
          toast.error(
            `Error: ${error.response.data.message || "Something went wrong"}`
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Error Request:", error.request);
          toast.error("No response from server. Please try again later.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
          toast.error("An error occurred. Please try again.");
        }
      });
  };
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    handleStateChange(
      "lookingFor",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };
  return (
    <div>
      <div className="visitor-form-container">
        <div className="visitor-items-h">I am Looking for?</div>
        <div>
          <Editor
            editorState={editorState}
            placeholder="Type what you are looking for..."
            onEditorStateChange={onEditorStateChange}
          />
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <LoadingButton
          onClick={save}
          sx={{ m: 2, width: 200 }}
          variant="contained"
        >
          Save
        </LoadingButton>
      </div>
    </div>
  );
};

export default EditLookingFor;
