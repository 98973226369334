// ChatApp.js
import React from "react";
import {
  Box,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  TextField,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import SearchIcon from "@mui/icons-material/Search";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

function ChatApp() {
  // Dummy chat list data
  const chats = [
    { name: "Himanshu M", message: "You: hi", time: "a year ago" },
    // Add more chat data as needed
  ];

  // Dummy messages data distinguishing between sent and received messages
  const messages = [
    { content: "Hi", time: "16:07", sent: false },
    { content: "Testing mobile app messaging", time: "16:07", sent: true },
    { content: "Ok", time: "15:03", sent: false },
    { content: "When will iOS be released", time: "15:04", sent: true },
    // Add more messages as needed
  ];

  // Function to return message bubble style based on sent or received
  const messageBubbleStyle = (sent) => ({
    maxWidth: "70%",
    marginLeft: sent ? "auto" : 0,
    marginRight: sent ? 0 : "auto",
    backgroundColor: sent ? "#e0f7fa" : "#eceff1",
    borderRadius: sent ? "20px 20px 0 20px" : "20px 20px 20px 0", // Tail-like effect for bubbles
    padding: "10px 16px",
    marginBottom: "8px",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.12)", // Soft shadow for depth
    border: sent ? "1px solid #b2ebf2" : "1px solid #cfd8dc", // Border to lift bubble off the page
    position: "relative", // For positioning the tail if needed
    "&:after": {
      // Tail effect (Optional)
      content: '""',
      position: "absolute",
      width: 0,
      height: 0,
      borderTop: "10px solid transparent",
      borderBottom: "10px solid transparent",
      ...(sent
        ? {
            left: "100%",
            borderLeft: "10px solid #e0f7fa",
          }
        : {
            right: "100%",
            borderRight: "10px solid #eceff1",
          }),
    },
  });

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Paper elevation={3} sx={{ p: 2, height: "calc(100vh - 48px)" }}>
            <TextField
              fullWidth
              placeholder="Search Chats"
              InputProps={{
                startAdornment: (
                  <IconButton position="start">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
            <Divider sx={{ my: 2 }} />
            <List sx={{ overflow: "auto" }}>
              {chats.map((chat, index) => (
                <ListItem button key={index}>
                  <ListItemAvatar>
                    <Avatar>{/* User Avatar */}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={chat.name} secondary={chat.message} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              height: "calc(100vh - 48px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">Himanshu M</Typography>
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Box>
            <Divider />
            <List sx={{ overflow: "auto", flexGrow: 1, p: 3 }}>
              {messages.map((message, index) => (
                <ListItem key={index}>
                  <Box sx={messageBubbleStyle(message.sent)}>
                    <ListItemText primary={message.content} />
                    <Typography variant="caption">{message.time}</Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
            <Divider />
            <Box
              component="form"
              sx={{ display: "flex", alignItems: "center", p: 2 }}
            >
              <TextField
                fullWidth
                size="small"
                multiline
                maxRows={4}
                placeholder="Enter your message"
                variant="outlined"
                sx={{ mr: 1 }}
              />
              <Button
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
              >
                Send
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ChatApp;
