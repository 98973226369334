import { useQuery } from "react-query";
import axios from "axios";
import { getAdminHeader, getCommonHeaderVE } from "../../../../utility/utility";
import { ADMIN_API } from "../../../../constants/constants";

const fetchExhibitor = (hall, stand) => {
  return axios
    .get(
      `${ADMIN_API}/get-exhibitor-by-hall-stand?hallNumber=${hall}&standNumber=${stand}`,
      getCommonHeaderVE()
    )
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useExhibitorByHallStand = (hall, stand) => {
  return useQuery(
    ["get-exhibitor-by-hall-stand", hall, stand],
    () => fetchExhibitor(hall, stand),
    {
      staleTime: 5 * 60 * 1000, // Data will be considered stale after 5 minutes
      retry: 3, // If the query fails, it will retry up to 3 times
    }
  );
};

export default useExhibitorByHallStand;
