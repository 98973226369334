import React from "react";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import {
  Avatar,
  Box,
  Typography,
  Stack,
  Tooltip,
  Card,
  CardActionArea,
} from "@mui/material";

const MeetingCard = ({ meetingItem, onClick }) => {
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const getFullName = (person) => `${person.firstName} ${person.lastName}`;

  return (
    <Card
      sx={{
        marginTop: "12px",
        "&:hover": {
          backgroundColor: "#f4f4f4", // Change this color for hover effect
        },
      }}
    >
      <CardActionArea onClick={() => onClick && onClick(meetingItem)}>
        <Box
          sx={{
            border: "1px solid #f9e6d3",
            padding: "8px",
            borderRadius: "8px",
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 600, mt: 1 }}>
            {meetingItem.data.subject}
          </Typography>
          <Stack direction="row" spacing={2}>
            <Box sx={{ color: "#777" }}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <AlarmOnIcon fontSize="small" />
                <Typography variant="body2">
                  {formatTime(meetingItem.data.selectedDate)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <CalendarTodayIcon fontSize="small" />
                <Typography variant="body2">
                  {formatDate(meetingItem.data.selectedDate)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <AlignVerticalBottomIcon fontSize="small" />
                <Typography variant="body2">
                  {meetingItem.data.meeting_type}
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ alignSelf: "center", pl: 2, color: "#777" }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                Participants
              </Typography>
              <Stack direction="row" spacing={1}>
                <Tooltip title={getFullName(meetingItem.sender)}>
                  <Avatar
                    src={meetingItem.sender.profile_image}
                    alt={getFullName(meetingItem.sender)}
                  />
                </Tooltip>
                <Tooltip title={getFullName(meetingItem.recipient)}>
                  <Avatar
                    src={meetingItem.recipient.profile_image}
                    alt={getFullName(meetingItem.recipient)}
                  />
                </Tooltip>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default MeetingCard;
