import axios from "axios";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { toast } from "react-toastify";
import { Box, Card, CardContent } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RegHeader from "../../../visitorregt/components/headers/regHeader";
import { VISITOR_API } from "../../../constants/constants";
export const getCategoryName = (userObj) => {
  const category = userObj.user_type
    ? userObj.user_type
    : "Pre-Registered Visitor";
  return category;
};

function Hall1ScanService() {
  const [currentUser, setCurrentUser] = useState();
  const [scannedUsers, setCurrentUsers] = useState([]);
  const [barcodeId, setBarcodeId] = useState("");
  const [users, setUsers] = useState();
  const [isPrinting, setIsPrinting] = useState(false);
  const [successScanned, setSuccessScanned] = useState([]);
  const inputRef = useRef();
  const summaryData = useMemo(() => {
    let data = {
      VISITOR: 0,
      EXHIBITOR: 0,
      DELEGATION: 0,
      VIP: 0,
      EC: 0,
      SERVICE: 0,
      MEDIA: 0,
      STUDENT: 0,
    };

    scannedUsers.forEach((user) => {
      let userType = user.user_type ? user.user_type.toUpperCase() : "VISITOR";
      if (data.hasOwnProperty(userType)) {
        data[userType] += 1;
      }
    });

    return data;
  }, [scannedUsers]);

  useEffect(() => {
    // If not printing, focus the input field
    if (!isPrinting && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isPrinting]);
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US"); // Format as per your locale
  }

  function formatTime(dateString) {
    const time = new Date(dateString);
    return time.toLocaleTimeString("en-US", { hour12: true }); // Format as per your locale and preferences
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      inputRef.current.value = "";
      inputRef.current?.focus();
    }
  };

  const handleScanInput = (event) => {
    const scannedValue = event.target.value;
    const barcodeId = extractBarcodeId(scannedValue);
    if (barcodeId && barcodeId.length >= 14) {
      // if (isValidMongoDbId(barcodeId)) {
      //   toast.success("Successfully Scanned", { autoClose: 1 });
      //   inputRef.current.value = "";
      // } else {
      //   if (barcodeId.length === 14) {
      //     fetchUserDetails(barcodeId);
      //   }
      // }
      fetchUserDetails(barcodeId);
    }
  };

  function extractBarcodeId(vCardString) {
    const noteRegex = /Barcode ID: ((\d{14})|([a-f0-9]{24}))/i;
    const match = vCardString.match(noteRegex);
    return match ? match[1] : null;
  }

  let lastFetchTime = 0;
  const fetchDebounceTime = 1000; // Set debounce time (in milliseconds)
  const fetchUserDetails = async (scannedData) => {
    const currentTime = new Date().getTime();
    // Only proceed if enough time has passed since the last fetch
    if (currentTime - lastFetchTime > fetchDebounceTime) {
      lastFetchTime = currentTime;
      const barcodeId = scannedData; //extractBarcodeId(scannedData);
      if (barcodeId) {
        const dataToUpdate = {
          barcodeId: barcodeId,
        };
        try {
          const { data } = await axios.post(
            `${VISITOR_API}security-scan-hall-one`,
            dataToUpdate
          );
          inputRef.current.disabled = false;
          inputRef.current.value = "";
          inputRef.current?.focus();
          toast.success(data.message, { autoClose: 1 });
          fetchUsers();
        } catch (error) {
          toast.error(error.message, { autoClose: 1 });
        }
      } else {
        toast.error("Invalid QR", { autoClose: 1 });
      }
    }
  };
  const fetchUsers = async () => {
    try {
      const { data } = await axios.get(
        VISITOR_API + "get-scanned-users-hall-one"
      );
      setCurrentUsers(data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Box
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <RegHeader />
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={1280}
        width={"100%"}
        margin="auto"
        gap={1}
        paddingBottom={1}
        flexGrow={1}
      >
        <Card sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <CardContent
            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            <h3>Scanned List</h3>
            <TableContainer
              component={Paper}
              style={{ flexGrow: 1, maxHeight: "192px", overflowY: "auto" }}
            >
              <Table aria-label="scanned list table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Bar Code</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Scanned Date</TableCell>
                    <TableCell>Scanned Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scannedUsers?.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">
                        {row.userInfo[0]?.title} {row.userInfo[0]?.firstName}{" "}
                        {row.userInfo[0]?.lastName} {row.userInfo[0]?.name}
                      </TableCell>
                      <TableCell>{row.barcodeId}</TableCell>
                      <TableCell>
                        {row.userInfo[0].user_type
                          ? row.userInfo[0].user_type
                          : "Regular Visitor"}
                      </TableCell>
                      <TableCell>{formatDate(row.createdOn)}</TableCell>
                      <TableCell>{formatTime(row.createdOn)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <h3>Statitics</h3>
            <TableContainer component={Paper}>
              <Table aria-label="summary table">
                <TableHead>
                  <TableRow>
                    <TableCell>Visitor</TableCell>
                    <TableCell>Exhibitor</TableCell>
                    <TableCell>Delegate</TableCell>
                    <TableCell>VIP</TableCell>
                    <TableCell>EC</TableCell>
                    <TableCell>SERVICE</TableCell>
                    <TableCell>MEDIA</TableCell>
                    <TableCell>STUDENT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {Object.entries(summaryData).map(([category, count]) => (
                      <TableCell key={category}>{count}</TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer> */}
          </CardContent>
        </Card>
        <Box style={{ display: "block" }} ml={"auto"} mr={"auto"} mb={4}>
          <input
            ref={inputRef}
            id="barcodeInput"
            type="text"
            // value={barcodeId}
            onChange={handleScanInput}
            onKeyDown={handleKeyDown}
            placeholder="Scan Badge"
            style={{
              padding: "10px",
              fontSize: "16px",
              width: "300px",
              textAlign: "center",
              borderRadius: "4px",
              border: "2px solid #000",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Hall1ScanService;
