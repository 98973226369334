import React from "react";
import useMeetings from "../hooks/useMeetings";
import MuPb from "../../../../widgets/MuPb";
import { Box, Typography } from "@mui/material";
import MeetingCard from "../../../../widgets/MeetingCard";
import { useNavigate } from "react-router-dom";
import BackBtn from "../../admin/widgets/BackBtn";

const AllMeetings = ({ user }) => {
  const navigate = useNavigate();
  const {
    data: meetingsData,
    isLoading: isMloading,
    isError: isMerror,
    error: mError,
  } = useMeetings(user.username);
  if (isMloading) {
    return <MuPb />;
  }
  const onSelect = (item) => {
    navigate(`/meeting/${item._id}`);
  };
  return (
    <Box pl={2} pr={2}>
      <BackBtn />
      <Typography textAlign={"center"} mt={2} variant="h5" fontWeight={"bold"}>
        All Meetings
      </Typography>
      <Box maxWidth={600} ml={"auto"} mr={"auto"}>
        {meetingsData?.map((item, i) => (
          <MeetingCard onClick={onSelect} meetingItem={item} />
        ))}
      </Box>
    </Box>
  );
};

export default AllMeetings;
