import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  CssBaseline,
  CircularProgress,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { API_ENDPOINT } from "../../constants/constants";
import imtexLogo from "../../assets/imtex_log_header_01.png";

const SetPasswordActivationPage = () => {
  const params = useParams();
  const { code } = params;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [linkLoading, setLinkLoading] = useState(true);
  const [isPset, setPset] = useState(false);

  const [isValid, setIsValid] = useState(true);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    checkExpiry();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password.trim() || !confirmPassword.trim()) {
      toast.error("Password fields cannot be empty.");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords don't match.");
      return;
    }
    setMyPassword();
  };

  async function setMyPassword() {
    setLoading(true);
    try {
      const response = await axios.post(API_ENDPOINT + "visitor/set-password", {
        token: code,
        password: password,
      });
      setLoading(false);
      setPset(true);
      console.log("Data:", response.status);
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error("Invalid request!");
        //console.log("Response error: ", error.response.status);
      } else if (error.request) {
        //console.log("No response received:", error.request);
        toast.error("Invalid request!");
      } else {
        //console.log("Error", error.message);
        toast.error("Invalid request!");
      }
    }
  }
  async function checkExpiry() {
    try {
      const response = await axios.post(API_ENDPOINT + "visitor/check-link", {
        token: code,
      });
      setLinkLoading(false);
      setIsValid(true);
      console.log("Data:", response.status);
    } catch (error) {
      setLinkLoading(false);
      setIsValid(false);
      if (error.response) {
        toast.error("Invalid request!");
        //console.log("Response error: ", error.response.status);
      } else if (error.request) {
        //console.log("No response received:", error.request);
        toast.error("Invalid request!");
      } else {
        //console.log("Error", error.message);
        toast.error("Invalid request!");
      }
    }
  }

  if (linkLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh" // Take up the full viewport height
      >
        <CircularProgress />
      </Box>
    );
  }
  if (!isValid) {
    return (
      <Container component="main" maxWidth="xs">
        <Typography variant="h4" align="center" color="error">
          Invalid Link!
        </Typography>
      </Container>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Container component="main" maxWidth="md" sx={{ margin: "16px auto" }}>
        {/* <Typography variant="h5" textAlign={"center"}>
          Set New Password for IMTEX FORMING 2024, TOOLTECH 2024 & DIGITAL
          MANUFACTURING 2024
        </Typography> */}
        <img
          src={imtexLogo}
          alt=""
          style={{
            display: "block",
            margin: "10px auto",
            width: "100%",
            maxWidth: "500px",
          }}
        />
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Set New Password
        </Typography>
      </Container>
      {!isPset ? (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div>
            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="New Password"
                type="password"
                name="password"
                autoComplete="new-password"
                autoFocus
                value={password}
                onChange={handlePasswordChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              <LoadingButton
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Set Password
              </LoadingButton>

              {/* <p>This link expires in 5 minutes</p> */}
            </form>
          </div>
        </Container>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h4 color="green">Password set Successfully</h4>
          <p>
            You can now login to the app{" "}
            <a href="https://imtex2024-imtma.expoplanner.in/">here</a>
          </p>
        </div>
      )}
    </div>
  );
};

export default SetPasswordActivationPage;
