import React from "react";
import ComingSoon from "../exhibitor/ComingSoon";
import PasswordReset from "./PasswordReset";
import { Box, ThemeProvider } from "@mui/material";
import { ExpoTheme } from "../../../theme/theme";

const Settings = () => {
  return (
    <div className="rd-dash-content">
      <ThemeProvider theme={ExpoTheme}>
        <Box sx={{ maxWidth: "720px", ml: "auto", mr: "auto" }}>
          <PasswordReset />
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default Settings;
