import { useQuery } from "react-query";
import axios from "axios";
import { EXHIBITOR_API } from "../../../../../constants/constants";
import {
  getCommonHeaderVE,
  getExhibitorHeader,
} from "../../../../../utility/utility";

const fetchExhibitors = (page = 1, sq) => {
  return axios
    .get(
      `${EXHIBITOR_API}get-exhibitors?page=${page}&sq=${sq}`,
      getCommonHeaderVE()
    )
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useExhibitors = (page, sq) => {
  return useQuery(
    ["get-exhibitors-explore", page, sq],
    () => fetchExhibitors(page, sq),
    {
      staleTime: 0, // Data will be considered stale after 5 minutes
      retry: 3, // If the query fails, it will retry up to 3 times
    }
  );
};

export default useExhibitors;
