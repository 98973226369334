import React from "react";
import {
  Paper,
  Button,
  Typography,
  TextField,
  Box,
  Container,
  Alert,
} from "@mui/material";
import { CloudDownload, CloudUpload } from "@mui/icons-material";
import NextPrev from "./reuse/NextPrev";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
const Manual = () => {
  return (
    <Container>
      <Paper elevation={3} style={{ padding: "2rem" }}>
        <Box mb={2}>
          <Typography variant="h5">Exhibitor Manual</Typography>
        </Box>
        {/* <Box mb={2} style={{ backgroundColor: "#FFEB3B", padding: "1rem" }}>
          <Typography>
            <strong>
              ⚠️ Page not yet submitted, the deadline is 18 Jan, 2024 .
            </strong>
          </Typography>
        </Box> */}
        {/* <Alert severity="warning">
          <Typography variant="subtitle1">
            Page not yet submitted, the deadline is 23 Dec, 2024.
          </Typography>
        </Alert> */}

        <Typography variant="h6" gutterBottom>
          Required files and documents
        </Typography>
        <Typography gutterBottom>
          Download files, upload required documents and attach forwarded files
          if needed
        </Typography>

        <Box my={3}>
          <Typography variant="subtitle1">FILES TO DOWNLOAD</Typography>
          <Button
            sx={{ mt: 2, mb: 2, textTransform: "none" }}
            variant="outlined"
            startIcon={<PictureAsPdfIcon color="error" />}
            component="a" // Use <a> tag as the root component of the Button
            href="https://s3.ap-south-1.amazonaws.com/expoplanner.in/uploads/IMTEX+Forming+2024+Exhibitor+Manual+(3).pdf" // Link to the PDF
            target="_blank" // Open in a new tab
            rel="noopener noreferrer" // For security reasons when using target="_blank"
          >
            IMTEX 2024 & Tooltech 2024 Exhibitor Manual.pdf
          </Button>
          {/* <Typography style={{ marginTop: "1rem" }}>1.88 MB</Typography> */}
        </Box>

        {/* <Box my={3}>
          <Typography variant="subtitle1">FORWARDED FILES</Typography>
          <Button
            variant="outlined"
            startIcon={<CloudUpload />}
            style={{ marginTop: "1rem" }}
          >
            Upload your respond
          </Button>
        </Box>

        <Box mt={3} display="flex" justifyContent="center">
          <Button variant="contained" color="primary">
            Submit
          </Button>
        </Box> */}
      </Paper>
    </Container>
  );
};

export default Manual;
