import { useQuery } from "react-query";
import axios from "axios";
import {
  getAdminHeader,
  getExhibitorHeader,
} from "../../../../utility/utility";
import { ADMIN_API, EXHIBITOR_API } from "../../../../constants/constants";

const fetchItem = async () => {
  try {
    const res = await axios.get(
      `${EXHIBITOR_API}get-profile`,
      getExhibitorHeader()
    );
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const useExhibitorProfile = () => {
  const queryInfo = useQuery(["get-exhibitor"], () => fetchItem(), {
    staleTime: 20 * 60 * 1000, // Data will be considered stale after 5 minutes
    retry: 0,
  });

  const refetchExhibitorProfile = async () => {
    await queryInfo.refetch();
  };

  return {
    ...queryInfo,
    refetchExhibitorProfile,
  };
};

export default useExhibitorProfile;
