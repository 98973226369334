import { useQuery } from "react-query";
import axios from "axios";
import { EXHIBITOR_API } from "../../../../../constants/constants";
import { getExhibitorHeader } from "../../../../../utility/utility";

const fetchItems = (page = 1, searchQ) => {
  return axios
    .get(
      `${EXHIBITOR_API}get-visitors?page=${page}&sq=${searchQ}`,
      getExhibitorHeader()
    )
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useVisitors = (page, searchQ) => {
  const queryInfo = useQuery(
    ["get-visitors-explore", page, searchQ],
    () => fetchItems(page, searchQ),
    {
      staleTime: 0, // Data will be considered stale after 5 minutes
      retry: 3,
    }
  );
  return {
    ...queryInfo,
    refetchVisitors: queryInfo.refetch, // Extract refetch function from queryInfo
  };
};

export default useVisitors;
