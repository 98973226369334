import React, { useEffect, useState } from "react";
import ExhibitorCard from "../../../../widgets/ExhibitorCard";
import axios from "axios";
import {
  EXHIBITOR,
  EXHIBITOR_API,
  IMTEX_EXHIBITOR,
  INTERACT_FAVORITE,
  INTERACT_MEETING,
  INTERACT_MESSAGE,
  VISITOR,
  VISITOR_API,
} from "../../../../constants/constants";
import { getExhibitorHeader, getHeader } from "../../../../utility/utility";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import { toast } from "react-toastify";
import ProfileDetails from "../../exhibitor/profile/ProfileDetails";
import { Button, Grid, Pagination, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MuPb from "../../../../widgets/MuPb";
import useExhibitors from "../../exhibitor/explore/hooks/useExhibitors";
import MessagePopup from "../../exhibitor/MessagePopup";
import useLoginProfile from "../hooks/useLoginProfile";
import { useNavigate } from "react-router-dom";
const Exhibitors = ({
  searchFilter,
  currentView,
  setCurrentView,
  parentView,
}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, error } = useExhibitors(page, searchFilter);
  const {
    data: profileData,
    isLoading: profileLoading,
    isError: isProfileError,
    error: profileError,
    refetchExhibitorProfile,
  } = useLoginProfile();
  const EXHIBITOR_VIEW = "exhibitor_view";
  const [currentUser, setCurrentUser] = useState(null);
  const [openMessage, setOpenMessage] = useState(false);
  const [senderMessage, setSenderMessage] = useState();
  const [recipientMessage, setRecipientMessage] = useState();

  const onExhibitorSelect = (cuser) => {
    setCurrentUser(cuser);
    setCurrentView(EXHIBITOR_VIEW);
  };
  if (isLoading || profileLoading) {
    return <MuPb />;
  }
  const onPageChange = (e, page) => {
    setPage(page);
  };
  const onInteract = (type, cuser) => {
    switch (type) {
      case INTERACT_MESSAGE:
        setSenderMessage(VISITOR);
        setRecipientMessage({ username: cuser, type: EXHIBITOR });
        setOpenMessage(true);
        break;
      case INTERACT_MEETING:
        console.log("meet", cuser);
        navigate(
          `/meeting-scheduler?uid=${profileData.message.email}&type=visitors&tid=${cuser.username}&tType=${IMTEX_EXHIBITOR}`
        );
        break;
      case INTERACT_FAVORITE:
        console.log("fav");
        break;
      default:
        console.log("nothing selected");
    }
  };

  return (
    <div>
      <MessagePopup
        sender={senderMessage}
        recipient={recipientMessage}
        open={openMessage}
        setOpen={setOpenMessage}
      />
      {currentView !== parentView && (
        <Button
          sx={{ marginTop: "10px", marginLeft: "8px" }}
          onClick={() => setCurrentView(parentView)}
          variant="outlined"
          startIcon={<ArrowBackIosNewIcon />}
          size="small"
        >
          Back
        </Button>
      )}
      {currentView === parentView ? (
        <div>
          <Grid
            pl={2}
            pr={2}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h6">Exhibitors</Typography>
            </Grid>
            <Grid item>
              <Pagination
                count={Math.ceil(data.totalItems / 30)}
                page={page}
                onChange={onPageChange}
                color="primary"
              />
            </Grid>
          </Grid>
          {data.data.map((exh, i) => (
            <ExhibitorCard
              key={i}
              item={exh}
              onInteract={onInteract}
              onSelectExhibitor={onExhibitorSelect}
            />
          ))}
        </div>
      ) : (
        <>
          {currentUser && (
            <ProfileDetails
              id={currentUser.username}
              isView={true}
              user={currentUser}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Exhibitors;
