import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import RdIconContainer from './RdIconContainer';
import { ICON_TYPE_NON_IMAGE } from '../constants/constants';
import EditBtn from './actionBtn/editBtn';

const HeaderLabel = ({
    label, onEdit, labelStyle={}, showEdit=true
}) => {
    return (
        <div style={{display: 'flex'}}>
            <div style={{fontWeight: 600, color: '#185399', flex: 1, alignSelf: 'center', ...labelStyle}}>{label}</div>
            {showEdit && <EditBtn onEdit={onEdit}/>}
        </div>
    );
};

export default HeaderLabel;