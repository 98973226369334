import axios from "axios";
import React, { useEffect, useState } from "react";
import { EXHIBITOR_API, VISITOR_API } from "../../../../constants/constants";
import { getExhibitorHeader, getHeader } from "../../../../utility/utility";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import ProductItem from "../../../../widgets/ProductItem";
import { toast } from "react-toastify";
import { Box, Button, Grid, Pagination, Typography } from "@mui/material";
import ProductDetail from "../../exhibitor/ProductDetail";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useProducts from "../hooks/useProducts";
import MuPb from "../../../../widgets/MuPb";
import useExploreProducts from "../../exhibitor/hooks/getExploreProducts";

const Products = ({
  searchFilter,
  currentView,
  setCurrentView,
  parentView,
}) => {
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, error } = useExploreProducts(
    page,
    searchFilter
  );
  const DETAIL_VIEW = "detail_view";
  const [currentProduct, setCurrentProduct] = useState("");

  const onEditProduct = () => {};
  const onDeleteProduct = () => {};

  const onSelectProduct = (product) => {
    setCurrentProduct(product);
    setCurrentView(DETAIL_VIEW);
  };
  const onBack = () => {
    setCurrentView(parentView);
  };
  if (isLoading) {
    return <MuPb />;
  }
  const onPageChange = (e, page) => {
    setPage(page);
  };

  return (
    <div>
      {currentView !== parentView && (
        <Button
          sx={{ marginTop: "10px", marginLeft: "8px" }}
          onClick={() => setCurrentView(parentView)}
          variant="outlined"
          startIcon={<ArrowBackIosNewIcon />}
          size="small"
        >
          Back
        </Button>
      )}

      {currentView === parentView ? (
        <Box>
          <Grid
            pl={2}
            pr={2}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h6">Products</Typography>
            </Grid>
            <Grid item>
              <Pagination
                count={Math.ceil(data.totalItems / 30)}
                page={page}
                onChange={onPageChange}
                color="primary"
              />
            </Grid>
          </Grid>
          {data.data.map((product, i) => (
            <ProductItem
              viewMode={true}
              onSelectProduct={onSelectProduct}
              onEditProduct={onEditProduct}
              onDeleteProduct={onDeleteProduct}
              key={i}
              product={product}
            />
          ))}

          {data.data.length === 0 && (
            <div className="items-not-found">No Products Found!</div>
          )}
        </Box>
      ) : (
        <ProductDetail product={currentProduct} />
      )}
    </div>
  );
};

export default Products;
