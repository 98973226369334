import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const columns = [
  { field: "id", headerName: "Bar Code No", width: 100 },
  { field: "firstName", headerName: "First name", width: 150 },
  { field: "lastName", headerName: "Last name", width: 150 },
  {
    field: "phone",
    headerName: "Phone Number",
    type: "tel",
    width: 150,
  },
  { field: "emailId", headerName: "Email ID", width: 200 },
];

function KioskSearchModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen} className="kiosk-search-btn">
        SEARCH
      </Button>
      <Modal
        aria-labelledby="upload-file"
        aria-describedby="upload-file-content"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "80%",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by Email id/Mobile/Company name"
                inputProps={{
                  "aria-label": "Search by Email id/Mobile/Company name",
                }}
              />
              <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button variant="contained">PRINT</Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

const rows = [
  {
    id: 1,
    lastName: "Snow",
    firstName: "Jon",
    phone: 9876543456,
    emailId: "jon@mail.com",
  },
  {
    id: 2,
    lastName: "Lannister",
    firstName: "Cersei",
    phone: 6789875645,
    emailId: "cersei@mail.com",
  },
  {
    id: 3,
    lastName: "Lannister",
    firstName: "Jaime",
    phone: 6785678678,
    emailId: "jaime@mail.com",
  },
  {
    id: 4,
    lastName: "Stark",
    firstName: "Arya",
    phone: 9875678976,
    emailId: "stark@mail.com",
  },
  {
    id: 5,
    lastName: "Targaryen",
    firstName: "Daenerys",
    phone: null,
    emailId: "daenerys@mail.com",
  },
  {
    id: 6,
    lastName: "Melisandre",
    firstName: null,
    phone: 8978986573,
    emailId: "meli@mail.com",
  },
  {
    id: 7,
    lastName: "Clifford",
    firstName: "Ferrara",
    phone: 7896781232,
    emailId: "ferrara@mail.com",
  },
  { id: 8, lastName: "Frances", firstName: "Rossini", phone: 8912343212 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", phone: 7854345654 },
];

export default KioskSearchModal;
