import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import axios from "axios";
import { EXHIBITOR_API } from "../../../constants/constants";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  getExhibitorHeader,
  getExhibitorMultipartHeader,
} from "../../../utility/utility";
import { LoadingButton } from "@mui/lab";
const Input = styled("input")({
  display: "none",
});

function AddBrochure({ onAdded, showAdd, mode = "add" }) {
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0]?.name || "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      toast.error("Please select a PDF file");
      return;
    }
    if (title === "") {
      toast.error("Please enter title");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("pdf", file);
    setLoading(true);
    try {
      const { data } = await axios.post(
        EXHIBITOR_API + "add-brochure",
        formData,
        getExhibitorMultipartHeader()
      );
      setLoading(false);
      toast.success(data.message);
      setTitle("");
      setFile(null);
      onAdded();
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 2,
          mb: 2,
        }}
      >
        <Typography component="h1" variant="h5">
          Upload Brochure
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 3, width: "100%" }}
        >
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={title}
            onChange={handleTitleChange}
            margin="normal"
          />

          <label htmlFor="contained-button-file">
            <Input
              accept="application/pdf"
              id="contained-button-file"
              type="file"
              onChange={handleFileChange}
            />
            <Button
              component="span"
              sx={{ mt: 2 }}
              variant="outlined"
              fullWidth
            >
              <PictureAsPdfIcon sx={{ mr: 1 }} color="error" /> Choose PDF
            </Button>
            {fileName && (
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                {fileName}
              </Typography>
            )}
          </label>

          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3 }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
}

export default AddBrochure;
