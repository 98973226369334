import React from "react";
import expoFooterImg from "../../../assets/imtex_footer.png";
import "./Footer.css";
const Footer = () => {
  return (
    <img
      style={{ display: "block", width: "100%" }}
      src={expoFooterImg}
      alt="Expo Footer"
    />
  );
};

export default Footer;
