import { useMutation } from "react-query";
import axios from "axios";
import { ADMIN_API, VISITOR_API } from "../../../../constants/constants";

function passwordReset(data) {
  return axios
    .post(`${VISITOR_API}create-reset-link`, data)
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
}

export function usePasswordReset() {
  const mutation = useMutation(passwordReset);

  return {
    ...mutation,
    reset: mutation.mutate,
  };
}
