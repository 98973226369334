import AdditionalServices from "../components/dashboard/exhibitor/oes/AdditionalServices";
import Advertisement from "../components/dashboard/exhibitor/oes/Advertisement";
import AudioEquipment from "../components/dashboard/exhibitor/oes/AudioEquipment";
import EcoDesign from "../components/dashboard/exhibitor/oes/EcoDesign";
import ElectricalWork from "../components/dashboard/exhibitor/oes/ElectricalWork";
import ExhibitorBadges from "../components/dashboard/exhibitor/oes/ExhibitorBadges";
import ExitPass from "../components/dashboard/exhibitor/oes/ExitPass";
import GuidelinesMezzanine from "../components/dashboard/exhibitor/oes/GuidelinesMezzanine";
import Horticulture from "../components/dashboard/exhibitor/oes/Horticulture";
import HotelAccomodation from "../components/dashboard/exhibitor/oes/HotelAccomodation";
import InvitationInaguaration from "../components/dashboard/exhibitor/oes/InvitationInaguaration";
import MandatoryDesign from "../components/dashboard/exhibitor/oes/MandatoryDesign";
import MandatoryFormA from "../components/dashboard/exhibitor/oes/MandatoryFormA";
import MandatoryFormB from "../components/dashboard/exhibitor/oes/MandatoryFormB";
import MandatoryNote from "../components/dashboard/exhibitor/oes/MandatoryNote";
import Manual from "../components/dashboard/exhibitor/oes/Manual";
import Security from "../components/dashboard/exhibitor/oes/Security";
import SeminarHalls from "../components/dashboard/exhibitor/oes/SeminarHalls";
import ServiceBadges from "../components/dashboard/exhibitor/oes/ServiceBadges";
import SetupSchedule from "../components/dashboard/exhibitor/oes/SetupSchedule";
import ShowDaily from "../components/dashboard/exhibitor/oes/ShowDaily";
import SiteHandling from "../components/dashboard/exhibitor/oes/SiteHandling";
import StandPossession from "../components/dashboard/exhibitor/oes/StandPossession";
import Summary from "../components/dashboard/exhibitor/oes/Summary";
import TemporaryPersonnel from "../components/dashboard/exhibitor/oes/TemporaryPersonnel";
import Translators from "../components/dashboard/exhibitor/oes/Translators";
import UtilityDetails from "../components/dashboard/exhibitor/oes/UtilityDetails";
import VisaApplication from "../components/dashboard/exhibitor/oes/VisaApplication";
import exhibitorIcon from "../assets/exhibitor_icon.png";
export const ICON_TYPE_NON_IMAGE = "icon_type_non_image";
export const ICON_TYPE_IMAGE = "icon_type_image";
export const HEADER_RIGHT_NOTIFICATION = "header_right_notification";
export const HEADER_RIGHT_MESSAGE = "header_right_message";
export const HEADER_RIGHT_PROFILE = "header_right_profile";
export const MOBILE_THRESHOLD = 640;
export const VISITOR = "visitor";
export const VISITORS = "visitors";
export const EXHIBITOR = "exhibitor";
export const IMTEX_EXHIBITOR = "imtexExhibitor";
export const EXHIBITOR_TOKEN = "exhibitor_token";
export const PROFILE_IMAGE = "profile_image";
export const PROFILE_BANNER = "profile_banner";
export const MEETINGS = "meetings";
export const USER = "user";
export const ADMIN = "admin";
export const LOCAL = "local";
export const PRODUCTION = "production";
export const LONG_DASH = "—";
export const APP_LOGO = "/app_logo.png";
export const HEADER_RIGHT_MODE_SETTINGS = "header_right_mode_settings";
export const state = PRODUCTION;
export const TRANSPARENCY = 0.8;
export const DRAWER_WIDTH = 240;
export const API_ENDPOINT =
  state === PRODUCTION
    ? "https://apis.expoplanner.in/"
    : "http://localhost:5002/";
export const VISITOR_API = `${API_ENDPOINT}visitor/`;
export const EXHIBITOR_API = `${API_ENDPOINT}exhibitor/`;
export const INTERACTION_API = `${API_ENDPOINT}interactions/`;
export const ADMIN_API = `${API_ENDPOINT}admin`;
export const DEFAULT_DP = `https://s3.ap-south-1.amazonaws.com/expoplanner.in/uploads/expo_expo_placeholder.png`;
export const DEFAULT_EX_ICON = exhibitorIcon;
export const INTERACT_MEETING = "interact_meeting";
export const INTERACT_MESSAGE = "interact_message";
export const INTERACT_FAVORITE = "interact_favorite";
export const MODULES = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
    ["link", "image"], // this line adds the image and video options
  ],
};

export const OES_COMPONENTS = [
  <Manual />,
  <SetupSchedule />,
  // <ExhibitionCatalogue />,
  <UtilityDetails />,
  <StandPossession />,
  <InvitationInaguaration />,
  <ExhibitorBadges />,
  <ServiceBadges />,
  <ElectricalWork />,
  <ExitPass />,
  <GuidelinesMezzanine />,
  <SiteHandling />,
  <Security />,
  <HotelAccomodation />,
  <SeminarHalls />,
  <AudioEquipment />,
  <TemporaryPersonnel />,
  <Translators />,
  <Horticulture />,
  <Advertisement />,
  <ShowDaily />,
  <VisaApplication />,
  <AdditionalServices />,
  <EcoDesign />,
  <MandatoryDesign />,
  <MandatoryNote />,
  <MandatoryFormA />,
  <MandatoryFormB />,
  <Summary />,
];
export const SUPPLIER_LIST = [
  {
    form_number: 2,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 3,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 4,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 5,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 6,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 7,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 8,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 9,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 10,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 11,
    supplier_name: "R.E. Rogers India Pvt. Ltd",
    address:
      "R.E.R. House, 2383/1, 11th Main, 15th Cross 'E' Block Opp. to State Bank of Mysore Sahakara Nagar Bangalore - 560092, Karnataka, India",
    contact_person: "Mr. Surendhar | Mr. Rajath Naik",
    email_id: [
      "surender@rogersworldwideindia.com",
      "rajath@rogersworldwideindia.com",
    ],
    phone_number: "+91-80-42690500-55|+91-9945083076|9901597909",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 11,
    supplier_name: "P S Bedi & Company Pvt. Ltd",
    address:
      "D - 14 /1, Okhla Industrial Area, Phase - 1, New Delhi - 110020, Delhi, India",
    contact_person: "Mr. Shrenik Jain | Mr. C. Sudhir Babu",
    email_id: ["sjain@psbedi.com", "cs1exh@psbedi.com"],
    phone_number: "+91-11-46055200|+91-9538205424|9315654664",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 11,
    supplier_name: "Schenker India Pvt. Ltd",
    address:
      "101, Touch Down, 1st Floor, No. 1 & 2 HAL Industrial Area, Adjacent to HAL Museum, Airport Road, Bangalore - 560037, Karnataka, India",
    contact_person: "Mr. Vignesh Bharadwaj | Mr. Prabodh Kulkarni",
    email_id: [
      "vignesh.bharadwaj@dbschenker.com",
      "prabodh.kulkarni-extern@dbschenker.com",
    ],
    phone_number: "+91 9884029200|9319283395",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 12,
    supplier_name: "Shashi Detective Services Pvt. Ltd",
    address:
      "No. 28, Shashikirana Complex 12 Main, 1st Block, Rajajinagar Bangalore - 560010, Karnataka, India",
    contact_person: "Mr. Ravindra Kumar",
    email_id: ["gm@shashisecurity.com"],
    phone_number: "+91-80-23133633|23134876|+91-9742222000|9880495755",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 12,
    supplier_name: "SIS Ltd.",
    address:
      "1 & 16, NG Arcade, 2nd Floor, Hesaragatta Main Road,Havanur Extension, Near Vidya School Bus Stop, Bangalore - 560022, Karnataka, India",
    contact_person: "Mr. Arockia Samy",
    email_id: ["arockia.samy@sisindia.com"],
    phone_number: "+91-9986236393",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 22,
    supplier_name: "Amanullah Khan’s Sons",
    address:
      "No.81, New Bamboo Bazaar, Parallel to N.R Road, City Market, Bangalore - 560 002, Karnataka, India",
    contact_person: "Mr. Jameel",
    email_id: ["aksabbas@gmail.com"],
    phone_number: "+91 9880244700",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 13,
    supplier_name: "Hotel Accommodation & Travel Arrangements",
    address:
      "No. 1/4, 2nd Floor, W.H. Hanumathappa Layout, 1st Cross Off. Ulsoor Road Bangalore - 560042, Karnataka, India",
    contact_person: "Mr. Debashis Dutta",
    email_id: ["hos@micehospitality.com"],
    phone_number: "+91-8884433113",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 14,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 15,
    supplier_name: "Divyashree Audio Visual",
    address:
      "# 63, 3rd A Cross, 3rd Block, Thyagarajanagar, Bangalore - 560028, Karnataka, India",
    contact_person: "Mr. Babu",
    email_id: ["divyashreeimtex@gmail.com"],
    phone_number: "+91-9845142072",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 15,
    supplier_name: "M/s. IT Vision",
    address:
      "No. 277, 2nd Cross, C.T. Bed 3rd Block, Thyagarajanagar Bangalore - 560028, Karnataka, India",
    contact_person: "Mr. Kumar",
    email_id: ["itvisionav@gmail.com"],
    phone_number: "+91-9964322319",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 16,
    supplier_name: "JRB Eventz Management Pvt. Ltd",
    address:
      "D-14/1, Basement, Okhla Industrial Area Phase-I, New Delhi - 110020, Delhi, India",
    contact_person: "Mr. Jatin Bharadwaj",
    email_id: ["jbharadwaj@jrbeventz.com"],
    phone_number: "+91-9910201927",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 17,
    supplier_name: "Crystal Hues Ltd",
    address:
      "303, Nikith Arcade, Third floor, Second Main Road, Kasturi Nagar Bangalore - 560043, Karnataka, India",
    contact_person: "Ms. Subhasree Paitandy",
    email_id: ["subhasree.paitandy@crystalhues.com"],
    phone_number: "+91-7625000102",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 18,
    supplier_name: "BVG India Limited",
    address:
      "#47, BVG HOUSE, 2nd Floor, Vasant Nagar Millers, Tank Bund Road, Bangalore - 560001 Karnataka, India",
    contact_person: "Mr. Vinay / Mr. Vikram",
    email_id: ["vinay.m@bvgindia.com", "vikram.jadhav@bvgindia.com"],
    phone_number: "+91-9686723314|7760998851",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 19,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 20,
    supplier_name: "Magic Wand Media Inc",
    address:
      "401, Omprakash Villa, B.P.S. Road Opp. Bank of India, Mulund (West) Mumbai - 400080, Maharashtra, India",
    contact_person: "Ms. Soumi Mitra",
    email_id: [
      "soumi.mitra@magicwandmedia.in",
      "murali.sundaram@magicwandmedia.in",
    ],
    phone_number: "+9- 9870401498",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 21,
    supplier_name: "Indian Machine Tool Manufacturers Association",
    address:
      "Bangalore International Exhibition Centre 10th Mile, Tumkur Road, Madavara Post Bangalore - 562123, Karnataka, India ",
    contact_person: "Mr. Christopher A | Mr. Prasant Panda",
    email_id: ["info@imtex.in"],
    phone_number: "+91-80-66246600",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 22,
    supplier_name: "Meroform India Pvt Ltd",
    address:
      "47/1, Maya industrial Area, Near Royaloak Showroom, Anchepalya, off Tumkur Road, Nagasandra Post, Bangalore - 560073, Karnataka, India",
    contact_person: "Mr. Anand Nair",
    email_id: ["anand@meroformindia.com"],
    phone_number: "+91-9873379792",
    deadline_date: "30/12/2023",
  },
  {
    form_number: 22,
    supplier_name: "Pavilions & Interiors (I) Pvt. Ltd",
    address:
      "No. 241/N, G.A. Complex, 1st Stage, 4th Block HBR Layout, Service Road Outer Ring Road, Bangalore - 560043, Karnataka, India",
    contact_person: "Mr. Santosh",
    email_id: ["pi.bangalore@pavilionsinteriors.com"],
    phone_number: "+91-8095856888",
    deadline_date: "30/12/2023",
  },
];
export const PRODUCT_CATEGORIES = [
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.01", name: "Bench Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.02", name: "Column Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.03", name: "Crank Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.04", name: "Deep Drawing Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.05", name: "Dial Feed Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.06", name: "Double Action Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.07", name: "Double and Four Column Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.08", name: "Fly Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.09", name: "Four" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.10", name: "Friction Screw Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.11", name: "Geared Drawing Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.12", name: "Hand Lever Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.13", name: "Hot Forming Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.14", name: "Hydraulic Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.15", name: "Inclinable Eccentric Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.16", name: "Large Panel Transfer Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.17", name: "Mechanical Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: {
      code: "A01.18",
      name: "Open Front Non Inclinable Eccentric Presses",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: {
      code: "A01.19",
      name: "Open Front Presses (C-Frame Presses)",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.20", name: "Percussion Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: {
      code: "A01.21",
      name: "Pneumatic and Hydropneumatic Presses",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.22", name: "Servo Driven Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.23", name: "Straight Sided Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.24", name: "Toggle Lever Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.25", name: "Transfer Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.26", name: "Two Point Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.27", name: "Wide Frame Crank Drawing Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.28", name: "Wide Frame Drawing Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A01.00", name: "Presses" },
    Subcategory: { code: "A01.29", name: "Presses, any other" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A02.00", name: "Presses for special applications" },
    Subcategory: { code: "A02.01", name: "Calibrating Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A02.00", name: "Presses for special applications" },
    Subcategory: { code: "A02.02", name: "Coining Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A02.00", name: "Presses for special applications" },
    Subcategory: { code: "A02.03", name: "Die Spotting Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A02.00", name: "Presses for special applications" },
    Subcategory: { code: "A02.04", name: "Flanging Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A02.00", name: "Presses for special applications" },
    Subcategory: { code: "A02.05", name: "Marking Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A02.00", name: "Presses for special applications" },
    Subcategory: { code: "A02.06", name: "Scrap Bailing Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A02.00", name: "Presses for special applications" },
    Subcategory: { code: "A02.07", name: "Stamping Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A02.00", name: "Presses for special applications" },
    Subcategory: { code: "A02.08", name: "Straightening Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A02.00", name: "Presses for special applications" },
    Subcategory: { code: "A02.09", name: "Trimming Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A02.00", name: "Presses for special applications" },
    Subcategory: {
      code: "A02.10",
      name: "Presses for special application, any other",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: { code: "A03.01", name: "Circle(Disc) Cutting Shears" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: { code: "A03.02", name: "Cutting-off Grinders" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: { code: "A03.03", name: "Flame Cutting Equipment" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: { code: "A03.04", name: "Gang Slitting Rotary Shears" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: { code: "A03.05", name: "Gas Cutting Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: { code: "A03.06", name: "Guillotine Shears" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: { code: "A03.07", name: "Hand Lever Shears" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: { code: "A03.08", name: "Laser Cutting Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: { code: "A03.09", name: "Length Cutting Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: {
      code: "A03.10",
      name: "Other Sheet and Strip Working Machines",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: { code: "A03.11", name: "Plasma Jet Cutting Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: { code: "A03.12", name: "Slitting &Cutting-to-length Lines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: { code: "A03.13", name: "Slitting Lines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: {
      code: "A03.14",
      name: "Strip Levelling and Cutting-off Machines",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: { code: "A03.15", name: "Trimming Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: { code: "A03.16", name: "Water Jet Cutting Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A03.00", name: "Sheet metal cutting machines" },
    Subcategory: {
      code: "A03.17",
      name: "Sheet Metal Cutting Machines, any other",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A04.00",
      name: "Sheet metal blanking, Punching machines",
    },
    Subcategory: {
      code: "A04.01",
      name: "Combined Curve-cutting and Nibbling Machines",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A04.00",
      name: "Sheet metal blanking, Punching machines",
    },
    Subcategory: {
      code: "A04.02",
      name: "Combined Punching and Laser-cutting Machines",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A04.00",
      name: "Sheet metal blanking, Punching machines",
    },
    Subcategory: {
      code: "A04.03",
      name: "Dial Feed Presses or Rotary Table Presses",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A04.00",
      name: "Sheet metal blanking, Punching machines",
    },
    Subcategory: {
      code: "A04.04",
      name: "High Production Presses with Automatic Feed",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A04.00",
      name: "Sheet metal blanking, Punching machines",
    },
    Subcategory: { code: "A04.05", name: "Nibbling Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A04.00",
      name: "Sheet metal blanking, Punching machines",
    },
    Subcategory: { code: "A04.06", name: "Notching Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A04.00",
      name: "Sheet metal blanking, Punching machines",
    },
    Subcategory: {
      code: "A04.07",
      name: "Other Shearing, Nibbling, Notching and Punching Machines",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A04.00",
      name: "Sheet metal blanking, Punching machines",
    },
    Subcategory: { code: "A04.08", name: "Perforating Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A04.00",
      name: "Sheet metal blanking, Punching machines",
    },
    Subcategory: { code: "A04.09", name: "Punching Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A04.00",
      name: "Sheet metal blanking, Punching machines",
    },
    Subcategory: { code: "A04.10", name: "Turret Punch Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A04.00",
      name: "Sheet metal blanking, Punching machines",
    },
    Subcategory: {
      code: "A04.11",
      name: "Universal shearing and Punching (Notching) Machines",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A04.00",
      name: "Sheet metal blanking, Punching machines",
    },
    Subcategory: {
      code: "A04.12",
      name: "Sheet Metal Blanking, Punching Machines, any other",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: { code: "A05.01", name: "Beading and Curling Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: { code: "A05.02", name: "Bending and Straightening Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: { code: "A05.03", name: "Corrugating Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: { code: "A05.04", name: "Crimping Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: { code: "A05.05", name: "Folding Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: { code: "A05.06", name: "Press Brakes" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: { code: "A05.07", name: "Profile Forming Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: {
      code: "A05.08",
      name: "Roll Forming Machines for Hollow Components",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: { code: "A05.09", name: "Seaming Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: { code: "A05.10", name: "Sheet and Roller Forming Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: { code: "A05.11", name: "Sheet Metal Bending Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: { code: "A05.12", name: "Sheet Metal Levelling Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: { code: "A05.13", name: "Stretch Drawing Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: { code: "A05.14", name: "Stretch Forming Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: { code: "A05.15", name: "Stretching and Levelling Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A05.00", name: "Sheet metal forming machines" },
    Subcategory: {
      code: "A05.16",
      name: "Sheet Metal Forming Machines, any other",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A06.00", name: "Sheet metal working cells and systems" },
    Subcategory: { code: "A06.01", name: "Bending Centres" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A06.00", name: "Sheet metal working cells and systems" },
    Subcategory: {
      code: "A06.02",
      name: "Flexible Forming and Shaping Systems",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A06.00", name: "Sheet metal working cells and systems" },
    Subcategory: {
      code: "A06.03",
      name: "Flexible Sheet Metal Working Systems",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A06.00", name: "Sheet metal working cells and systems" },
    Subcategory: { code: "A06.04", name: "Sheet Metal Working Centres" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A06.00", name: "Sheet metal working cells and systems" },
    Subcategory: {
      code: "A06.05",
      name: "Sheet metal working cells and systems, any other",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A07.00", name: "Die Casting" },
    Subcategory: { code: "A07.01", name: "Automation" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A07.00", name: "Die Casting" },
    Subcategory: { code: "A07.02", name: "High Pressure Die Casting" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A07.00", name: "Die Casting" },
    Subcategory: { code: "A07.03", name: "Low Pressure Die Casting" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A07.00", name: "Die Casting" },
    Subcategory: { code: "A07.04", name: "Die casting, any other" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A08.00", name: "Forging" },
    Subcategory: { code: "A08.01", name: "Cold and hot forging machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A08.00", name: "Forging" },
    Subcategory: { code: "A08.02", name: "Forging Hammers" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A08.00", name: "Forging" },
    Subcategory: { code: "A08.03", name: "Forging, any other" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A09.00", name: "Extrusion" },
    Subcategory: { code: "A09.01", name: "Extrusion Press" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A09.00", name: "Extrusion" },
    Subcategory: { code: "A09.02", name: "Impact Extrusion Press" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A09.00", name: "Extrusion" },
    Subcategory: { code: "A09.03", name: "Extrusion, any other" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A10.00", name: "Metal spinning and flow forming" },
    Subcategory: { code: "A10.01", name: "Flow forming machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A10.00", name: "Metal spinning and flow forming" },
    Subcategory: { code: "A10.02", name: "Metal spinning machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A10.00", name: "Metal spinning and flow forming" },
    Subcategory: {
      code: "A10.03",
      name: "Vertical Ring Flow-extruding machines",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A10.00", name: "Metal spinning and flow forming" },
    Subcategory: {
      code: "A10.04",
      name: "Metal spinning and flow forming, any other",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A11.00", name: "Rolling" },
    Subcategory: { code: "A11.01", name: "Gear / Spline / Profile Rolling" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A11.00", name: "Rolling" },
    Subcategory: { code: "A11.02", name: "Thread Rolling" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A11.00", name: "Rolling" },
    Subcategory: { code: "A11.03", name: "Rolling, any other" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A12.00", name: "Wire forming machines" },
    Subcategory: { code: "A12.01", name: "Chain Making Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A12.00", name: "Wire forming machines" },
    Subcategory: { code: "A12.02", name: "Nail Making Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A12.00", name: "Wire forming machines" },
    Subcategory: {
      code: "A12.03",
      name: "Spring Coiling Machines and Apparatus",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A12.00", name: "Wire forming machines" },
    Subcategory: { code: "A12.04", name: "Wire Bending and Forming Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A12.00", name: "Wire forming machines" },
    Subcategory: { code: "A12.05", name: "Wire Coiling and Winding Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A12.00", name: "Wire forming machines" },
    Subcategory: { code: "A12.06", name: "Wire Drawing Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A12.00", name: "Wire forming machines" },
    Subcategory: { code: "A12.07", name: "Wire Netting and Weaving Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A12.00", name: "Wire forming machines" },
    Subcategory: {
      code: "A12.08",
      name: "Wire Straightening and Cutting-off Machine",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A12.00", name: "Wire forming machines" },
    Subcategory: { code: "A12.09", name: "Wire Forming Machines, any other" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.01", name: "Band Saws for Cutting Sections" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.02", name: "Bar and Section Bending Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.03", name: "Bar and Section Finishing Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.04", name: "Circular Saws for Cutting Sections" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: {
      code: "A13.05",
      name: "Combined Automatic Tube Forming, Welding and Cutting-off Machines",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.06", name: "Cutting Machines for Hollow Shapes" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: {
      code: "A13.07",
      name: "Flexible Manufacturing Systems for Tubes and Sections",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: {
      code: "A13.08",
      name: "Machines for Punching, Drilling & Tapping Structural Sections",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.09", name: "Ring Rolling" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: {
      code: "A13.10",
      name: "Roller Finishing and Deep Rolling Machines",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.11", name: "Roller Forming Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.12", name: "Rolling stands" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.13", name: "Section and profile rolling" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.14", name: "Section, Billet, Ingot Shears" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.15", name: "Sheet Rolling" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.16", name: "Straightening Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.17", name: "Swaging Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.18", name: "Tube Bending Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.19", name: "Tube Cutting-off Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.20", name: "Tube Ending Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: { code: "A13.21", name: "Tube Finishing Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: {
      code: "A13.22",
      name: "Tube Reducing and Enlarging Machines",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A13.00",
      name: "Bar, Section and Tube working machines",
    },
    Subcategory: {
      code: "A13.23",
      name: "Bar, Section and Tube Working Machines, any other",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A14.00", name: "Hydro forming" },
    Subcategory: { code: "A14.01", name: "Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A14.00", name: "Hydro forming" },
    Subcategory: { code: "A14.02", name: "Hydro forming, any other" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A15.00", name: "Casting & Foundry" },
    Subcategory: { code: "A15.01", name: "Core making" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A15.00", name: "Casting & Foundry" },
    Subcategory: { code: "A15.02", name: "Foundry machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A15.00", name: "Casting & Foundry" },
    Subcategory: { code: "A15.03", name: "Melting" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A15.00", name: "Casting & Foundry" },
    Subcategory: { code: "A15.04", name: "Mould box" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A15.00", name: "Casting & Foundry" },
    Subcategory: { code: "A15.05", name: "Pattern Making" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A15.00", name: "Casting & Foundry" },
    Subcategory: { code: "A15.06", name: "Sand preparation and handling" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A15.00", name: "Casting & Foundry" },
    Subcategory: { code: "A15.07", name: "SG Iron and Automotive Foundries" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A15.00", name: "Casting & Foundry" },
    Subcategory: { code: "A15.08", name: "Casting & Foundry, any other" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A16.00", name: "Heat Treatment & Hardening" },
    Subcategory: { code: "A16.01", name: "Case hardening" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A16.00", name: "Heat Treatment & Hardening" },
    Subcategory: { code: "A16.02", name: "Furnaces" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A16.00", name: "Heat Treatment & Hardening" },
    Subcategory: { code: "A16.03", name: "Heat treatment equipment" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A16.00", name: "Heat Treatment & Hardening" },
    Subcategory: { code: "A16.04", name: "Induction hardening" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A16.00", name: "Heat Treatment & Hardening" },
    Subcategory: { code: "A16.05", name: "Laser Hardening" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A16.00", name: "Heat Treatment & Hardening" },
    Subcategory: { code: "A16.06", name: "Laser Heat Treatment" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A16.00", name: "Heat Treatment & Hardening" },
    Subcategory: {
      code: "A16.07",
      name: "Heat Treatment & Hardening, any other",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A17.00",
      name: "Machines for the production of Bolts, Nuts, Screws and Rivets",
    },
    Subcategory: { code: "A17.01", name: "Bolt Chamfering Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A17.00",
      name: "Machines for the production of Bolts, Nuts, Screws and Rivets",
    },
    Subcategory: { code: "A17.02", name: "Bolt Shears" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A17.00",
      name: "Machines for the production of Bolts, Nuts, Screws and Rivets",
    },
    Subcategory: {
      code: "A17.03",
      name: "Cold and Warm Presses for Bolts and Rivets",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A17.00",
      name: "Machines for the production of Bolts, Nuts, Screws and Rivets",
    },
    Subcategory: {
      code: "A17.04",
      name: "Deburring (Trimming) Machines for Bolts, Screws, Nuts and Rivets",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A17.00",
      name: "Machines for the production of Bolts, Nuts, Screws and Rivets",
    },
    Subcategory: { code: "A17.05", name: "Nut Presses" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A17.00",
      name: "Machines for the production of Bolts, Nuts, Screws and Rivets",
    },
    Subcategory: { code: "A17.06", name: "Nut Tapping Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A17.00",
      name: "Machines for the production of Bolts, Nuts, Screws and Rivets",
    },
    Subcategory: { code: "A17.07", name: "Screw and Bolt Threading Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A17.00",
      name: "Machines for the production of Bolts, Nuts, Screws and Rivets",
    },
    Subcategory: {
      code: "A17.08",
      name: "Screw Head Turning and Slotting Machines",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A17.00",
      name: "Machines for the production of Bolts, Nuts, Screws and Rivets",
    },
    Subcategory: { code: "A17.09", name: "Thread Rolling Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A17.00",
      name: "Machines for the production of Bolts, Nuts, Screws and Rivets",
    },
    Subcategory: {
      code: "A17.10",
      name: "Machines for the Production of Bolts, Screws, Nuts and Rivets, any other",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A18.00", name: "Joining & Welding" },
    Subcategory: { code: "A18.01", name: "Arc, Gas welding" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A18.00", name: "Joining & Welding" },
    Subcategory: { code: "A18.02", name: "Building-up Welding" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A18.00", name: "Joining & Welding" },
    Subcategory: { code: "A18.03", name: "Edge preparation machine" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A18.00", name: "Joining & Welding" },
    Subcategory: { code: "A18.04", name: "Electron Beam Welding" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A18.00", name: "Joining & Welding" },
    Subcategory: { code: "A18.05", name: "Friction welding" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A18.00", name: "Joining & Welding" },
    Subcategory: { code: "A18.06", name: "Laser welding" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A18.00", name: "Joining & Welding" },
    Subcategory: { code: "A18.07", name: "Riveting Machine" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A18.00", name: "Joining & Welding" },
    Subcategory: { code: "A18.08", name: "Robotic welding" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A18.00", name: "Joining & Welding" },
    Subcategory: { code: "A18.09", name: "Seam and Butt Welding Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A18.00", name: "Joining & Welding" },
    Subcategory: { code: "A18.10", name: "Small Electric Welding Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A18.00", name: "Joining & Welding" },
    Subcategory: { code: "A18.11", name: "Spot welding" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A18.00", name: "Joining & Welding" },
    Subcategory: { code: "A18.12", name: "Joining & Welding, any other" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A19.00", name: "Robotics & Manufacturing Automation" },
    Subcategory: { code: "A19.01", name: "Assembly Machines and Lines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A19.00", name: "Robotics & Manufacturing Automation" },
    Subcategory: { code: "A19.02", name: "Industrial Robots" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: { code: "A19.00", name: "Robotics & Manufacturing Automation" },
    Subcategory: { code: "A19.03", name: "Ultrasonic Assembly Machines" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A20.00",
      name: "Specialised Metal Forming Processes & Machines",
    },
    Subcategory: { code: "A20.01", name: "Explosive Forming" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A20.00",
      name: "Specialised Metal Forming Processes & Machines",
    },
    Subcategory: { code: "A20.02", name: "Hot Forming" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A20.00",
      name: "Specialised Metal Forming Processes & Machines",
    },
    Subcategory: { code: "A20.03", name: "Investment Casting" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A20.00",
      name: "Specialised Metal Forming Processes & Machines",
    },
    Subcategory: { code: "A20.04", name: "Magnetic Pulse Forming" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A20.00",
      name: "Specialised Metal Forming Processes & Machines",
    },
    Subcategory: { code: "A20.05", name: "Metal Injection Moulding" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A20.00",
      name: "Specialised Metal Forming Processes & Machines",
    },
    Subcategory: { code: "A20.06", name: "Powder Metallurgy" },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A20.00",
      name: "Specialised Metal Forming Processes & Machines",
    },
    Subcategory: {
      code: "A20.07",
      name: "Rapid Prototyping Machines, including Metal Rapid Prototyping",
    },
  },
  {
    MainCategory: { code: "A", name: "IMTEX Forming 2024" },
    Category: {
      code: "A20.00",
      name: "Specialised Metal Forming Processes & Machines",
    },
    Subcategory: {
      code: "A20.08",
      name: "Specialised Metal Forming Processes, Any other",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.01", name: "Boring Bars" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.02", name: "Boring Bits" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.03", name: "Boring Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.04", name: "Broaching Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: {
      code: "B01.05",
      name: "Carbide-tipped Tools & Carbide Tips",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.06", name: "Circular Saw Blades" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.07", name: "Countersinks and Core Drills" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.08", name: "Cutting Tools Coated" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.09", name: "Diamond Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.10", name: "Electrode Wires for WEDM" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.11", name: "Electrodes for EDM" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: {
      code: "B01.12",
      name: "Embossing, Stamping and Marking Tools",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.13", name: "Engraving Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.14", name: "Fine Boring Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: {
      code: "B01.15",
      name: "Gear Tools (Hobbing, Shaping & Shaving)",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.16", name: "Gun Drills" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: {
      code: "B01.17",
      name: "Hardmetal Inserts (Carbide Inserts)",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.18", name: "Inserted Blade Milling Cutter" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.19", name: "Inserted Tooth Saw Blades" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.20", name: "Inserts" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.21", name: "Knurling Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: {
      code: "B01.22",
      name: "Indexable Inserts of Aluminium Oxide",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.23", name: "Metal Band Saw Blades" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.24", name: "Milling Cutters" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.25", name: "Other Tools for Machine Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.26", name: "Planning & Shaping Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: {
      code: "B01.27",
      name: "Plunge Cutting and Die Cutting Tools (Form Cutting Tools)",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.28", name: "Power Hacksaw Blades" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.29", name: "Reamers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.30", name: "Shear Knives, Machine Knives" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.31", name: "Shell and Angular End Millers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.32", name: "Side and Face Cutters" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.33", name: "Slot Millers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.34", name: "Spade Drills" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.35", name: "Special Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.36", name: "Step Drills" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.37", name: "Surface Milling Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.38", name: "Taps" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.39", name: "Thread Rolling Dies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.40", name: "Threading Dies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.41", name: "Threading Tools, Cutting" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.42", name: "Tool Bits" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.43", name: "Tools for Micromachining" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.44", name: "Twist Drills & Centre Drills" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B01.00", name: "Cutting Tools" },
    Subcategory: { code: "B01.45", name: "Cutting Tools, any other" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: {
      code: "B02.01",
      name: "Bending dies for tubes and sections",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.02", name: "Bending tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.03", name: "Blanking and punching dies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.04", name: "Car body tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.05", name: "Coining dies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.06", name: "Composite dies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.07", name: "Deep drawing dies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.08", name: "Drawing dies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: {
      code: "B02.09",
      name: "Embossing, stamping and marking tools",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.10", name: "Fine blanking dies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.11", name: "Forging dies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.12", name: "Knurling tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.13", name: "Multistage dies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.14", name: "Press tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.15", name: "Punches and dies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.16", name: "Punching tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.17", name: "Roll forming tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: {
      code: "B02.18",
      name: "Roller finishing and deep rolling tools",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.19", name: "Special tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.20", name: "Thread rolling tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.21", name: "Tools for powder metallurgy" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.22", name: "Tools for progressive presses" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.23", name: "Tools for screw manufacture" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.24", name: "Transfer die sets" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.25", name: "Notching & Nibbling Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.26", name: "Roller Burnishing Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: {
      code: "B02.27",
      name: "Standard Parts for Press Tool Moulds & Dies",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.28", name: "Threading Tools, Forming" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B02.00", name: "Forming Tools" },
    Subcategory: { code: "B02.29", name: "Forming tools, any other" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.01", name: "Abrasive and Polishing Pastes" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.02", name: "Abrasive Discs and Belts" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.03", name: "Abrasive products with diamond" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.04", name: "Bonded Abrasive Products" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.05", name: "Buffing Wheels" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.06", name: "Coated Abrasives" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.07", name: "Deburring Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.08", name: "Diamond Grinding Wheels" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.09", name: "Diamond Paste" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.10", name: "Dressing Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.11", name: "Grinding Wheels" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.12", name: "Honing, Lapping & Polishing Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.13", name: "Polishing Wheels & Belts" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.14", name: "Superfinishing Process Materials" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.15", name: "Synthetic Diamond Powder" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.16", name: "Trueing and Dressing Devices" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: { code: "B03.17", name: "Vibratory Deburring" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B03.00", name: "Abrasive and Polishing Tools" },
    Subcategory: {
      code: "B03.18",
      name: "Abrasive Tools and  Products, any other",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B04.00", name: "Hand Held Tools" },
    Subcategory: { code: "B04.01", name: "Electric and Pneumatic Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B04.00", name: "Hand Held Tools" },
    Subcategory: { code: "B04.02", name: "Electric/Pneumatic Power Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B04.00", name: "Hand Held Tools" },
    Subcategory: { code: "B04.03", name: "Hand tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B04.00", name: "Hand Held Tools" },
    Subcategory: { code: "B04.04", name: "Manual deburring tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B04.00", name: "Hand Held Tools" },
    Subcategory: { code: "B04.05", name: "Hand held tools, any other" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.01", name: "Boring & Facing Heads" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.02", name: "Chucking Tools with Code" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.03", name: "Drill Chucks" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.04", name: "Milling Arbors" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.05", name: "Multi Spindle, Angle Heads" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.06", name: "Multispindle Heads" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: {
      code: "B05.07",
      name: "Quick Action Fixture for Press Tools",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.08", name: "Shrink chucking equipment" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.09", name: "Sleeves" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.10", name: "Slotting Heads" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.11", name: "Speed Increasers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.12", name: "Tapping" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.13", name: "Tool Assembly Equipment" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.14", name: "Tool Breakage Detection System" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.15", name: "Tool Changing Systems for Presses" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.16", name: "Tool Holders" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.17", name: "Tool Holders with Codes" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.18", name: "Tool Management System & Software" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.19", name: "Tool Presetting Equipment" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: {
      code: "B05.20",
      name: "Tool Setting Instruments and Machines",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.21", name: "Tooling System, Modular" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.22", name: "Tool Wear Detection System" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.23", name: "Tooling System for Boring" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.24", name: "Tooling System for Drilling" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.25", name: "Tooling System for Grinding" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.26", name: "Tooling System for Milling" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.27", name: "Tooling System for Turning" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.28", name: "Turret Heads" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B05.00", name: "Tooling Systems" },
    Subcategory: { code: "B05.29", name: "Tooling Systems, any other" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.01", name: "Chucking systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.02", name: "Chucks, others" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.03", name: "Clamping devices; other" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.04", name: "Clamping Elements" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.05", name: "Collet Chucks" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.06", name: "Elements for Jigs & Fixtures" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.07", name: "Expanding Mandrels" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.08", name: "Fixture plates" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.09", name: "Hydraulic Clamping Device" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.10", name: "Lathe Chucks, Hand Operated" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.11", name: "Lathe Chucks, Power Operated" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.12", name: "Machine Vices" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.13", name: "Magnetic Chucks" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.14", name: "Magnetic Plates" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.15", name: "Vacuum Fixing Plate" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B06.00", name: "Workholding" },
    Subcategory: { code: "B06.16", name: "Workholding, any other" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B07.00", name: "Automation for Storage & Transport" },
    Subcategory: { code: "B07.01", name: "Automated Guided Vehicles" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B07.00", name: "Automation for Storage & Transport" },
    Subcategory: {
      code: "B07.02",
      name: "Automated Storage and Retrieval Machines",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B07.00", name: "Automation for Storage & Transport" },
    Subcategory: {
      code: "B07.03",
      name: "Components To lnterlink Separate Production Stages",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B07.00", name: "Automation for Storage & Transport" },
    Subcategory: { code: "B07.04", name: "Conveying Equipment and Systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B07.00", name: "Automation for Storage & Transport" },
    Subcategory: { code: "B07.05", name: "Palletizing Systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B07.00", name: "Automation for Storage & Transport" },
    Subcategory: { code: "B07.06", name: "Rail Guided Vehicles" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B07.00", name: "Automation for Storage & Transport" },
    Subcategory: {
      code: "B07.07",
      name: "Automation for Storage & Transport, any other",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B08.00", name: "Components for Robotics & Automation" },
    Subcategory: { code: "B08.01", name: "Changing System for Grippers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B08.00", name: "Components for Robotics & Automation" },
    Subcategory: { code: "B08.02", name: "End Effectors and Actuators" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B08.00", name: "Components for Robotics & Automation" },
    Subcategory: { code: "B08.03", name: "Grippers and Tools for Robots" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B08.00", name: "Components for Robotics & Automation" },
    Subcategory: {
      code: "B08.04",
      name: "Gripping Tools and Automatic Fixtures",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B08.00", name: "Components for Robotics & Automation" },
    Subcategory: {
      code: "B08.05",
      name: "Loading & Feeding for Machine Tools",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B08.00", name: "Components for Robotics & Automation" },
    Subcategory: {
      code: "B08.06",
      name: "Manipulators for Aligning and Positioning of Work pieces",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B08.00", name: "Components for Robotics & Automation" },
    Subcategory: {
      code: "B08.07",
      name: "Manipulators for Warehousing, Input and Delivery of Workpieces",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B08.00", name: "Components for Robotics & Automation" },
    Subcategory: { code: "B08.08", name: "Tool Changing Systems for Presses" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B08.00", name: "Components for Robotics & Automation" },
    Subcategory: { code: "B08.09", name: "Units for Assembly Systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.01", name: "Bar Feeders" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.02", name: "Boring Devices" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.03", name: "Centres, Tailstock for Lathes" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.04", name: "Cleaning and Degreasing Machines" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.05", name: "Copying Devices" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.06", name: "Cross Slide Tables (NC Controlled)" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.07", name: "Dividing Heads and Tables" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.08",
      name: "Oil Recovery Equipment  (Chip Separator)",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.09", name: "Package Presses for Chips" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.10",
      name: "Pneumatic and Hydraulic Accessories",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.11",
      name: "Process water treatment for superfinishing systems",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.12", name: "Spherical Turning Attachments" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.13", name: "Steady & Follow rests" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.14", name: "Steady rests self-centering" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.15", name: "Swarf Breaker" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.16", name: "Taper Turning Attachment" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.17", name: "Threading Devices" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.18", name: "Tilting Tables" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.19", name: "Accessories, anyother" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.20",
      name: "Air Bearing Based Linear Guiding Systems",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.21", name: "Air Heat Exchangers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.22", name: "Gear Units" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.23", name: "Grinding Devices" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.24", name: "Grinding Spindles" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.25",
      name: "Grinding Wheel Trueing, Dressing and Forming Devices",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.26",
      name: "High Frequency Spindles for Grinding",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.27", name: "High Pressure Coolant Systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.28",
      name: "Hydraulic Fluid Power Controls & Components",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.29", name: "Hydrostatic/Aerostatic Bearings" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.30",
      name: "Main Spindles for Turning, Milling & Boring and  Milling Machines",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.31",
      name: "Motor Spindle Units for Turning & Milling",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.32", name: "Panel Coolers and Panel AC" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: {
      code: "B09.33",
      name: "Pneumatic Fluid Power Transmissions/Controls & Components",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.34", name: "Positioning Systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.35", name: "Precision Actuators" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.36", name: "Refrigerating Units" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.37", name: "Swarf Conveyor" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.38", name: "Through Spindle Coolant System" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.39", name: "Tool Magazines and Tool Changers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.40", name: "Tool Turret" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.41", name: "Unit Heads" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.42", name: "Water Heat Exchangers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B09.00",
      name: "Attachments, Accessories & Sub-systems",
    },
    Subcategory: { code: "B09.43", name: "Sub-system, any other" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: {
      code: "B10.01",
      name: "Anti-friction Bearings including Spindle/Ball Screw",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: { code: "B10.02", name: "Anti-friction Linear Slides" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: { code: "B10.03", name: "Anti-vibration Mounts" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: {
      code: "B10.04",
      name: "Ball Screws & Planetary Roller Screws",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: {
      code: "B10.05",
      name: "Beds and Parts of Machines by Polymer Concrete & Others",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: { code: "B10.06", name: "Brake" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: { code: "B10.07", name: "Clutches" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: { code: "B10.08", name: "Couplings" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: { code: "B10.09", name: "Drive Belts" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: { code: "B10.10", name: "Hard Rock Machine Frames" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: { code: "B10.11", name: "Levelling Jacks" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: { code: "B10.12", name: "Linear Guide Rails" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: { code: "B10.13", name: "Machine Lamps" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: { code: "B10.14", name: "Slideway and Leadscrew Covers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: { code: "B10.15", name: "Springs" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B10.00", name: "Mechanical Elements and Components" },
    Subcategory: {
      code: "B10.16",
      name: "Mechanical Elements and Components, any other",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.01", name: "Actuators" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.02", name: "Air Preparation (D-F-R)" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.03", name: "Assemblies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.04", name: "Cylinders" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.05", name: "Filters" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.06", name: "Fittings, Connections" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.07", name: "Gaskets, O-Rings and Seals" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.08",
      name: "High Pressure Lines and Hoses, Flexible Tubing",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.09", name: "Hydraulic & Pneumatic Valves" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.10", name: "Hydropneumatic Accumulators" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.11", name: "Motors" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.12", name: "Pneumatic Components" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.13", name: "Pneumatic & Oil Pneumatic Jacks" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.14", name: "Positioning Systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.15", name: "Power Packs" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.16", name: "Pressure Reducers and Intensifiers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.17", name: "Pumps" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.18", name: "Relieving Devices" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: { code: "B11.19", name: "Rotary Unions" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B11.00",
      name: "Hydraulic and Pneumatic Systems and Elements",
    },
    Subcategory: {
      code: "B11.20",
      name: "Hydraulic and Pneumatic System & Elements, any other",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.01", name: "Cable Duct Systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.02", name: "Cable Holder Chains" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.03", name: "Control Devices and Switches" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.04", name: "Converters" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.05", name: "Cooling Fans" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.06", name: "Electric Control Panels, Cubicles" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.07", name: "Electric Motors" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: {
      code: "B12.08",
      name: "Electrical & Electronic Components and Assemblies",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.09", name: "Flexible Protective Hose" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.10", name: "Limit Switches" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.11", name: "Machine Monitoring Systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: {
      code: "B12.12",
      name: "Metering and Counting Instrument & Devices",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.13", name: "Power Electronics" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.14", name: "Rectifiers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.15", name: "Sensors and Transducers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.16", name: "Signal Processing Systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.17", name: "Switchgear and Control Gear" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.18", name: "UPS & Inverters" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: { code: "B12.19", name: "Voltage Stabilizers & Conditioners" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B12.00", name: "Electrical & Electronic Equipment" },
    Subcategory: {
      code: "B12.20",
      name: "Electrical & Electronic Equipment for Machine Tools, any other",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B13.00", name: "Feeding Systems" },
    Subcategory: { code: "B13.01", name: "Press feeding systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B13.00", name: "Feeding Systems" },
    Subcategory: { code: "B13.02", name: "Decoilers and Straighteners" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B13.00", name: "Feeding Systems" },
    Subcategory: { code: "B13.03", name: "Wire feeding systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B13.00", name: "Feeding Systems" },
    Subcategory: { code: "B13.04", name: "Automation" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B13.00", name: "Feeding Systems" },
    Subcategory: { code: "B13.05", name: "Sheet handling systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B13.00", name: "Feeding Systems" },
    Subcategory: { code: "B13.06", name: "Scrap conveyor systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B13.00", name: "Feeding Systems" },
    Subcategory: { code: "B13.07", name: "Robotic manipulators" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B13.00", name: "Feeding Systems" },
    Subcategory: {
      code: "B13.08",
      name: "Loading & Material Feeding Equipment for Machine Tools",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B13.00", name: "Feeding Systems" },
    Subcategory: { code: "B13.09", name: "Feeding systems, any other" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: { code: "B14.01", name: "Balancing Machine" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: { code: "B14.02", name: "Corrosion Testing Equipment" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: { code: "B14.03", name: "Deep-drawing Testers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: {
      code: "B14.04",
      name: "Electronic Unbalance Measuring and Control Equipment",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: { code: "B14.05", name: "Falling Weight Testing Machines" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: {
      code: "B14.06",
      name: "Gear Testing Machines and Apparatus",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: { code: "B14.07", name: "Hardness Testing Machine" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: {
      code: "B14.08",
      name: "Machines and Apparatus for Non-destructive Metal Tests",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: { code: "B14.09", name: "Metallographic Testing Equipment" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: { code: "B14.10", name: "Other Testing Machines" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: { code: "B14.11", name: "Tensile Testing Machine" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: {
      code: "B14.12",
      name: "Testing Machines impact (Pendulum Type)",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: { code: "B14.13", name: "Testing Machines, Spring Type" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: { code: "B14.14", name: "Torsion Testing Machines" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: { code: "B14.15", name: "Ultrasonic Testing Machines" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: { code: "B14.16", name: "Universal Testing Machine" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: {
      code: "B14.17",
      name: "Vibration Fatigue Testing Machines Including Tensile Testing, Compression Testing & Bending",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B14.00", name: "Testing Machines" },
    Subcategory: { code: "B14.18", name: "Testing Machines, any other" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.01",
      name: "Accessories for Measuring Equipment",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.02",
      name: "Acquisition of Measured Electrical / Non-electrical Data",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.03", name: "Angle and inclination Measurement" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.04", name: "Bore Measuring Equipment" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.05", name: "Circularity Measurement" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.06", name: "Clamping Force Testers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.07",
      name: "CMM for Measuring, Scanning and Digitising",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.08", name: "Computerized Measuring Machines" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.09", name: "CNC Coordinate Measuring Machines" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.10", name: "Diagnostic Systems and Software" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.11", name: "Digital Read Out Systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.12",
      name: "Displacement and Distance Measurement",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.13", name: "Dynamometer" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.14",
      name: "Electronic Gauging and Measurement Systems",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.15",
      name: "Equipment for Electromotive Force Measurement",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.16", name: "Force and Moment Measurement" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.17", name: "Gauges & Callipers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.18", name: "Gear Testing Equipment" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.19", name: "Hob Testing Equipment" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.20", name: "Image Processing Systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.21", name: "Laser Measuring Equipment" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.22", name: "Layer Thickness Measurement" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.23", name: "Limit Signal Transmitters" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.24", name: "Measured Data Amplifiers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.25", name: "Measuring and Marking Machines" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.26",
      name: "Measuring Instruments for Machine Geometry",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.27",
      name: "Measuring Instruments for Residual Magnetism",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.28", name: "Measuring Instruments, Electrical" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.29", name: "Measuring Instruments, Mechanical" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.30", name: "Measuring Instruments,Electronic" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.31", name: "Measuring Instruments, Optical" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.32", name: "Measuring Instruments,Pneumatic" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.33", name: "Measuring Instruments;others" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.34", name: "Measuring Machines, Any other" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.35",
      name: "Measuring Microscopes with Digital Image Processing",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.36", name: "Measuring Tables" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.37", name: "Micrometers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.38", name: "Microscopes" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.39", name: "Noise Measurement" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.40", name: "Peripherals" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.41",
      name: "Picture and Signal Pattern Recognition Sensor Systems",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.42", name: "Pressure Measurement" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.43", name: "Profile and Shape Measurement" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.44", name: "Profile Projector" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.45",
      name: "Quality Information and Display Systems",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.46", name: "Signal Processing Systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.47", name: "Speed Measurement" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.48", name: "Stereo Microscopes" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.49",
      name: "Surface Measuring Instruments & Machines",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.50",
      name: "Surface Plates and Flatness Measuring Equipment",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.51", name: "Systems for Tool Identification" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.52", name: "Temperature Measurement" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.53", name: "Testing Control and Management" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.54", name: "Thread measurement" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: {
      code: "B15.55",
      name: "Transducers (Measuring Probes and Sensors)",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.56", name: "Vibration Meters" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: {
      code: "B15.00",
      name: "Measuring, Inspection & Quality Control",
    },
    Subcategory: { code: "B15.57", name: "Video Systems and Endoscopes" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: { code: "B16.01", name: "Blow moulding moulds" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: {
      code: "B16.02",
      name: "Components for dies and moulds; other",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: { code: "B16.03", name: "Die Sets" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: { code: "B16.04", name: "Die-casting dies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: { code: "B16.05", name: "Drawing Dies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: { code: "B16.06", name: "Extrusion dies" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: {
      code: "B16.07",
      name: "Injection moulding tools for other materials",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: {
      code: "B16.08",
      name: "Injection moulding tools for thermoplastics",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: { code: "B16.09", name: "Moulds for ceramics" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: { code: "B16.10", name: "Moulds for extrusion coating" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: { code: "B16.11", name: "Moulds for glass" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: { code: "B16.12", name: "Moulds for rubber" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: {
      code: "B16.13",
      name: "Multi-colour injection moulding tools",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: {
      code: "B16.14",
      name: "Multi-component injection moulding tools",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: {
      code: "B16.15",
      name: "Pressing dies for thermosetting plastics",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: { code: "B16.16", name: "Sleeves" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: {
      code: "B16.17",
      name: "Standard parts for press tools, moulds, dies",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: { code: "B16.18", name: "Vacuum Moulds" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B16.00", name: "Dies & Moulds" },
    Subcategory: { code: "B16.19", name: "Dies and moulds, any other" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B17.00", name: "Production Control & Networks" },
    Subcategory: { code: "B17.01", name: "Data Networks, also for FMS" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B17.00", name: "Production Control & Networks" },
    Subcategory: { code: "B17.02", name: "Input Devices" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B17.00", name: "Production Control & Networks" },
    Subcategory: { code: "B17.03", name: "Interactive Terminals" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B17.00", name: "Production Control & Networks" },
    Subcategory: { code: "B17.04", name: "Operational Data Acquisition" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B17.00", name: "Production Control & Networks" },
    Subcategory: { code: "B17.05", name: "Peripherals" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B17.00", name: "Production Control & Networks" },
    Subcategory: {
      code: "B17.06",
      name: "Production Control & Networks, any other",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B18.00", name: "Controls, Drives and Systems" },
    Subcategory: { code: "B18.01", name: "Adaptive Controls" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B18.00", name: "Controls, Drives and Systems" },
    Subcategory: { code: "B18.02", name: "Axis Position Measuring Systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B18.00", name: "Controls, Drives and Systems" },
    Subcategory: { code: "B18.03", name: "CNC for Cutting Machines" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B18.00", name: "Controls, Drives and Systems" },
    Subcategory: { code: "B18.04", name: "CNC for Forming Machines" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B18.00", name: "Controls, Drives and Systems" },
    Subcategory: { code: "B18.05", name: "CNC for Other Machines" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B18.00", name: "Controls, Drives and Systems" },
    Subcategory: {
      code: "B18.06",
      name: "Electronic and Stored Program Controls",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B18.00", name: "Controls, Drives and Systems" },
    Subcategory: { code: "B18.07", name: "Feed Drives" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B18.00", name: "Controls, Drives and Systems" },
    Subcategory: { code: "B18.08", name: "Linear Motors" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B18.00", name: "Controls, Drives and Systems" },
    Subcategory: { code: "B18.09", name: "Programmable Controllers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B18.00", name: "Controls, Drives and Systems" },
    Subcategory: { code: "B18.10", name: "Programming Systems for CNC/PLC" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B18.00", name: "Controls, Drives and Systems" },
    Subcategory: { code: "B18.11", name: "Robot Controllers" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B18.00", name: "Controls, Drives and Systems" },
    Subcategory: { code: "B18.12", name: "Spindle Drives" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B18.00", name: "Controls, Drives and Systems" },
    Subcategory: {
      code: "B18.13",
      name: "Control and Drive Systems, any other",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B19.00", name: "Software" },
    Subcategory: { code: "B19.01", name: "ERP" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B19.00", name: "Software" },
    Subcategory: { code: "B19.02", name: "Software for Design & Development" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B19.00", name: "Software" },
    Subcategory: { code: "B19.03", name: "Software for Engineering & Methods" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B19.00", name: "Software" },
    Subcategory: {
      code: "B19.04",
      name: "Software for Production Planning & Control",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B19.00", name: "Software" },
    Subcategory: { code: "B19.05", name: "Software for Programming" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B19.00", name: "Software" },
    Subcategory: {
      code: "B19.06",
      name: "Software for Quality Control & Management",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B19.00", name: "Software" },
    Subcategory: { code: "B19.07", name: "Software for Simulation" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B19.00", name: "Software" },
    Subcategory: { code: "B19.08", name: "Software, any other" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B20.00", name: "Services" },
    Subcategory: { code: "B20.01", name: "Design & Engineering" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B20.00", name: "Services" },
    Subcategory: { code: "B20.02", name: "Heat Treatment" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B20.00", name: "Services" },
    Subcategory: { code: "B20.03", name: "Surface Coating" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B20.00", name: "Services" },
    Subcategory: { code: "B20.04", name: "Machining & Manufacturing" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B20.00", name: "Services" },
    Subcategory: {
      code: "B20.05",
      name: "Erection & Maintenance, Calibration",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B20.00", name: "Services" },
    Subcategory: { code: "B20.06", name: "Tool Sharpening & Maintenance" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B20.00", name: "Services" },
    Subcategory: { code: "B20.07", name: "Research & Documentation" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B20.00", name: "Services" },
    Subcategory: { code: "B20.08", name: "Training & Education" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B21.00", name: "Shop Equipment" },
    Subcategory: { code: "B21.01", name: "Lifting & Transport Equipment" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B21.00", name: "Shop Equipment" },
    Subcategory: {
      code: "B21.02",
      name: "Small Electric Hoists for Machine Tools",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B21.00", name: "Shop Equipment" },
    Subcategory: { code: "B21.03", name: "Storing of Workpieces and Tools" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B21.00", name: "Shop Equipment" },
    Subcategory: { code: "B21.04", name: "Tool Cabinets" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B21.00", name: "Shop Equipment" },
    Subcategory: { code: "B21.05", name: "Work Benches, Composable Shelves" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B21.00", name: "Shop Equipment" },
    Subcategory: { code: "B21.06", name: "Work Shop Fittings" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B21.00", name: "Shop Equipment" },
    Subcategory: { code: "B21.07", name: "Shop Equipment, any other" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B22.00", name: "Waste Disposal Equipment" },
    Subcategory: { code: "B22.01", name: "Chip Compacting Presses" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B22.00", name: "Waste Disposal Equipment" },
    Subcategory: { code: "B22.02", name: "Emulsion breaking" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B22.00", name: "Waste Disposal Equipment" },
    Subcategory: {
      code: "B22.03",
      name: "Equipment for Waste Disposal; others",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B22.00", name: "Waste Disposal Equipment" },
    Subcategory: { code: "B22.04", name: "Oil and oil mist vacuuming systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B22.00", name: "Waste Disposal Equipment" },
    Subcategory: {
      code: "B22.05",
      name: "Oil Recovery Equipment (Chip Separator)",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B22.00", name: "Waste Disposal Equipment" },
    Subcategory: {
      code: "B22.06",
      name: "Oil separation, oil mist separation",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B22.00", name: "Waste Disposal Equipment" },
    Subcategory: {
      code: "B22.07",
      name: "Processing of coolants and lubricants",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B22.00", name: "Waste Disposal Equipment" },
    Subcategory: {
      code: "B22.08",
      name: "Tipping containers for chip removal",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B22.00", name: "Waste Disposal Equipment" },
    Subcategory: { code: "B22.09", name: "Waste oil collecting tanks" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B22.00", name: "Waste Disposal Equipment" },
    Subcategory: {
      code: "B22.10",
      name: "Waste Disposal Equipment, any other",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: {
      code: "B23.01",
      name: "Air protection systems and components",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: {
      code: "B23.02",
      name: "Dust Separating Systems, Filtering Systems, Filters",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: { code: "B23.03", name: "Exhaust systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: { code: "B23.04", name: "Fire protection safety systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: { code: "B23.05", name: "Industrial safety products" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: {
      code: "B23.06",
      name: "Industrial suction plants for cleaning up of machines",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: { code: "B23.07", name: "Noise reduction systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: { code: "B23.08", name: "Personal safety devices" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: { code: "B23.09", name: "Protection devices for robots" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: { code: "B23.10", name: "Safety devices, Splash" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: {
      code: "B23.11",
      name: "Safety Equipment for Metal Forming Machines",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: { code: "B23.12", name: "Safety equipment on the machine" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: {
      code: "B23.13",
      name: "Wastewater purification, plant and components",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: {
      code: "B23.14",
      name: "Water treatment, plant and components",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: { code: "B23.15", name: "Workshop safety devices" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B23.00", name: "Safety & Environment" },
    Subcategory: {
      code: "B23.16",
      name: "Equipment for safety and environment; any other",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B24.00", name: "Lubrication & Coolant" },
    Subcategory: { code: "B24.01", name: "Coolant Filtration Systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B24.00", name: "Lubrication & Coolant" },
    Subcategory: { code: "B24.02", name: "Coolant Pumps" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B24.00", name: "Lubrication & Coolant" },
    Subcategory: {
      code: "B24.03",
      name: "Lubricants and Cutting Fluids for use on Machine Tools",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B24.00", name: "Lubrication & Coolant" },
    Subcategory: { code: "B24.04", name: "Lubrication Equipment and Devices" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B24.00", name: "Lubrication & Coolant" },
    Subcategory: {
      code: "B24.05",
      name: "Minimal lubrification technology (MQL)",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B24.00", name: "Lubrication & Coolant" },
    Subcategory: { code: "B24.06", name: "Oil Cleaning and Dispensing System" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B24.00", name: "Lubrication & Coolant" },
    Subcategory: { code: "B24.07", name: "Oil mist lubricators" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B24.00", name: "Lubrication & Coolant" },
    Subcategory: { code: "B24.08", name: "Oiling and greasing systems" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B24.00", name: "Lubrication & Coolant" },
    Subcategory: {
      code: "B24.09",
      name: "Separators for Coolants and Lubricants",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B24.00", name: "Lubrication & Coolant" },
    Subcategory: {
      code: "B24.10",
      name: "Spraying attachment for forging/die casting machines",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B24.00", name: "Lubrication & Coolant" },
    Subcategory: { code: "B24.11", name: "Lubrication and coolant; any other" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B25.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "B25.01", name: "Welding Fume Exhaust Units" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B25.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "B25.02", name: "Welding Material" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B25.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "B25.03", name: "Welding Accessories" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B25.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "B25.04", name: "Other Welding Equipment" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B25.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "B25.05", name: "Thermal Cutting Accessories" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B25.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "B25.06", name: "Welding Torches" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B25.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "B25.07", name: "Thermal Cutting Torches" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B25.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "B25.08", name: "Portable Welding machines" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B25.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "B25.09", name: "Welding Positioners" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B25.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "B25.10", name: "Soldering Materials" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B25.00", name: "Welding Material & Accessories" },
    Subcategory: {
      code: "B25.11",
      name: "Soldering Equipment and Accessories",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B25.00", name: "Welding Material & Accessories" },
    Subcategory: {
      code: "B25.12",
      name: "Welding Material & Accessories, any other",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.01", name: "Aluminium and Light Alloys" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.02", name: "Bar, Profile, Sections" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.03", name: "Blanks for Tools-hard Metal" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.04", name: "Blanks for Tools-HSS" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.05", name: "Die & Tool Steel" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.06", name: "Forged Parts" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.07", name: "Galvanized, Pre-coated" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.08", name: "High strength material" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.09", name: "High temperature material" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.10", name: "Industrial Adhesives" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.11", name: "Metal Powders" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.12", name: "Polymer Concrete" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.13", name: "Pre-Treated" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: {
      code: "B26.14",
      name: "Resins for Models and Rapid Prototyping",
    },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.15", name: "Shape Memory Alloys" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.16", name: "Sheets" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.17", name: "Steel and Special Alloys" },
  },
  {
    MainCategory: { code: "B", name: "Tooltech 2024" },
    Category: { code: "B26.00", name: "Raw Materials" },
    Subcategory: { code: "B26.18", name: "Raw Materials, any other" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.01", name: "Communication & Networking" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.02", name: "Data Acquisition Hardware" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: {
      code: "C01.03",
      name: "Guided Vehicles, Smart Handling & Transfer Systems",
    },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: {
      code: "C01.04",
      name: "Humanoid / Cooperating Robots (Cobots)",
    },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.05", name: "Integrating Hardware" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: {
      code: "C01.06",
      name: "IoT Devices, Sensors and Components",
    },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.07", name: "Smart Mobile Terminals" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.08", name: "Vision Systems" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.09", name: "Industry 4.0 hardware, anyother" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.10", name: "Artificial Intelligence" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.11", name: "CAD / CAM / CAE" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.12", name: "Communication" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.13", name: "Data Analytics & Cloud Services" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.14", name: "ERP" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.15", name: "Industry 4.0 Integrated Systems" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.16", name: "Internet and Data Security" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.17", name: "Smart Factory Solutions" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.18", name: "Augmented & Virtual Reality" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.19", name: "Simulation, Digital Twins" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C01.00", name: "Industry 4.0" },
    Subcategory: { code: "C01.20", name: "Industry 4.0 Software, anyother" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C02.00", name: "Additive Manufacturing" },
    Subcategory: {
      code: "C02.01",
      name: "Additive Manufacturing / 3D printing Machines",
    },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C02.00", name: "Additive Manufacturing" },
    Subcategory: { code: "C02.02", name: "Fused Deposition Modeling" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C02.00", name: "Additive Manufacturing" },
    Subcategory: { code: "C02.03", name: "Selective Laser Sintering" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C02.00", name: "Additive Manufacturing" },
    Subcategory: { code: "C02.04", name: "Technologies and Services" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C02.00", name: "Additive Manufacturing" },
    Subcategory: {
      code: "C02.05",
      name: "CAD / CAE software in Additive Manufacturing /    3D printing",
    },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C02.00", name: "Additive Manufacturing" },
    Subcategory: {
      code: "C02.06",
      name: "Scanning , digitization, metrology and Inspection equipment",
    },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C02.00", name: "Additive Manufacturing" },
    Subcategory: { code: "C02.07", name: "Metal Moulding and Tooling" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C02.00", name: "Additive Manufacturing" },
    Subcategory: { code: "C02.08", name: "Hybrid Process" },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C02.00", name: "Additive Manufacturing" },
    Subcategory: {
      code: "C02.09",
      name: "Input Materials – Powders, Granules, Filaments, Resins (Metal & Plastic)",
    },
  },
  {
    MainCategory: { code: "C", name: "Digital Manufacturing 2024" },
    Category: { code: "C02.00", name: "Additive Manufacturing" },
    Subcategory: { code: "C02.10", name: "Additive Manufacturing, anyother" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D01.00", name: "Joining & Welding" },
    Subcategory: { code: "D01.01", name: "Arc, Gas welding" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D01.00", name: "Joining & Welding" },
    Subcategory: { code: "D01.02", name: "Building-up Welding" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D01.00", name: "Joining & Welding" },
    Subcategory: { code: "D01.03", name: "Edge preparation machine" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D01.00", name: "Joining & Welding" },
    Subcategory: { code: "D01.04", name: "Electron Beam Welding" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D01.00", name: "Joining & Welding" },
    Subcategory: { code: "D01.05", name: "Friction welding" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D01.00", name: "Joining & Welding" },
    Subcategory: { code: "D01.06", name: "Laser welding" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D01.00", name: "Joining & Welding" },
    Subcategory: { code: "D01.07", name: "Riveting Machine" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D01.00", name: "Joining & Welding" },
    Subcategory: { code: "D01.08", name: "Robotic welding" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D01.00", name: "Joining & Welding" },
    Subcategory: { code: "D01.09", name: "Seam and Butt Welding Machines" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D01.00", name: "Joining & Welding" },
    Subcategory: { code: "D01.10", name: "Small Electric Welding Machines" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D01.00", name: "Joining & Welding" },
    Subcategory: { code: "D01.11", name: " Spot welding" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D01.00", name: "Joining & Welding" },
    Subcategory: { code: "D01.12", name: "Joining & Welding, any other" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D02.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "D02.01", name: "Welding Fume Exhaust Units" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D02.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "D02.02", name: "Welding Material" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D02.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "D02.03", name: "Welding Accessories" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D02.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "D02.04", name: "Other Welding Equipment" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D02.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "D02.05", name: "Thermal Cutting Accessories" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D02.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "D02.06", name: "Welding Torches" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D02.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "D02.07", name: "Thermal Cutting Torches" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D02.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "D02.08", name: "Portable Welding machines" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D02.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "D02.09", name: "Welding Positioners" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D02.00", name: "Welding Material & Accessories" },
    Subcategory: { code: "D02.10", name: "Soldering Materials" },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D02.00", name: "Welding Material & Accessories" },
    Subcategory: {
      code: "D02.11",
      name: "Soldering Equipment and Accessories",
    },
  },
  {
    MainCategory: { code: "D", name: "WeldExpo 2024" },
    Category: { code: "D02.00", name: "Welding Material & Accessories" },
    Subcategory: {
      code: "D02.12",
      name: "Welding Material & Accessories, any other",
    },
  },
];
