import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  EXHIBITOR,
  EXHIBITOR_API,
  INTERACT_FAVORITE,
  INTERACT_MEETING,
  INTERACT_MESSAGE,
  VISITOR,
  VISITOR_API,
} from "../../../../constants/constants";
import { getExhibitorHeader, getHeader } from "../../../../utility/utility";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import VisitorCard from "../../../../widgets/VisitorCard";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ProfileDetails from "../ProfileDetails";
import MessagePopup from "../../exhibitor/MessagePopup";

const Visitors = () => {
  const PARENT_VIEW = "parent_view";
  const EXHIBITOR_VIEW = "exhibitor_view";
  const [currentView, setCurrentView] = useState(PARENT_VIEW);
  const [currentUser, setCurrentUser] = useState(null);

  const [openMessage, setOpenMessage] = useState(false);
  const [senderMessage, setSenderMessage] = useState();
  const [recipientMessage, setRecipientMessage] = useState();

  const [loading, setLoading] = useState(false);
  const [visitors, setVisitors] = useState([]);

  const onVisitorSelect = (cuser) => {
    setCurrentUser(cuser);
    console.log(cuser);
    setCurrentView(EXHIBITOR_VIEW);
  };

  useEffect(() => {
    loadVisitors();
  }, []);

  function loadVisitors() {
    setLoading(true);
    axios
      .get(VISITOR_API + "get-visitors", getHeader())
      .then((res) => {
        setLoading(false);
        const d = res.data;
        console.log(d);
        if (d.success) {
          setVisitors(d.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`An error occurred while fetching exhibitors: ${err}`);
      });
  }
  const onInteract = (type, cuser) => {
    switch (type) {
      case INTERACT_MESSAGE:
        setSenderMessage(VISITOR);
        setRecipientMessage({ username: cuser, type: VISITOR });
        setOpenMessage(true);
        break;
      case INTERACT_MEETING:
        console.log("meet");
        break;
      case INTERACT_FAVORITE:
        console.log("fav");
        break;
      default:
        console.log("nothing selected");
    }
  };
  return (
    <div>
      <MessagePopup
        sender={senderMessage}
        recipient={recipientMessage}
        open={openMessage}
        setOpen={setOpenMessage}
      />
      {currentView !== PARENT_VIEW && (
        <Button
          sx={{ marginTop: "10px", marginLeft: "8px" }}
          onClick={() => setCurrentView(PARENT_VIEW)}
          variant="outlined"
          startIcon={<ArrowBackIosNewIcon />}
          size="small"
        >
          Back
        </Button>
      )}
      <CustomCircularP show={loading} />
      <br />

      {currentView === PARENT_VIEW ? (
        <div>
          {visitors.map((exh, i) => (
            <VisitorCard
              onInteract={onInteract}
              key={i}
              item={exh}
              onVisitorSelect={onVisitorSelect}
            />
          ))}
        </div>
      ) : (
        <>
          {currentUser && <ProfileDetails isView={true} user={currentUser} />}
        </>
      )}
    </div>
  );
};

export default Visitors;
