import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import useCountries from "./hooks/useCountries";

function CountriesCom({ state, handleStateChange, label = "Select Country*" }) {
  const { data, isLoading, isError, error } = useCountries(); // Fetch visitors for page 1
  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };
  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name="selectedCountry"
        value={state.selectedCountry}
        label={label}
        onChange={handleInputChange}
      >
        {data
          ?.sort((a, b) => {
            if (a.name === "India") return -1;
            if (b.name === "India") return 1;
            return a.name.localeCompare(b.name);
          })
          .map((target) => (
            <MenuItem key={target.isoCode} value={target}>
              {target.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default CountriesCom;
