import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import useStates from "../../../../../visitorregt/components/previewpage/hooks/useStates";

function StatesSelect({ state, handleStateChange }) {
  const { data, isLoading, isError, error } = useStates(
    state.country ? state.country : ""
  );

  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };
  if (isLoading) {
    return <LinearProgress />;
  }
  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">Select State*</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name="state"
        value={state.state}
        label="Select State*"
        onChange={handleInputChange}
      >
        {data &&
          data.map((target) => (
            <MenuItem key={target.isoCode} value={target.isoCode}>
              {target.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default StatesSelect;
