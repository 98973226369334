import {
  Card,
  Typography,
  Box,
  Alert,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Container,
  FormGroup,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import Supplier from "./reuse/Supplier";
import { LoadingButton } from "@mui/lab";
import Supplier2 from "./reuse/Supplier2";
import { tab } from "@testing-library/user-event/dist/tab";
import { EXHIBITOR_API } from "../../../../constants/constants";
import axios from "axios";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
import MuPb from "../../../../widgets/MuPb";
const SiteHandling = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();
  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [services, setServices] = useState([
    { id: 1, label: "Offloading & Shifting to stand", checked: false },
    { id: 2, label: "Unpacking & Placement in one operation", checked: false },
    { id: 3, label: "Only removal of base without un-packing", checked: false },
    {
      id: 4,
      label: "Only placement on base without re-packing",
      checked: false,
    },
    { id: 5, label: "Repacking with same packing material", checked: false },
    {
      id: 6,
      label: "Removal from stand and loading on vehicle in one operation",
      checked: false,
    },
    {
      id: 7,
      label:
        "Removal of empties to storage & Return after the closure of the exhibition",
      checked: false,
    },
    // { id: 8, label: "Other services", checked: false },
  ]);

  const [tableData, setTableData] = useState([
    {
      description: "",
      height: 0,
      length: 0,
      width: 0,
      weight: 0,
      qty: 0,
      manufacturer: "",
    },
  ]);
  function generateChecklistHtml(mdata) {
    return mdata
      .map(
        (item, index) => `
        <tr>
          <td>${item.label}</td>
          <td>${item.checked ? "Yes" : "No"}</td>
        </tr>
      `
      )
      .join("");
  }
  function generateFormDataHtml(mdata) {
    return mdata
      .map(
        (item, index) => `
      <tr>
        <td>${item.description}</td>
        <td>${item.height}</td>
        <td>${item.length}</td>
        <td>${item.width}</td>
        <td>${item.weight}</td>
        <td>${item.qty}</td>
        <td>${item.manufacturer}</td>
      </tr>
    `
      )
      .join("");
  }
  const checklistHtml = generateChecklistHtml(services);
  const formDataHtml = generateFormDataHtml(tableData);
  const handleChange = (event, id) => {
    const updatedServices = services.map((service) => {
      if (service.id === id) {
        return { ...service, checked: event.target.checked };
      }
      return service;
    });
    setServices(updatedServices);
  };

  const handleInputChange = (event, index, fieldName) => {
    // This creates a new array with all current table data
    const newData = [...tableData];
    // Update the specific field with the new value
    newData[index][fieldName] = event.target.value;
    // Set the new state
    setTableData(newData);
  };

  const handleAdd = () => {
    setTableData((prevData) => [
      ...prevData,
      {
        description: "",
        height: 0,
        length: 0,
        width: 0,
        weight: 0,
        qty: 0,
        manufacturer: "",
      },
    ]);
  };
  if (isLoading) {
    return <MuPb />;
  }

  const handleRemove = (index) => {
    const newData = [...tableData];
    newData.splice(index, 1);
    setTableData(newData);
  };
  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${
      supplier.email_id
    }</p></div></div><br/> <table><tr><th>Description</th><th>Status</th></tr>${checklistHtml}</table> ${spaceTemplate(
      data
    )} <br /><table><tr><th>Description</th><th>Height</th><th>Length</th><th>Width</th><th>Weight</th><th>Qty</th><th>Manufacturer</th></tr>${formDataHtml}</table> <br /><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "5.1 Site Handling",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "5.1 Site Handling",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };

  return (
    <Container>
      <Card elevation={4} style={{ padding: "24px", margin: "auto" }}>
        <Typography variant="h5" gutterBottom>
          5.1 Site Handling
        </Typography>

        <Box mb={3}>
          {/* <Alert severity="warning">
            Page not yet submitted, the deadline is 23 Dec, 2024.
          </Alert> */}
        </Box>

        <Typography variant="h6" gutterBottom>
          GST
        </Typography>

        <Typography gutterBottom>
          <p>
            The Govt. of India has levied a GST of 18% on handling charges plus
            on other charges except for reimbursement expenses to be invoiced by
            all freight forwarder and handling agents. Therefore the same shall
            be charged accordingly.
          </p>
          We would require the following Services [Please tick (✓) your
          requirements].
        </Typography>

        <FormGroup>
          {services.map((service) => (
            <FormControlLabel
              key={service.id}
              control={
                <Checkbox
                  checked={service.checked}
                  onChange={(event) => handleChange(event, service.id)}
                />
              }
              label={service.label}
            />
          ))}
        </FormGroup>

        <SpaceInfo data={data} />

        <Box>
          <div style={{ overflowX: "auto" }}>
            <TableContainer component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>Height(m)</TableCell>
                    <TableCell>Length(m)</TableCell>
                    <TableCell>Width(m)</TableCell>
                    <TableCell>Weight(kgs)</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Manufacturer</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          style={{ minWidth: "90px" }}
                          size="small"
                          value={row.description}
                          onChange={(e) =>
                            handleInputChange(e, index, "description")
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          style={{ minWidth: "90px" }}
                          size="small"
                          type="number"
                          value={row.height}
                          onChange={(e) =>
                            handleInputChange(e, index, "height")
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          style={{ minWidth: "90px" }}
                          size="small"
                          type="number"
                          value={row.length}
                          onChange={(e) =>
                            handleInputChange(e, index, "length")
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          style={{ minWidth: "90px" }}
                          size="small"
                          type="number"
                          value={row.width}
                          onChange={(e) => handleInputChange(e, index, "width")}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          style={{ minWidth: "90px" }}
                          size="small"
                          type="number"
                          value={row.weight}
                          onChange={(e) =>
                            handleInputChange(e, index, "weight")
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          style={{ minWidth: "90px" }}
                          size="small"
                          type="number"
                          value={row.qty}
                          onChange={(e) => handleInputChange(e, index, "qty")}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          style={{ minWidth: "90px" }}
                          size="small"
                          value={row.manufacturer}
                          onChange={(e) =>
                            handleInputChange(e, index, "manufacturer")
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={() => handleRemove(index)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button
            sx={{ mt: 1 }}
            size="small"
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAdd}
          >
            Add Row
          </Button>
        </Box>

        <Supplier2
          user={data}
          id={11}
          supplier={supplier}
          setSupplier={setSupplier}
        />

        <Box mt={4} mb={2}>
          <LoadingButton
            onClick={onSubmit}
            loading={submitting}
            disabled={!supplier}
            variant="contained"
            color="primary"
          >
            Submit
          </LoadingButton>
        </Box>
      </Card>
    </Container>
  );
};

export default SiteHandling;
