import React from "react";
import About from "./About";
import Company from "./Company";
import Professional from "./Professional";
import Categories from "./Categories";

const ProfileContainer = ({ profile, isView = false, setProfile }) => {
  return (
    <div>
      <About profile={profile} isView={isView} setProfile={setProfile} />
      <Company profile={profile} isView={isView} setProfile={setProfile} />
      <Professional profile={profile} isView={isView} setProfile={setProfile} />
      <Categories profile={profile} isView={isView} setProfile={setProfile} />
    </div>
  );
};

export default ProfileContainer;
