import React from "react";
import ComingSoon from "../exhibitor/ComingSoon";

const MySchedule = () => {
  return (
    <div className="rd-dash-content">
      <ComingSoon />
    </div>
  );
};

export default MySchedule;
