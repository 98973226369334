import React from "react";
import vBg from "../../../assets/visitor_bg.png"; // Path to your image
import { Box } from "@mui/material";
import QRCode from "react-qr-code";
export const BadgeToPrint = React.forwardRef((props, ref) => {
  const { user, isPreview = false } = props;
  const style = {
    badgeContainer: {
      marginTop: "16px",
      marginLeft: "16px",
      position: "relative",
      width: "10cm",
      height: "14cm",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      color: "black",
      boxSizing: "border-box",
    },
    detailsContainer: {
      textAlign: "center",
      marginTop: "4cm",
    },
    qrCode: {
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
      marginTop: "0.5cm", // Adjust the margin as needed
      width: "3.5cm", // Adjust size as needed
      height: "3.5cm", // Adjust size as needed
    },
  };
  const vCard = [
    "BEGIN:VCARD",
    "VERSION:3.0",
    `NOTE: Barcode ID: ${user.barcode_id}, User ID: ${user.user_id}`,
    `N:${user.lastName};${user.firstName};;;`,
    `FN:${user.title} ${user.firstName} ${user.lastName}`,
    `ORG:${user.companyName}`,
    `TITLE:${user.designation}`,
    `EMAIL;TYPE=PREF,INTERNET:${user.email}`,
    "END:VCARD",
  ].join("\n");

  return (
    <div ref={ref} style={style.badgeContainer}>
      <div style={style.detailsContainer}>
        {isPreview && (
          <img
            src={vBg}
            alt="Badge Background"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          />
        )}
        <Box
          sx={{
            position: "absolute",
            top: "5cm",
            left: ".2cm",
            right: ".2cm",
            bottom: "1cm",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "22px", mt: 2 }}>
            {`${user?.firstName?.toUpperCase()} ${user?.lastName?.toUpperCase()}`}
          </Box>
          <Box sx={{ fontWeight: "bold", fontSize: "18px", mt: 1 }}>
            {`${user?.companyName?.toUpperCase()}`}
          </Box>
          {/* <Box sx={{ fontWeight: "bold", fontSize: "17px", mt: 1 }}>
            {`${user.selectedCountry.name}`}
          </Box> */}
          <Box mt={2}>
            <QRCode value={vCard} size={130} />
          </Box>
        </Box>
      </div>
      <div />
    </div>
  );
});
