import { useQuery } from "react-query";
import axios from "axios";
import { getHeader } from "../../../../utility/utility";
import { VISITOR_API } from "../../../../constants/constants";

const fetchItem = async () => {
  try {
    const res = await axios.get(`${VISITOR_API}get-profile`, getHeader());
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const useLoginProfile = () => {
  const queryInfo = useQuery(["get-visitor-profile"], () => fetchItem(), {
    staleTime: 0 * 60 * 1000, // Data will be considered stale after 5 minutes
    retry: 0,
  });

  const refetchVisitorProfile = async () => {
    await queryInfo.refetch();
  };

  return {
    ...queryInfo,
    refetchVisitorProfile,
  };
};

export default useLoginProfile;
