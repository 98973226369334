// useAllVisitors.js

import { useQuery } from "react-query";
import axios from "axios";
import { ADMIN_API, PRODUCT_CATEGORIES } from "../../../../constants/constants";
import {
  extractDate,
  extractTime,
  getAdminHeader,
} from "../../../../utility/utility";
import * as XLSX from "xlsx";

const fetchAllVisitors = () => {
  return axios
    .get(`${ADMIN_API}/get-all-visitors`, getAdminHeader())
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

export function useAllVisitors() {
  const handleVisitorsLoaded = (data) => {
    const filteredData = data.data.map((item) => {
      // Remove _id from each object and transform country and state names
      const {
        _id,
        user_id,
        barcode_id,
        eventsList,
        title,
        firstName,
        lastName,
        phone,
        email,
        companyName,
        name,
        user_type,
        address,
        selectedCountry,
        selectedState,
        selectedCity,
        pin,
        website,
        telephone,
        designation,
        department,
        selectedEventSource,
        selectedIndustrySector,
        selectedCategories,
        qrLink,
        createdOn,
      } = item;

      const productCats = selectedCategories?.map((item) => {
        const catCode = item.split("-")[0].trim();
        const catO = PRODUCT_CATEGORIES.find(
          (pC) => pC.Subcategory.code === catCode
        );
        return catO.Category.name;
      });
      const productSubCats = selectedCategories?.map((item) => {
        const catCode = item.split("-")[0].trim();
        const catO = PRODUCT_CATEGORIES.find(
          (pC) => pC.Subcategory.code === catCode
        );
        return catO.Subcategory.name;
      });
      const productMainCats = selectedCategories?.map((item) => {
        const catCode = item.split("-")[0].trim();
        const catO = PRODUCT_CATEGORIES.find(
          (pC) => pC.Subcategory.code === catCode
        );
        return catO.MainCategory.name;
      });
      const uniqueMainCats = [...new Set(productMainCats)].join(", ");
      const uniqueSubCats = [...new Set(productSubCats)].join(", ");
      const uniqueCats = [...new Set(productCats)].join(", ");

      return {
        "User ID": user_id,
        "Barcode ID": barcode_id,
        "User Type": user_type,
        "Events List": Array.isArray(eventsList) ? eventsList?.join(", ") : [],
        Title: title,
        "First Name": firstName,
        "Last Name": lastName,
        name: name,
        "Phone Number": phone,
        Email: email,
        "Company Name": companyName,
        Address: address,
        Country: selectedCountry?.name,
        State: selectedState?.name,
        City: selectedCity,
        "Postal Code": pin,
        Website: website,
        Telephone: telephone,
        Designation: designation,
        Department: department,
        "Event Source": selectedEventSource,
        "Industry Sector": selectedIndustrySector,
        "Main Category": uniqueMainCats,
        "Sub Category": uniqueSubCats,
        Category: uniqueCats,
        "QR Link": qrLink,
        Date: extractDate(createdOn),
        Time: extractTime(createdOn),
      };
    });

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Visitors");
    XLSX.writeFile(wb, "visitors.xlsx");
  };
  const {
    data: allVisitors,
    isLoading: loadingVisitors,
    isError: visitorsFetchError,
    error: errorDetails,
    refetch: refetchAllVisitors,
  } = useQuery("allVisitors", fetchAllVisitors, {
    enabled: false, // query will not automatically run
    onSuccess: handleVisitorsLoaded,
  });

  return {
    allVisitors,
    loadingVisitors,
    visitorsFetchError,
    errorDetails,
    refetchAllVisitors,
  };
}
