import React, { useEffect, useState } from "react";
import HeaderLabel from "../../../../widgets/HeaderLabel";
import ProfileInfoCard from "../../../../widgets/ProfileInfoCard";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import BasicInfoEdit from "./edit/BasicInfoEdit";
import { useSearchParams } from "react-router-dom";

const About = ({ profile, isView = false, setProfile }) => {
  const [showEdit, setShowEdit] = useState(false);
  const onEditAbout = () => {
    setShowEdit(true);
  };
  return (
    <div className="profile-info-c">
      <RdMyModal
        showModal={showEdit}
        setShowModal={setShowEdit}
        modalC={
          <BasicInfoEdit
            profile={profile}
            setProfile={setProfile}
            setShow={setShowEdit}
          />
        }
      />

      <HeaderLabel showEdit={!isView} onEdit={onEditAbout} label="About" />
      <div className="rd-items-three">
        <ProfileInfoCard infoCard={{ label: "Title", value: profile.title }} />
        <ProfileInfoCard
          infoCard={{ label: "First Name", value: profile.firstName }}
        />
        <ProfileInfoCard
          infoCard={{ label: "Last Name", value: profile.lastName }}
        />
        <ProfileInfoCard
          infoCard={{ label: "Designation", value: profile.designation }}
        />
        <ProfileInfoCard infoCard={{ label: "Phone", value: profile.phone }} />
        <ProfileInfoCard infoCard={{ label: "Email", value: profile.email }} />
      </div>
    </div>
  );
};

export default About;
