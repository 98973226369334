import React, { useState } from "react";
import {
  Card,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Select,
  MenuItem,
  Divider,
  InputLabel,
  Container,
  Paper,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import TeamMember from "../../../../widgets/TeamMember";
import { DEFAULT_DP, EXHIBITOR_API } from "../../../../constants/constants";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import MuPb from "../../../../widgets/MuPb";
import AddIcon from "@mui/icons-material/Add";
import useTeamMembers from "../hooks/useTeamMembers";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import AddTeamMember from "../AddTeamMember";
import axios from "axios";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import SimpleYesNoDialog from "../../../../widgets/SimpleYesNoDialog";
import { LoadingButton } from "@mui/lab";
import Supplier2 from "./reuse/Supplier2";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
import { SubmittedStatus, oesMenus } from "./Services";
import { useParams } from "react-router-dom";
const ExhibitorBadges = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();
  const { id } = useParams();
  const {
    data: teamMembersData,
    isLoading: isMembersLoading,
    isError: isMembersError,
    error: membersError,
    refetchMembers,
  } = useTeamMembers(1);
  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();

  const [showAddModal, setShowAddModal] = useState(false);
  const [member, setMember] = useState(null);
  const [mode, setMode] = useState("add");
  const [deleting, setDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const onAddTeamMember = () => {
    setMode("add");
    setMember(null);
    setShowAddModal(true);
  };
  const onTeamAdded = () => {
    setShowAddModal(false);
    refetchMembers();
  };
  const onEditMember = (member) => {
    setMode("edit");
    setMember(member);
    setShowAddModal(true);
  };
  const onDeleteMember = (member) => {
    setMember(member);
    setShowDelete(true);
  };
  const onYesDelete = async () => {
    setShowDelete(false);
    setDeleting(true);

    try {
      const { data } = await axios.delete(
        `${EXHIBITOR_API}delete-team-member?id=${member._id}`,
        getExhibitorHeader()
      );
      setDeleting(false);
      toast.success(data.message);
      onTeamAdded();
    } catch (error) {
      setDeleting(false);
      toast.error(error.message);
    }
  };
  if (isMembersLoading) {
    return <MuPb />;
  }
  const employeeRows = teamMembersData.data
    .map(
      (employee, index) =>
        `<tr key=${index}>
      <td>${employee.firstName} ${employee.lastName}</td>
      <td>${employee.jobTitle}</td>
      <td>${employee.email}</td>
      <td>${employee.phone}</td>
    </tr>`
    )
    .join("");
  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div> ${spaceTemplate(
      data
    )}<div class="box"> <p><strong>Space Allocated:</strong> ${
      data?.standSize.value
    }</p></div><div class="box"> <p><strong>No of Badges Entitled:</strong> ${Math.ceil(
      (data?.standSize.value - 12) / 10 + 3
    )}</p></div><br/> <table> <tr><th>Name</th><th>Designation</th><th>Email</th><th>Phone</th></tr>${employeeRows}</table> <br/> <img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "4.3 Exhibitor Badges",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "4.3 Exhibitor Badges",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };

  return (
    <Container>
      <RdMyModal
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        modalC={
          <AddTeamMember
            member={member}
            mode={mode}
            onAdded={onTeamAdded}
            show={showAddModal}
            setShow={setShowAddModal}
          />
        }
      />
      <CustomCircularP show={deleting} />
      <SimpleYesNoDialog
        title="Please Confirm!"
        message="Are you sure you want to delete this?"
        onYes={onYesDelete}
        show={showDelete}
        setShow={setShowDelete}
      />
      <Paper elevation={3} style={{ padding: "2rem" }}>
        <Box display="flex" mb={2}>
          <Typography flex={1} variant="h5">
            4.3 Exhibitor Badges
          </Typography>
          <Box>
            <Button
              startIcon={<AddIcon />}
              sx={{ textTransform: "none" }}
              size="small"
              variant="contained"
              onClick={onAddTeamMember}
            >
              Add Team Member
            </Button>
          </Box>
        </Box>

        <Box mb={2}>
          <Alert severity="info">
            <Typography variant="subtitle1">
              Limit for team members creation has been set as{" "}
              {Math.ceil((data?.standSize.value - 12) / 10 + 3)}.{" "}
              {teamMembersData.totalItems} team members have been already added.
              You can add{" "}
              {Math.ceil((data?.standSize.value - 12) / 10 + 3) -
                teamMembersData.totalItems}{" "}
              more badges.
            </Typography>
          </Alert>
        </Box>
        {/* <Box mb={4}>
          <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 23 Dec, 2024.
            </Typography>
          </Alert>
        </Box> */}

        <Typography variant="body1" gutterBottom>
          Fax/Mail to reach IMTMA before 5 Jan 2024. Please collect Exhibitor
          badges from respective Hall Directors booth located in Halls from 12
          Jan 2024 onwards.
        </Typography>
        <SpaceInfo data={data} />
        <Box mt={3}>
          <Box mb={2}>
            <Typography>
              <strong>Space Allocated:</strong> {data?.standSize.value}
            </Typography>
          </Box>

          <Box mb={2}>
            <Typography>
              <strong>No of Badges Entitled:</strong>{" "}
              {Math.ceil((data?.standSize.value - 12) / 10 + 3)}
            </Typography>
          </Box>
        </Box>
        {/* <Typography variant="body1" sx={{ mb: 3 }}>
          Please provide us {teamMembersData.data.length} exhibitor badges for
          the following personnel at our stand.
        </Typography> */}
        {teamMembersData.data.map((team, i) => (
          <TeamMember
            viewMode={false}
            key={i}
            item={team}
            onEdit={onEditMember}
            onDelete={onDeleteMember}
          />
        ))}

        {/* Supplier */}
        <Supplier2
          user={data}
          id={6}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            loading={submitting}
            disabled={!supplier}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Paper>
    </Container>
  );
};

export default ExhibitorBadges;
