import { Cropper } from "react-cropper";
import { useEffect, useRef, useState } from "react";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import { toast } from "react-toastify";
import { Grid, Button, Typography, Input, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  getExhibitorMultipartHeader,
  getFileSizeOnlyValue,
} from "../../../utility/utility";
import { API_ENDPOINT, EXHIBITOR_API } from "../../../constants/constants";
function ProductImageCrop({ onUpdated, show, setShow }) {
  const aspect_ratio = 1;
  const cropperRef = useRef(null);
  const image_selector = useRef();
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(null);
  const [cropperKey, setCropperKey] = useState(Date.now());

  useEffect(() => {
    setImage(null);
    // Reset the file input field
    if (image_selector.current) {
      image_selector.current.value = "";
    }

    // Optionally, reset the cropper state if needed
    if (cropperRef.current && cropperRef.current.cropper) {
      cropperRef.current.cropper.reset();
    }
    setCropperKey(Date.now());
  }, [show]);

  const onInputChange = (event) => {
    const file = event.target.files[0];
    let mbSize = getFileSizeOnlyValue(file, "MB");
    if (mbSize > 2) {
      toast.info("The input file size exceeds 2MB");
      return;
    }
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const cancelCrop = () => {
    setImage(null);
    if (image_selector.current) {
      image_selector.current.value = "";
    }
    setShow(false);
  };

  const uploadImage = async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const base64Image = cropper.getCroppedCanvas().toDataURL();

    // remove prefix 'data:image/png;base64,' from the string
    const base64ImageContent = base64Image.replace(
      /^data:image\/(png|jpg);base64,/,
      ""
    );
    const blob = base64toBlob(base64ImageContent, "image/png");

    let formData = new FormData();
    formData.append("image", blob);
    //formData.append("upload_type", upload_type);
    setUploading(true);
    try {
      const { data } = await axios.post(
        API_ENDPOINT + "uploads/upload-image-file",
        formData,
        getExhibitorMultipartHeader()
      );
      setUploading(false);
      toast.success("Uploaded Successfully");
      onUpdated(data.message);
      cancelCrop();
    } catch (error) {
      setUploading(false);
      toast.error(error.message);
    }
  };
  const base64toBlob = (base64Data, contentType = "") => {
    contentType = contentType || "";
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    return new Blob(byteArrays, { type: contentType });
  };
  return (
    <Box style={{ height: "70vh" }}>
      {/* Informing user about aspect ratio and supported formats */}
      <Box
        sx={{
          display: "table",
          color: "#777",
          fontSize: "14px",
          padding: "8px",
          border: "1px dotted #ddd",
          marginBottom: "16px",
        }}
      >
        <Input
          type="file"
          inputProps={{ accept: "image/jpeg, image/png, image/jpg" }}
          onChange={onInputChange}
        />
        <Typography variant="body2">
          Aspect Ratio: {aspect_ratio}:1 (Max size: 2MB)
        </Typography>
        <Typography variant="body2">
          Supported Formats: PNG, JPEG, JPG
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Cropper
            key={cropperKey}
            src={image}
            style={{ height: 300, width: "100%" }}
            initialAspectRatio={aspect_ratio}
            aspectRatio={aspect_ratio}
            preview=".img-preview"
            ref={cropperRef}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography align="center">Preview</Typography>
          <div
            key={cropperKey}
            className="img-preview"
            style={{
              width: "100%",
              height: "172px",
              overflow: "hidden",
              display: "inline-block",
            }}
          ></div>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "12px",
        }}
      >
        <LoadingButton
          loading={uploading}
          variant="contained"
          color="primary"
          onClick={uploadImage}
          style={{ margin: "0 8px" }}
        >
          Upload
        </LoadingButton>
        <Button
          variant="outlined"
          color="secondary"
          onClick={cancelCrop}
          style={{ margin: "0 8px" }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
}

export default ProductImageCrop;
