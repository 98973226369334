// useAllExhibitors.js

import { useQuery } from "react-query";
import axios from "axios";
import { ADMIN_API } from "../../../../constants/constants";
import { getAdminHeader } from "../../../../utility/utility";
import * as XLSX from "xlsx";

const fetchAllExhibitors = () => {
  return axios
    .get(`${ADMIN_API}/get-all-exhibitors`, getAdminHeader())
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

export function useAllExhibitors() {
  const handleExhibitorsLoaded = (data) => {
    const filteredData = data.data.map((item) => {
      console.log(item);
      const {
        exhibitorId,
        username,
        password,
        companyName,
        contactSalutation,
        contactFirstName,
        contactLastName,
        contactDesignation,
        mobile,
        companyEmail,
        contactEmail,
        address1,
        address2,
        address3,
        country,
        state,
        city,
        pin,
        phone,
        fax,
        website,
        companyShortName,
        fascia,
        weAre,
        industrySector,
        exhibitorCategory,
        productCategories,
        hallNumber,
        standNumber,
        spaceType,
        exhibitArrival,
        exhibitArrivalTimeFrom,
        exhibitArrivalTimeTo,
        materialArrival,
        materialArrivalTimeFrom,
        materialArrivalTimeTo,
        dismantling,
        dismantlingTimeFrom,
        dismantlingTimeTo,
        standSize,
      } = item;

      return {
        "Exhibitor ID": exhibitorId,
        Username: username,
        Password: password,
        [companyName.label]: companyName.value,
        [contactSalutation.label]: contactSalutation.value,
        [contactFirstName.label]: contactFirstName.value,
        [contactLastName.label]: contactLastName.value,
        [contactDesignation.label]: contactDesignation.value,
        [mobile.label]: mobile.value,
        [companyEmail.label]: companyEmail.value,
        [contactEmail.label]: contactEmail.value,
        [address1.label]: address1.value,
        [address2.label]: address2.value,
        [address3.label]: address3.value,
        [country.label]: country?.value?.name,
        [state.label]: state?.value?.name,
        [city.label]: city.value,
        [pin.label]: pin.value,
        [phone.label]: phone.value,
        [fax.label]: fax.value,
        [website.label]: website.value,
        [companyShortName.label]: companyShortName.value,
        [fascia.label]: fascia.value,
        [weAre.label]: weAre.value,
        [industrySector.label]: industrySector.value,
        [exhibitorCategory.label]: exhibitorCategory.value,
        [productCategories.label]: productCategories.value,
        [hallNumber.label]: hallNumber.value,
        [standNumber.label]: standNumber.value,
        [spaceType.label]: spaceType.value,
        [exhibitArrival.label]: exhibitArrival.value,
        [exhibitArrivalTimeFrom.label]: exhibitArrivalTimeFrom.value,
        [exhibitArrivalTimeTo.label]: exhibitArrivalTimeTo.value,
        [materialArrival.label]: materialArrival.value,
        [materialArrivalTimeFrom.label]: materialArrivalTimeFrom.value,
        [materialArrivalTimeTo.label]: materialArrivalTimeTo.value,
        [dismantling.label]: dismantling.value,
        [dismantlingTimeFrom.label]: dismantlingTimeFrom.value,
        [dismantlingTimeTo.label]: dismantlingTimeTo.value,
        [standSize.label]: standSize.value,
      };
    });
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Exhibitors");
    XLSX.writeFile(wb, "exhibitors.xlsx");
  };
  const {
    data: allExhibitors,
    isLoading: loadingExhibitors,
    isError: exhibitorsFetchError,
    error: errorDetails,
    refetch: refetchAllExhibitors,
  } = useQuery("allExhibitors", fetchAllExhibitors, {
    enabled: false, // query will not automatically run
    onSuccess: handleExhibitorsLoaded,
  });

  return {
    allExhibitors,
    loadingExhibitors,
    exhibitorsFetchError,
    errorDetails,
    refetchAllExhibitors,
  };
}
