import { useQuery } from "react-query";
import axios from "axios";
import {
  EXHIBITOR_API,
  VISITORS,
  VISITOR_API,
} from "../../../../../constants/constants";
import { getExhibitorHeader, getHeader } from "../../../../../utility/utility";

const fetchItem = async (type) => {
  try {
    const res = await axios.get(
      `${type === VISITORS ? VISITOR_API : EXHIBITOR_API}get-profile`,
      type === VISITORS ? getHeader() : getExhibitorHeader()
    );
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const useProfileType = (type) => {
  const queryInfo = useQuery(
    ["get-profile-type", type],
    () => fetchItem(type),
    {
      staleTime: 0 * 60 * 1000, // Data will be considered stale after 5 minutes
      retry: 0,
    }
  );

  const refetchProfile = async () => {
    await queryInfo.refetch();
  };

  return {
    ...queryInfo,
    refetchProfile,
  };
};

export default useProfileType;
