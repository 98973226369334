import React, { useEffect, useState } from "react";
import {
  Card,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Select,
  MenuItem,
  Divider,
  InputLabel,
  Container,
  Paper,
  Stack,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import MuPb from "../../../../widgets/MuPb";
import Supplier2 from "./reuse/Supplier2";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { EXHIBITOR_API } from "../../../../constants/constants";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
import { SubmittedStatus, oesMenus } from "./Services";
import { useParams } from "react-router-dom";

const StandPossession = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();
  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
  });

  useEffect(() => {
    if (data) {
      setFormData({
        name: `${data.contactFirstName.value} ${data.contactLastName.value}`,
        designation: data.contactDesignation.value,
      });
    }
  }, [data]);

  if (isLoading) {
    return <MuPb />;
  }
  const updateProperty = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = async () => {
    if (name === "") {
      toast.warning("Name is required");
      document.getElementsByName("name")[0].focus();
      return;
    }
    if (designation === "") {
      toast.warning("Designation is required");
      document.getElementsByName("designation")[0].focus();
      return;
    }
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Tempplate</title> <style>.box{margin-bottom: 16px; /* 2rem equivalent */ font-size: 18px;}strong{font-weight: bold;}</style> </head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div>${spaceTemplate(
      data
    )}<div class="box"> <p><strong>Name:</strong> ${
      formData.name
    }</p></div><div class="box"> <p><strong>Designation:</strong> ${
      formData.designation
    }</p></div><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;
    const toSubmitData = {
      template,
      name: "4.1 Stand Possession",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "4.1 Stand Possession",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  return (
    <Container>
      <Paper elevation={3} style={{ padding: "2rem" }}>
        <Typography variant="h5" gutterBottom>
          4.1 Stand Possession
        </Typography>

        {/* <Box mb={4}>
          <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 23 Dec, 2024.
            </Typography>
          </Alert>
        </Box> */}

        <Typography variant="body1" gutterBottom>
          Submit to the Hall Director in your hall on /after 12 Jan 2024 but
          before 18 Jan 2024 at Exhibition Site. Shell scheme stands will be
          handed over on 16 Jan 2024.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Please hand over possession of space allotted to us, to our
          representative
        </Typography>

        <SpaceInfo data={data} />

        <Stack spacing={3} direction="row">
          <TextField
            value={name}
            name="name"
            onChange={(e) => setName(e.target.value)}
            size="small"
            fullWidth
            label="Name"
            variant="outlined"
          />
          <TextField
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
            size="small"
            name="designation"
            fullWidth
            label="Designation"
            variant="outlined"
          />
        </Stack>

        {/* Supplier */}
        <Supplier2
          user={data}
          id={4}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            disabled={!supplier}
            onClick={onSubmit}
            loading={submitting}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Paper>
    </Container>
  );
};

export default StandPossession;
