import { IconButton } from "@mui/material";
import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditBtn from "./actionBtn/editBtn";
import DeleteBtn from "./actionBtn/deleteBtn";
import ProfileActionItem from "./ProfileActionItem";
import ProductItemAction from "./actionBtn/productItemAction";

import MessageIcon from "@mui/icons-material/Message";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StarIcon from "@mui/icons-material/Star";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ForumIcon from "@mui/icons-material/Forum";
import RoomIcon from "@mui/icons-material/Room";
import EastIcon from "@mui/icons-material/East";
import { DEFAULT_EX_ICON } from "../constants/constants";

const ProductItem = ({
  product,
  onEditProduct,
  onDeleteProduct,
  viewMode = false,
  onSelectProduct,
}) => {
  return (
    <div className="product-item" onClick={() => onSelectProduct(product)}>
      <div className="product-image-cont">
        <img src={product.images ? product.images[0] : ""} alt="" />
      </div>
      <div className="product-item-name">{product.name}</div>
      {viewMode ? (
        <div style={{ margin: "8px" }}>
          <div style={{ display: "flex" }}>
            <img
              style={{ width: "36px", height: "36px", borderRadius: "8px" }}
              src={
                product.exhibitorArray &&
                product.exhibitorArray.length > 0 &&
                product.exhibitorArray[0].profile_image
                  ? product.exhibitorArray[0].profile_image
                  : DEFAULT_EX_ICON
              }
            />
            <div
              style={{
                flex: 1,
                marginLeft: "10px",
                alignSelf: "center",
                fontWeight: 500,
                color: "#444",
                fontSize: "14px",
                whiteSpace: "nowrap", // Keeps the text in a single line
                overflow: "hidden", // Ensures the content is clipped
                textOverflow: "ellipsis", // Adds an ellipsis to clipped content
              }}
            >
              {product.exhibitorArray &&
                product.exhibitorArray.length > 0 &&
                product.exhibitorArray[0].companyShortName.value}
            </div>
          </div>

          <div style={{ display: "flex", margin: "4px 0" }}>
            <div style={{ flex: 1, display: "flex", gap: "12px" }}>
              <IconButton
                sx={{ backgroundColor: "#dbedff" }}
                aria-label="message"
                size="small"
              >
                <ForumIcon sx={{ color: "#00a2d9" }} fontSize="inherit" />
              </IconButton>
              <IconButton
                sx={{ backgroundColor: "#ffead2" }}
                aria-label="message"
                size="small"
              >
                <CalendarMonthIcon
                  sx={{ color: "#ff981f" }}
                  fontSize="inherit"
                />
              </IconButton>
              <IconButton
                sx={{ backgroundColor: "#ffead2" }}
                aria-label="message"
                size="small"
              >
                <StarIcon sx={{ color: "#fb4d24" }} fontSize="inherit" />
              </IconButton>
            </div>
            <IconButton aria-label="arrow" size="small">
              <EastIcon fontSize="inherit" sx={{ color: "#3cb4e0" }} />
            </IconButton>
          </div>
        </div>
      ) : (
        <ProductItemAction
          onEdit={() => onEditProduct(product)}
          onDelete={() => onDeleteProduct(product)}
        />
      )}
    </div>
  );
};

export default ProductItem;
