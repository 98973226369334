import React, { useEffect, useState } from "react";
import RdIconContainer from "../../../../widgets/RdIconContainer";
import EditIcon from "@mui/icons-material/Edit";
import {
  EXHIBITOR_API,
  ICON_TYPE_NON_IMAGE,
} from "../../../../constants/constants";
import ProfileInfoCard from "../../../../widgets/ProfileInfoCard";
import HeaderLabel from "../../../../widgets/HeaderLabel";
import RdLoadingButton from "../../../../widgets/RdLoadingButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, Button, Chip, Divider, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchSelect from "../../../../widgets/SearchSelect";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import axios from "axios";
import { getExhibitorHeader } from "../../../../utility/utility";
import SimpleYesNoDialog from "../../../../widgets/SimpleYesNoDialog";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import EditBlog from "../../../../widgets/EditBlog";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import AddIcon from "@mui/icons-material/Add";
import DynamicEditFields from "../../../../widgets/DynamicEditFields";
import AddBtn from "../../../../widgets/actionBtn/addBtn";
import Products from "./Products";
import TeamMembers from "./TeamMembers";
import UploadContent from "./UploadContent";
import EditAbout from "./edit/EditAbout";
import CloseIcon from "@mui/icons-material/Close";
import CompanyInfoEdit from "./edit/CompanyInfoEdit";
import CountryStateCard from "../../../../widgets/CountryStateCard";
import ContactInfoEdit from "./edit/ContactInfoEdit";
import IndustrySectorEdit from "./edit/IndustrySectorEdit";
import CategoriesEdit from "./edit/CategoriesEdit";
import SocialLinksEdit from "./edit/SocialLinksEdit";
const About = ({
  exhibitor,
  setCurrentTab,
  setReloadProfile,
  isView = false,
  onUpdated,
  id,
}) => {
  const [showSectorDeleteConfirm, setShowSectorDeleteConfirm] = useState(false);
  const [showCategoriesDeleteConfirm, setShowCategoriesDeleteConfirm] =
    useState(false);
  const [currentSector, setCurrentSector] = useState("");
  const [currentCategory, setCurrentCategory] = useState("");
  const [showEditAbout, setShowEditAbout] = useState(false);
  const [showContactInfoEdit, setShowContactInfoEdit] = useState(false);
  const [showEditCi, setShowEditCi] = useState(false);
  const [showEditIs, setShowEditIs] = useState(false);
  const [showEditCats, setShowEditCats] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [adding, setAdding] = useState(false);

  const onEditAboutCompany = () => {
    setShowEditAbout(true);
  };

  const onSectorDeleteConfirmed = () => {
    setShowSectorDeleteConfirm(false);
    setDeleting(true);
    axios
      .post(
        EXHIBITOR_API + "delete-sector",
        { sector: currentSector },
        getExhibitorHeader()
      )
      .then((res) => {
        setDeleting(false);
        const d = res.data;
        if (d.success) {
          setReloadProfile((prevState) => !prevState);
        }
      });
  };
  const onCategoryDeleteConfirmed = () => {
    setShowCategoriesDeleteConfirm(false);
    setDeleting(true);
    axios
      .post(
        EXHIBITOR_API + "delete-category",
        { category: currentCategory },
        getExhibitorHeader()
      )
      .then((res) => {
        setDeleting(false);
        const d = res.data;
        if (d.success) {
          setReloadProfile((prevState) => !prevState);
        }
      });
  };
  const onCompanyInfoEdit = () => {
    setShowEditCi(true);
  };

  const onContactInfoEdit = () => {
    setShowContactInfoEdit(true);
  };
  const onIndustrySectorEdit = () => {
    setShowEditIs(true);
  };
  const onCategoriesEdit = () => {
    setShowEditCats(true);
  };
  return (
    <div>
      <RdMyModal
        showModal={showEditCi}
        setShowModal={setShowEditCi}
        modalC={
          <CompanyInfoEdit
            title="Update Company Info"
            user={exhibitor}
            show={showEditCi}
            setShow={setShowEditCi}
            onUpdated={onUpdated}
          />
        }
      />
      <RdMyModal
        showModal={showContactInfoEdit}
        setShowModal={setShowContactInfoEdit}
        modalC={
          <ContactInfoEdit
            title="Update Contact Info"
            user={exhibitor}
            show={showContactInfoEdit}
            onUpdated={onUpdated}
            setShow={setShowContactInfoEdit}
          />
        }
      />
      <RdMyModal
        showModal={showEditAbout}
        setShowModal={setShowEditAbout}
        modalC={
          <EditAbout
            title="Update About us"
            user={exhibitor}
            show={showEditAbout}
            setShow={setShowEditAbout}
            onUpdated={onUpdated}
          />
        }
      />
      <RdMyModal
        showModal={showEditIs}
        setShowModal={setShowEditIs}
        modalC={
          <IndustrySectorEdit
            title="Update Industry Sector"
            user={exhibitor}
            show={showEditIs}
            setShow={setShowEditIs}
            onUpdated={onUpdated}
          />
        }
      />
      <RdMyModal
        showModal={showEditCats}
        setShowModal={setShowEditCats}
        modalC={
          <CategoriesEdit
            title="Update Product Categories"
            user={exhibitor}
            show={showEditCats}
            setShow={setShowEditCats}
            onUpdated={onUpdated}
          />
        }
      />
      <SimpleYesNoDialog
        show={showSectorDeleteConfirm}
        setShow={setShowSectorDeleteConfirm}
        onYes={onSectorDeleteConfirmed}
        title="Please Confirm!"
        message="Are you sure you want to delete this?"
      />
      <SimpleYesNoDialog
        show={showCategoriesDeleteConfirm}
        setShow={setShowCategoriesDeleteConfirm}
        onYes={onCategoryDeleteConfirmed}
        title="Please Confirm!"
        message="Are you sure you want to delete this?"
      />
      <CustomCircularP show={deleting || adding} />
      <div
        style={{
          padding: "8px 14px",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
      >
        <HeaderLabel
          showEdit={!isView}
          onEdit={onEditAboutCompany}
          label={exhibitor.about ? exhibitor.about.label : "About Us"}
        />
        <div style={{ fontSize: "14px", color: "#777" }}>
          {parse(exhibitor.about ? exhibitor.about.value : "")}
        </div>
      </div>
      <div className="profile-info-c">
        <HeaderLabel
          showEdit={!isView}
          onEdit={onCompanyInfoEdit}
          label="Company Information"
        />
        <div className="rd-items-three">
          <ProfileInfoCard infoCard={exhibitor.companyName} />
          <ProfileInfoCard infoCard={exhibitor.companyShortName} />
          <ProfileInfoCard infoCard={exhibitor.fascia} />
          <ProfileInfoCard infoCard={exhibitor.weAre} />
          <ProfileInfoCard infoCard={exhibitor.phone} />
          <ProfileInfoCard infoCard={exhibitor.companyEmail} />
          <ProfileInfoCard infoCard={exhibitor.fax} />
          <ProfileInfoCard infoCard={exhibitor.website} />
          <CountryStateCard infoCard={exhibitor.country} />
          <CountryStateCard infoCard={exhibitor.state} />
          <ProfileInfoCard infoCard={exhibitor.city} />
          <ProfileInfoCard infoCard={exhibitor.pin} />
          <ProfileInfoCard infoCard={exhibitor.address1} />
          <ProfileInfoCard infoCard={exhibitor.address2} />
          <ProfileInfoCard infoCard={exhibitor.address3} />
        </div>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <HeaderLabel
          showEdit={!isView}
          onEdit={onContactInfoEdit}
          label="Contact Information"
        />
        <div className="rd-items-three">
          <ProfileInfoCard infoCard={exhibitor.contactSalutation} />
          <ProfileInfoCard infoCard={exhibitor.contactFirstName} />
          <ProfileInfoCard infoCard={exhibitor.contactLastName} />
          <ProfileInfoCard infoCard={exhibitor.contactDesignation} />
          <ProfileInfoCard infoCard={exhibitor.mobile} />
          <ProfileInfoCard infoCard={exhibitor.contactEmail} />
        </div>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Box mb={2}>
          <HeaderLabel
            showEdit={!isView}
            label="Industry/Sector"
            onEdit={onIndustrySectorEdit}
          />
          <Box mt={2}>
            {exhibitor.industrySector.value.map((is, i) => (
              <Chip
                key={i}
                label={is}
                sx={{
                  mr: 1,
                  mb: 1,
                }}
                // onDelete={() => {}}
                // deleteIcon={<CloseIcon />}
              />
            ))}
          </Box>
        </Box>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Box mb={2}>
          <HeaderLabel
            showEdit={!isView}
            label="Product Categories"
            onEdit={onCategoriesEdit}
          />
          <Box sx={{ color: "#444", mt: 2 }}>
            {exhibitor.productCategories?.value?.map((category, i) => (
              <Chip
                key={i}
                label={category}
                sx={{
                  mr: 1,
                  mb: 1,
                }}
                // onDelete={() => {}}
                // deleteIcon={<CloseIcon />}
              />
            ))}
          </Box>
        </Box>
      </div>
      <div style={{ marginTop: "12px" }}>
        <Products
          id={id}
          exhibitor={exhibitor}
          setReloadProfile={setReloadProfile}
          isView={isView}
        />
      </div>
      <div style={{ marginTop: "12px" }}>
        <TeamMembers
          id={id}
          exhibitor={exhibitor}
          setReloadProfile={setReloadProfile}
          isView={isView}
        />
      </div>
      <div style={{ marginTop: "12px" }}>
        <UploadContent
          id={id}
          exhibitor={exhibitor}
          setReloadProfile={setReloadProfile}
          isView={isView}
        />
      </div>
    </div>
  );
};

export default About;
