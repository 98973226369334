import { useMutation } from "react-query";
import axios from "axios";
import { ADMIN_API } from "../../../../constants/constants";

function adminLogin(loginData) {
  return axios
    .post(`${ADMIN_API}/login`, loginData)
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
}

export function useAdminLogin() {
  const mutation = useMutation(adminLogin);

  return {
    ...mutation,
    login: mutation.mutate,
  };
}
