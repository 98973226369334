import React from "react";
import "./ExhibitorCard.css";
import { Button, IconButton } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StarIcon from "@mui/icons-material/Star";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ForumIcon from "@mui/icons-material/Forum";
import RoomIcon from "@mui/icons-material/Room";
import EastIcon from "@mui/icons-material/East";
import {
  DEFAULT_DP,
  DEFAULT_EX_ICON,
  INTERACT_FAVORITE,
  INTERACT_MEETING,
  INTERACT_MESSAGE,
} from "../constants/constants";
const ExhibitorCard = ({ item, onInteract, onSelectExhibitor }) => {
  const markFav = (event) => {
    event.stopPropagation();
    toast("Marked as Favorite!");
  };
  const onClickExhibitor = () => {
    onSelectExhibitor(item);
  };
  return (
    <div className="exhibitor-card-c">
      <img
        src={item.profile_image ? item.profile_image : DEFAULT_EX_ICON}
        className="ex-img-left"
      />
      <div>
        <div className="name-two-lines">{item?.companyName?.value}</div>
        <Button
          startIcon={<RoomIcon color="#444" />}
          sx={{ textTransform: "none", color: "#444" }}
          variant="text"
        >
          {item?.hallNumber?.value} | {item?.standNumber?.value}
        </Button>
        <div style={{ display: "flex", margin: "4px 0" }}>
          <div style={{ flex: 1, display: "flex", gap: "12px" }}>
            <IconButton
              sx={{ backgroundColor: "#dbedff" }}
              aria-label="message"
              size="small"
              onClick={() => onInteract(INTERACT_MESSAGE, item.username)}
            >
              <ForumIcon sx={{ color: "#00a2d9" }} fontSize="inherit" />
            </IconButton>
            <IconButton
              onClick={() => onInteract(INTERACT_MEETING, item)}
              sx={{ backgroundColor: "#ffead2" }}
              aria-label="message"
              size="small"
            >
              <CalendarMonthIcon sx={{ color: "#ff981f" }} fontSize="inherit" />
            </IconButton>
            <IconButton
              sx={{ backgroundColor: "#ffead2" }}
              onClick={() => onInteract(INTERACT_FAVORITE, item)}
              aria-label="message"
              size="small"
            >
              <StarIcon sx={{ color: "#fb4d24" }} fontSize="inherit" />
            </IconButton>
          </div>
          <IconButton
            onClick={onClickExhibitor}
            aria-label="arrow"
            size="small"
          >
            <EastIcon fontSize="inherit" sx={{ color: "#3cb4e0" }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default ExhibitorCard;
