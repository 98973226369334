import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Box,
  Alert,
  Typography,
} from "@mui/material";
import Logo from "../../../../assets/exp_planner_logo_h.png"; // Replace with the path to your logo
import { useAdminLogin } from "../hooks/useAdminLogin";
import { LoadingButton } from "@mui/lab";
import { saveAdmin } from "../../../../utility/utility";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login, isLoading, isError, error, data } = useAdminLogin();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login({ username: username, password: password });
  };
  useEffect(() => {
    if (data) {
      saveAdmin({ token: data.data.user.token });
      navigate("home");
    }
    if (isError) {
      toast.error("Some error occurred");
    }
  }, [data, isError, navigate]);
  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: (theme) => theme.palette.background.default,
      }}
      maxWidth="sm"
    >
      <Box component="div" sx={{ width: 192, marginBottom: 4 }}>
        <img src={Logo} alt="Company Logo" width="100%" />
      </Box>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        autoComplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: 400,
        }}
      >
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          size="small"
          margin="normal"
          value={username} // Bind value to state
          onChange={(e) => setUsername(e.target.value)} // Update state on change
        />
        <TextField
          label="Password"
          type="password"
          size="small"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password} // Bind value to state
          onChange={(e) => setPassword(e.target.value)} // Update state on change
        />
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          loading={isLoading}
        >
          Login
        </LoadingButton>
      </Box>
      <Box
        sx={{
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "100%",
          backgroundColor: (theme) => theme.palette.background.paper,
          p: 1,
          textAlign: "center",
        }}
      >
        <Typography variant="body2" color="textSecondary">
          © 2023 Expoplanner. All Rights Reserved.
        </Typography>
      </Box>
    </Container>
  );
}

export default Login;
