import React, { useState } from "react";
import VisitorCard from "../../../../widgets/VisitorCard";
import { Box, Button, Grid, Pagination, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ProfileDetails from "../../visitor/ProfileDetails";
import useMatchedVisitors from "./hooks/useMatchedVisitors";
import MuPb from "../../../../widgets/MuPb";
import NoDataFound from "../NoDataFound";
import {
  EXHIBITOR,
  IMTEX_EXHIBITOR,
  INTERACT_FAVORITE,
  INTERACT_MEETING,
  INTERACT_MESSAGE,
  VISITOR,
} from "../../../../constants/constants";
import { useNavigate } from "react-router-dom";
import useLoginProfile from "../hooks/useLoginProfile";
const Matchmaking = ({ currentView, setCurrentView, parentView }) => {
  const navigate = useNavigate();
  const [senderMessage, setSenderMessage] = useState();
  const [openMessage, setOpenMessage] = useState(false);
  const [recipientMessage, setRecipientMessage] = useState();

  const {
    data: profileData,
    isLoading: profileLoading,
    isError: isProfileError,
    error: profileError,
    refetchExhibitorProfile,
  } = useLoginProfile();

  const EXHIBITOR_VIEW = "exhibitor_view";
  const [currentUser, setCurrentUser] = useState(null);
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, error } = useMatchedVisitors(page);

  const onVisitorSelect = (cuser) => {
    setCurrentUser(cuser);
    console.log(cuser);
    setCurrentView(EXHIBITOR_VIEW);
  };
  const onInteract = (type, cuser) => {
    switch (type) {
      case INTERACT_MESSAGE:
        setSenderMessage(EXHIBITOR);
        setRecipientMessage({ username: cuser, type: VISITOR });
        setOpenMessage(true);
        break;
      case INTERACT_MEETING:
        navigate(
          `/meeting-scheduler?uid=${profileData.username}&type=${IMTEX_EXHIBITOR}&tid=${cuser.email}&tType=visitors`
        );

        break;
      case INTERACT_FAVORITE:
        console.log("fav");
        break;
      default:
        console.log("nothing selected");
    }
  };

  if (isLoading || profileLoading) {
    return <MuPb />;
  }
  const onPageChange = (e, page) => {
    setPage(page);
  };
  return (
    <div>
      {currentView === parentView && (
        <div style={{ color: "green", margin: "8px 8px 0 8px" }}>
          B2B Matching AI seamlessly connects exhibitors with relevant visitors,
          leveraging advanced algorithms and data analysis to optimize business
          interactions during events.
        </div>
      )}
      {currentView !== parentView && (
        <Button
          sx={{ marginTop: "10px", marginLeft: "8px", marginBlock: "10px" }}
          onClick={() => setCurrentView(parentView)}
          variant="outlined"
          startIcon={<ArrowBackIosNewIcon />}
          size="small"
        >
          Back
        </Button>
      )}

      {currentView === parentView ? (
        <Box>
          <Grid
            pl={2}
            pr={2}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h6">Matched Visitors</Typography>
            </Grid>
            <Grid item>
              <Pagination
                count={Math.ceil(data.totalItems / 30)}
                page={page}
                onChange={onPageChange}
                color="primary"
              />
            </Grid>
          </Grid>
          <div style={{ display: "inline-block", gap: "12px" }}>
            {data.data.map((exh, i) => (
              <VisitorCard
                key={i}
                item={exh}
                onInteract={onInteract}
                onVisitorSelect={onVisitorSelect}
              />
            ))}
          </div>
          {data.data.length === 0 && <NoDataFound />}
        </Box>
      ) : (
        <>
          {currentUser && <ProfileDetails isView={true} user={currentUser} />}
        </>
      )}
    </div>
  );
};

export default Matchmaking;
