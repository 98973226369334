import React from "react";
const TermsAndConditions = () => {
  const containerStyle = {
    maxWidth: "800px",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    margin: "16px auto",
  };

  return (
    <div style={containerStyle}>
      <h2>Terms and Conditions</h2>
      <p>
        Personal data provided by you is collected, processed and used by IMTMA
        only and, if applicable, shared with the exhibition service partners in
        accordance with relevant data protection regulations for the purpose of
        providing support and information for the customers and potential
        customers and for handling the services offered.
      </p>
      <p>
        I agree to register as a visitor to the event and would be interested to
        receive information about the event and other benefits offered by the
        organiser. By sending my registration, I give my consent to IMTMA and
        its entities to send me promotional and transactional information via
        digital and offline channels not limited to e-mails, SMS, WhatsApp,
        print newsletters, and invitation cards.
      </p>
      <center>
        <p>
          <strong>© Indian Machine Tool Manufacturers' Association</strong> All
          Rights Reserved
        </p>
      </center>
    </div>
  );
};
export default TermsAndConditions;
