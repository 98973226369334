import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import { useScrollTrigger } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_ENDPOINT } from "../../../constants/constants";
import { getAdminHeader } from "../../../utility/utility";
import { toast } from "react-toastify";
import { StatusCodes } from "http-status-codes";

export default function StandZoneForm({
  show,
  setShow,
  color,
  strokeWidth,
  hallId,
  shape,
  onUpdated,
}) {
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    setName("");
  }, [show]);
  const addStand = async (eventId, hallId, name, shape) => {
    setSaving(true);
    try {
      const response = await axios.post(
        API_ENDPOINT + "floorplan/add-stand",
        {
          eventId: 1,
          hallId,
          color: color,
          strokeWidth: strokeWidth,
          name: name,
          shape: shape,
        },
        getAdminHeader()
      );
      setSaving(false);

      if (response.status === 201) {
        toast.success("Stand added successfully:");
        onUpdated();
        setShow(false);
      } else {
        console.error("Failed to add Stand:", response.data.message);
      }
    } catch (error) {
      setSaving(false);
      if (error.response) {
        // Check for the conflict status code
        if (error.response.status === StatusCodes.CONFLICT) {
          console.error(
            "Conflict error while adding stand:",
            error.response.data.message
          );
          toast.error("Stand already exists: " + error.response.data.message);
        } else {
          console.error(
            "Error while adding stand:",
            error.response.data.message
          );
          toast.error(error.response.data.message);
        }
      } else if (error.request) {
        console.error("Error while adding stand: No response from server");
        toast.error("No response from server");
      } else {
        console.error("Error while adding stand:", error.message);
        toast.error(error.message);
      }
    }
  };
  const onSave = () => {
    if (name === "") {
      toast.error("Please enter stand name");
    } else {
      addStand(1, hallId, name, shape);
    }
  };
  return (
    <Box p={2} pl={4} pr={4} component="form" noValidate autoComplete="off">
      <TextField
        required
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        id="stand-zone-name"
        label="Stand/Zone Name"
        variant="outlined"
      />
      <FormGroup>
        <FormControlLabel control={<Checkbox />} label="Conference Room" />
        <FormControlLabel control={<Checkbox />} label="Check-in zone" />
        <FormControlLabel control={<Checkbox />} label="Lounge Meeting Room" />
        <FormControlLabel control={<Checkbox />} label="Buyer Lounge" />
      </FormGroup>
      <FormControlLabel control={<Switch />} label="Hide on Front-end" />
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Button
          onClick={() => setShow(false)}
          variant="outlined"
          color="inherit"
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={onSave}
          loading={saving}
          variant="contained"
          color="primary"
        >
          Save
        </LoadingButton>
      </Box>
    </Box>
  );
}
