import { useQuery } from "react-query";
import axios from "axios";
import { INTERACTION_API } from "../../../../../constants/constants";
import { getCommonHeaderVE } from "../../../../../utility/utility";

const fetchProfile = (username, type) => {
  return axios
    .get(
      `${INTERACTION_API}get-profile?un=${username}&type=${type}`,
      getCommonHeaderVE()
    )
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useProfile = (username, type) => {
  return useQuery(
    ["interactions/get-profile", username, type],
    () => fetchProfile(username, type),
    {
      staleTime: 5 * 60 * 1000, // Data will be considered stale after 5 minutes
      retry: 2,
    }
  );
};

export default useProfile;
