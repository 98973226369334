import React, { useEffect, useState } from "react";
import "../dashboard.css";
import Footer from "../../footer/footer";

import {
  DEFAULT_DP,
  EXHIBITOR,
  INTERACT_FAVORITE,
  INTERACT_MEETING,
  INTERACT_MESSAGE,
} from "../../../constants/constants";
import {
  EXHIBITOR_MENU_LIST,
  deleteExhibitor,
  isExhibitorLoggedIn,
} from "../../../utility/utility";
import Dashboard from "./Dashboard";
import { useNavigate, useParams } from "react-router-dom";
import Settings from "./Settings";
import Services from "./oes/Services";
import Onsite from "./Onsite";
import Reports from "./Reports";
import ProfileDetails from "./profile/ProfileDetails";
import Explore from "./explore/Explore";
import NewDashHeader from "../header/NewDashHeader";
import SideMenuList from "./profile/SideMenuList";
import MuPb from "../../../widgets/MuPb";
import { Alert } from "@mui/material";
import ExhibitionCatalogue from "./oes/ExhibitionCatalogue";
import useLoginProfile from "./hooks/useLoginProfile";
import MySchedule from "./MySchedule";
import Favorites from "./Favorites";
import ComingSoon from "./ComingSoon";
import MobileSideMenuList from "./profile/MobileSideMenuList";
import GeneralView from "../../floorplan/view/GeneralView";
import HelpdeskPopup from "./HelpdeskPopup";
import MessagePopup from "./MessagePopup";
import useNotifications from "./hooks/useNotifications";
import AllMeetings from "./meeting/AllMeetings";
const ExhibitorDashboard = () => {
  const navigate = useNavigate();
  const { page } = useParams();
  const [openHelpDesk, setOpenHelpDesk] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  if (page === "exhibitor-services") {
    navigate("/exhibitor-services/0");
  }
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useLoginProfile();
  const {
    data: notificationsData,
    isLoading: notificationsLoading,
    isError: isNotificationError,
    refetch: refetchNotifications,
  } = useNotifications(data?.username);

  useEffect(() => {
    refetchExhibitorProfile();
  }, []);
  function checkLoginStatus() {
    if (!isExhibitorLoggedIn()) {
      navigate("/login");
    }
  }
  const onLogout = () => {
    deleteExhibitor();
    checkLoginStatus();
  };
  useEffect(() => {
    if (isError) {
      if (error.message === "No token provided.") {
        navigate("/login");
      }
    }
  }, [isError, error]);
  const onMenuClick = () => {
    setShowSideMenu((prev) => !prev);
  };
  if (isLoading) {
    return <MuPb />;
  }
  if (!data) {
    return <Alert severity="error">No data loaded</Alert>;
  }
  const onHelpDeskPopup = () => {
    setOpenHelpDesk(true);
  };

  return (
    <div className="rd-expo-dash-c">
      <HelpdeskPopup open={openHelpDesk} setOpen={setOpenHelpDesk} />

      <NewDashHeader
        profileLink={`/exhibitor/profile`}
        onMenuClick={onMenuClick}
        onLogout={onLogout}
        notifications={notificationsData}
        onHelpDeskPopup={onHelpDeskPopup}
        profileImg={
          data.profile_image === undefined || data.profile_image === ""
            ? DEFAULT_DP
            : data.profile_image
        }
      />
      <div className="rd-dashboard-c">
        <div style={{ display: "flex" }}>
          <SideMenuList
            onLogout={onLogout}
            category={EXHIBITOR}
            menuList={EXHIBITOR_MENU_LIST}
            page={page}
          />
          <MobileSideMenuList
            showSideMenu={showSideMenu}
            setShowSideMenu={setShowSideMenu}
            onLogout={onLogout}
            category={EXHIBITOR}
            menuList={EXHIBITOR_MENU_LIST}
            page={page}
          />

          <div style={{ flex: 1, width: "100%" }}>
            {page === "dashboard" || page === undefined ? (
              <Dashboard user={data} />
            ) : page === "profile" ? (
              <ProfileDetails
                user={data}
                reloadProfile={refetchExhibitorProfile}
              />
            ) : page === "explore" ||
              page === "exhibitors" ||
              page === "products" ||
              page === "ai-matching" ||
              page === "news" ||
              page === "visitors" ? (
              <Explore page={page} />
            ) : page === "floor-plan" ? (
              <GeneralView />
            ) : page === "exhibition-catalogue" ? (
              <ExhibitionCatalogue />
            ) : page === "all-meetings" ? (
              <AllMeetings user={data} />
            ) : page === "exhibitor-services" ? (
              <div></div>
            ) : page === "services" ? (
              <Services />
            ) : page === "my-schedule" ? (
              <MySchedule />
            ) : page === "favorites" ? (
              <Favorites />
            ) : page === "settings" ? (
              <Settings />
            ) : page === "onsite" ? (
              <Onsite />
            ) : page === "reports" ? (
              <Reports />
            ) : (
              <></>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ExhibitorDashboard;
