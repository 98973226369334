import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  CardContent,
  Card,
  Container,
  Slide,
  Slider,
  List,
  ListItem,
  Button,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import SimpleCalendar from "./SimpleCalendar";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useNavigate } from "react-router-dom";
import BackBtn from "../../dashboard/admin/widgets/BackBtn";
import { Group, Image, Layer, Line, Rect, Stage } from "react-konva";
import useFloorPlan from "../hooks/useFloorPlan";
import MuPb from "../../../widgets/MuPb";
import { toast } from "react-toastify";
import FpCropEdit, { MAP_IMAGE } from "./FpCropEditor";
import RdMyModal from "../../../widgets/myModal/RdMyModal";
import { CONTAINER_HEIGHT, CONTAINER_WIDTH } from "./AddHall";
import {
  getAdminHeader,
  getHoverColor,
  hexToRGBA,
} from "../../../utility/utility";
import { API_ENDPOINT, TRANSPARENCY } from "../../../constants/constants";
import CustomCircularP from "../../../widgets/CustomCircularP";
import SimpleYesNoDialog from "../../../widgets/SimpleYesNoDialog";
import axios from "axios";

function GeneralFloorPlan() {
  const { data, isLoading, isError, error, refetchFloorPlan } = useFloorPlan(1);
  const innerHeight = useRef(
    window.innerHeight + window.screen.availHeight - window.outerHeight
  );
  const navigate = useNavigate();
  const [showImageCrop, setShowImageCrop] = useState(false);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [image, setImage] = useState(null);
  const [gMenuEl, setGmenuEl] = useState(null);
  const [hMenuEl, setHmenuEl] = useState(null);
  const [stageScale, setStageScale] = useState(1);
  const [stagePos, setStagePos] = useState({ x: 0, y: 0 });
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const [newHalls, setNewHalls] = useState();
  const [showHallDelete, setShowHallDelete] = useState(false);
  const [targetHall, setTargetHall] = useState();
  const [hallDeleting, setHallDeleting] = useState(false);

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.01;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const pointer = stage.getPointerPosition();

    // Calculate the new scale
    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // Update the stage scale
    setStageScale(newScale);

    // Calculate the new position
    const newPos = {
      x: pointer.x - ((pointer.x - stage.x()) * newScale) / oldScale,
      y: pointer.y - ((pointer.y - stage.y()) * newScale) / oldScale,
    };

    setStagePos(newPos); // Update the stage position
    setStageX(newPos.x); // Assuming these are used for something else
    setStageY(newPos.y);
  };

  useEffect(() => {
    refetchFloorPlan();
  }, []);

  useEffect(() => {
    if (data) {
      const img = new window.Image();
      img.src = data.image;

      img.onload = () => {
        setImage({
          img: img,
          width: img.width,
          height: img.height,
        });

        if (containerRef.current) {
          const { width, height } =
            containerRef.current.getBoundingClientRect();

          const originalWidth = CONTAINER_HEIGHT * (img.width / img.height);
          const originalHeight = CONTAINER_HEIGHT;
          const newHeight = width * (originalHeight / originalWidth);
          setDimensions({
            width,
            height: newHeight,
          });
          const halls = [...data.halls];

          halls.forEach((hall) => {
            const scaleX = width / originalWidth;
            const scaleY = newHeight / originalHeight;

            if (hall.shape.type === "Polygon") {
              // Scale polygon points
              hall.shape.shape = hall.shape.shape.map((point, index) => {
                return index % 2 === 0 ? point * scaleX : point * scaleY;
              });
            } else if (hall.shape.type === "Rectangle") {
              // Scale rectangle properties
              hall.shape.shape.x *= scaleX;
              hall.shape.shape.y *= scaleY;
              hall.shape.shape.width *= scaleX;
              hall.shape.shape.height *= scaleY;
            }
          });
          setNewHalls(halls);
        }
      };
    }
  }, [data]);
  useEffect(() => {
    if (isError) {
      if (error.message === "Not Found") {
        //toast.info("Nothing found");
      } else if (error.message === "Internal Server Error") {
        //toast.error("Some internal error occured");
      } else {
        //toast.error("Some error occured");
      }
    }
  }, [isError]);
  if (isLoading) {
    return <MuPb />;
  }

  const hanldeHallMenuClick = (e, hall) => {
    setTargetHall(hall);
    setHmenuEl(e.target);
  };
  const handleGeneralPlanEdit = (e) => {
    setGmenuEl(e.target);
  };
  const handleGmenuClick = (event, action) => {
    setGmenuEl(null); // Close the menu

    switch (action) {
      case "Edit Image":
        break;

      case "Replace Image":
        setShowImageCrop(true);
        break;

      case "Delete Image":
        break;

      default:
        console.warn("Unknown action:", action);
        break;
    }
  };
  const addNewHall = () => {
    navigate("/fp-add-hall");
  };

  const handleHmenuClick = (e, m) => {
    setHmenuEl(null); // Close the menu
    switch (m) {
      case "Edit Hall":
        navigate(`/edit-hall/${targetHall._id}`);
        break;

      case "Delete Hall":
        setShowHallDelete(true);
        break;

      default:
        console.warn("Unknown action:", m);
        break;
    }
  };
  const handleHallDelete = async (id) => {
    setHallDeleting(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}floorplan/delete-hall?id=${id}`,
        getAdminHeader()
      );
      setHallDeleting(false);
      refetchFloorPlan();
      toast.success(response.data.message);
    } catch (error) {
      setHallDeleting(false);
      console.error("Error during deletion:", error.response);
      toast.error(error.response.data.message);
    }
  };
  const onYesHallDelete = () => {
    setShowHallDelete(false);
    handleHallDelete(targetHall._id);
  };
  return (
    <Box sx={{ maxWidth: "1280px", ml: "auto", mr: "auto", mt: 2 }}>
      <RdMyModal
        showModal={showImageCrop}
        setShowModal={setShowImageCrop}
        modalC={
          <FpCropEdit
            reloadFn={refetchFloorPlan}
            show={showImageCrop}
            setShow={setShowImageCrop}
            upload_type={MAP_IMAGE}
          />
        }
      />
      <CustomCircularP show={hallDeleting} />
      <SimpleYesNoDialog
        title="Please Confirm!"
        message={`Are you sure you want to delete ${targetHall?.name}?. Please beware that all data releated to this hall will be deleted.`}
        onYes={onYesHallDelete}
        show={showHallDelete}
        setShow={setShowHallDelete}
      />
      <BackBtn />
      <Menu
        anchorEl={gMenuEl}
        open={Boolean(gMenuEl)}
        onClose={() => setGmenuEl(null)}
      >
        <MenuItem
          disabled={!image}
          onClick={(e) => handleGmenuClick(e, "Edit Image")}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit Image
        </MenuItem>
        <MenuItem
          disabled={!image}
          onClick={(e) => handleGmenuClick(e, "Replace Image")}
        >
          <ListItemIcon>
            <FindReplaceIcon fontSize="small" />
          </ListItemIcon>
          Replace Image
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={!image}
          sx={{ color: "red" }}
          onClick={(e) => handleGmenuClick(e, "Delete Image")}
        >
          <ListItemIcon>
            <DeleteOutlineIcon sx={{ color: "red" }} fontSize="small" />
          </ListItemIcon>
          Delete Image
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={hMenuEl}
        open={Boolean(hMenuEl)}
        onClose={() => setHmenuEl(null)}
      >
        <MenuItem onClick={(e) => handleHmenuClick(e, "Edit Hall")}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit Hall
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ color: "red" }}
          onClick={(e) => handleHmenuClick(e, "Delete Hall")}
        >
          <ListItemIcon>
            <DeleteOutlineIcon sx={{ color: "red" }} fontSize="small" />
          </ListItemIcon>
          Delete Hall
        </MenuItem>
      </Menu>

      <Grid marginTop={1} container spacing={2}>
        <Grid item xs={7}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // Adjust as per your needs
                }}
              >
                <Typography variant="h6">General Plan</Typography>

                <IconButton
                  onClick={handleGeneralPlanEdit}
                  color="primary"
                  aria-label="edit"
                >
                  <EditIcon />
                </IconButton>
              </Box>
              <Card
                ref={containerRef}
                sx={{
                  mt: 2,
                  height: image
                    ? dimensions.height
                    : `calc(${innerHeight.current}px - 192px)`,
                  overflow: "hidden",
                  position: "relative",
                }}
                variant="outlined"
              >
                {image ? (
                  <Stage
                    width={dimensions.width}
                    height={dimensions.height}
                    scaleX={stageScale}
                    scaleY={stageScale}
                    x={stagePos.x}
                    y={stagePos.y}
                    draggable={true}
                    onWheel={handleWheel}
                  >
                    <Layer>
                      <Image
                        image={image.img}
                        width={dimensions.width}
                        height={dimensions.height}
                      />
                      {newHalls
                        ?.filter((hall) => hall.shape.type === "Polygon")
                        .map((hall) => (
                          <Group>
                            <Line
                              points={hall.shape.shape}
                              fill={hexToRGBA(hall.color, TRANSPARENCY)}
                              stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                              strokeWidth={hall.strokeWidth}
                              closed={true}
                              onClick={() => {
                                navigate(`/hall-plan/${hall._id}`);
                              }}
                              onMouseEnter={(e) => {
                                const shape = e.target;
                                shape.fill(getHoverColor(hall.color)); // Change fill to a darker blue on hover
                                shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                              }}
                              onMouseLeave={(e) => {
                                const shape = e.target;
                                shape.fill(hexToRGBA(hall.color, TRANSPARENCY)); // Reset to the original color on mouse leave
                                shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                              }}
                            />
                          </Group>
                        ))}
                      {newHalls
                        ?.filter((hall) => hall.shape.type === "Rectangle")
                        .map((hall) => (
                          <Rect
                            x={hall.shape.shape.x}
                            y={hall.shape.shape.y}
                            width={hall.shape.shape.width}
                            height={hall.shape.shape.height}
                            fill={hexToRGBA(hall.color, TRANSPARENCY)}
                            onClick={(e) => {
                              navigate(`/hall-plan/${hall._id}`);
                            }}
                            onMouseEnter={(e) => {
                              const shape = e.target;
                              shape.fill(getHoverColor(hall.color)); // Change fill to a darker blue on hover
                              shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                            }}
                            onMouseLeave={(e) => {
                              const shape = e.target;
                              shape.fill(hexToRGBA(hall.color, TRANSPARENCY)); // Reset to the original color on mouse leave
                              shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                            }}
                          />
                        ))}
                    </Layer>
                  </Stage>
                ) : (
                  <Box sx={{ p: 3, width: "100%", height: "100%" }}>
                    Loading ...
                  </Box>
                )}
              </Card>

              {!data?.image && (
                <Box
                  onClick={() => setShowImageCrop(true)}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    border: "2px dashed #054059",
                    borderRadius: 1,
                    padding: 4,
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="h6" color="#054059">
                    Click to upload Map
                  </Typography>
                  <Typography variant="body2">
                    Only images (JPG/JPEG, PNG) less than 2mb are allowed for
                    upload.
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={5}>
          <Card>
            <CardContent>
              <Box>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // Adjust as per your needs
                  }}
                >
                  <Typography variant="h6">Hall List</Typography>

                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    size="small"
                    variant="contained"
                    onClick={addNewHall}
                  >
                    Add New Hall
                  </Button>
                </Box>

                <Typography variant="caption" gutterBottom>
                  EXHIBITION HALLS
                </Typography>

                <List>
                  {data && data.halls.length > 0 ? (
                    data.halls.map((hall, index) => (
                      <Card
                        key={index}
                        variant="outlined"
                        sx={{ padding: 1, mt: 1 }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            // Adjust as per your needs
                          }}
                        >
                          <Typography variant="body1">{hall.name}</Typography>
                          <IconButton
                            onClick={(e) => hanldeHallMenuClick(e, hall)}
                            size="small"
                            aria-label="edit"
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Box>
                      </Card>
                    ))
                  ) : (
                    <Typography
                      variant="h6"
                      sx={{ mt: 3, textAlign: "center" }}
                    >
                      No halls available.
                    </Typography>
                  )}
                </List>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GeneralFloorPlan;
