import React, { useEffect } from "react";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import Login from "./login/login";
import { Route, Routes, useNavigate } from "react-router-dom";
import { isAdminLoggedIn } from "../../../utility/utility";
import "./Admin.css";
import Dashboard from "./home/home";
import AddEvent from "./home/AddEvent";
import { QueryClient, QueryClientProvider } from "react-query";
import GeneralFloorPlan from "../../floorplan/editor/GeneralFloorPlan";
import Services from "../exhibitor/oes/Services";
import Exhibitors from "./home/exhibitors/Exhibitors";
import ExhibitorDetail from "../exhibitor/ExhibitorDetail";
import AllocateHall from "./home/allocatehall/allocateHall";
const theme = createTheme();
const queryClient = new QueryClient();

function Admin() {
  const navigate = useNavigate();
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {!isAdminLoggedIn() && <Login />}

        <Routes>
          <Route path="" element={<Dashboard />} />
          <Route path="floor-plan" element={<GeneralFloorPlan />} />
          <Route path="exhibitors" element={<Exhibitors />} />
          <Route path="allocate-hall" element={<AllocateHall />} />
          <Route path="exhibitor/:id" element={<ExhibitorDetail />} />
          <Route path="home/*" element={<Dashboard />} />
        </Routes>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default Admin;
