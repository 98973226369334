import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  EXHIBITOR,
  EXHIBITOR_API,
  IMTEX_EXHIBITOR,
  INTERACT_FAVORITE,
  INTERACT_MEETING,
  INTERACT_MESSAGE,
  VISITOR,
} from "../../../../constants/constants";
import { getExhibitorHeader, getHeader } from "../../../../utility/utility";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import VisitorCard from "../../../../widgets/VisitorCard";
import { toast } from "react-toastify";
import { Box, Button, Grid, Pagination, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ProfileDetails from "../../visitor/ProfileDetails";
import useVisitors from "./hooks/useVisitors";
import MuPb from "../../../../widgets/MuPb";
import MessagePopup from "../MessagePopup";
import useLoginProfile from "../hooks/useLoginProfile";
import { useNavigate } from "react-router-dom";
const Visitors = ({
  searchFilter,
  currentView,
  setCurrentView,
  parentView,
}) => {
  const navigate = useNavigate();
  const [senderMessage, setSenderMessage] = useState();
  const [openMessage, setOpenMessage] = useState(false);
  const [recipientMessage, setRecipientMessage] = useState();
  const EXHIBITOR_VIEW = "exhibitor_view";
  const [currentUser, setCurrentUser] = useState(null);
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, error } = useVisitors(page, searchFilter);
  const {
    data: profileData,
    isLoading: profileLoading,
    isError: isProfileError,
    error: profileError,
    refetchExhibitorProfile,
  } = useLoginProfile();
  const onVisitorSelect = (cuser) => {
    setCurrentUser(cuser);
    setCurrentView(EXHIBITOR_VIEW);
  };
  if (isLoading || profileLoading) {
    return <MuPb />;
  }
  const onPageChange = (e, page) => {
    setPage(page);
  };
  const onInteract = (type, cuser) => {
    switch (type) {
      case INTERACT_MESSAGE:
        setSenderMessage(EXHIBITOR);
        setRecipientMessage({ username: cuser, type: VISITOR });
        setOpenMessage(true);
        break;
      case INTERACT_MEETING:
        navigate(
          `/meeting-scheduler?uid=${profileData.username}&type=${IMTEX_EXHIBITOR}&tid=${cuser.email}&tType=visitors`
        );

        break;
      case INTERACT_FAVORITE:
        console.log("fav");
        break;
      default:
        console.log("nothing selected");
    }
  };
  return (
    <div>
      <MessagePopup
        sender={senderMessage}
        recipient={recipientMessage}
        open={openMessage}
        setOpen={setOpenMessage}
      />
      {currentView !== parentView && (
        <Button
          sx={{ marginTop: "10px", marginLeft: "8px", marginBottom: "12px" }}
          onClick={() => setCurrentView(parentView)}
          variant="outlined"
          startIcon={<ArrowBackIosNewIcon />}
          size="small"
        >
          Back
        </Button>
      )}

      {currentView === parentView ? (
        <Box>
          <Grid
            pl={2}
            pr={2}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h6">Visitors </Typography>
            </Grid>
            <Grid item>
              <Pagination
                count={Math.ceil(data.totalItems / 30)}
                page={page}
                onChange={onPageChange}
                color="primary"
              />
            </Grid>
          </Grid>
          <div style={{ display: "inline-block", gap: "12px" }}>
            {data.data.map((exh, i) => (
              <VisitorCard
                key={i}
                item={exh}
                onInteract={onInteract}
                onVisitorSelect={onVisitorSelect}
              />
            ))}
            {data?.data?.length === 0 && (
              <div className="items-not-found">No Data Yet</div>
            )}
          </div>
        </Box>
      ) : (
        <>
          {currentUser && <ProfileDetails isView={true} user={currentUser} />}
        </>
      )}
    </div>
  );
};

export default Visitors;
