import React, { useEffect, useState } from "react";
import RdLoadingButton from "../../../../widgets/RdLoadingButton";
import SearchBar from "../../../../widgets/searchBar";
import FilterListIcon from "@mui/icons-material/FilterList";
import Exhibitors from "./Exhibitors";
import Products from "./Products";
import Matchmaking from "./Matchmaking";
import Visitors from "./Visitors";
import News from "./News";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import { Button } from "@mui/material";
import MaterialMenu from "../../../../widgets/MaterialMenu";
import TuneIcon from "@mui/icons-material/Tune";
import { useNavigate } from "react-router-dom";
import ThreePIcon from "@mui/icons-material/ThreeP";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RedeemIcon from "@mui/icons-material/Redeem";
import GroupIcon from "@mui/icons-material/Group";
import NewspaperIcon from "@mui/icons-material/Newspaper";
const Explore = ({ page }) => {
  const navigate = useNavigate();
  const PARENT_VIEW = "parent_view";
  const [currentView, setCurrentView] = useState(PARENT_VIEW);
  const itemsA = ["Exhibitors", "Products", "B2B AI Matchmaking"];
  const [activeTab, setActiveTab] = useState(itemsA[0]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const iconsA = [
    <ThreePIcon />,
    <ShoppingCartIcon />,
    <RedeemIcon />,
    <GroupIcon />,
    <NewspaperIcon />,
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onTabSelect = (item) => {
    setActiveTab(item);
  };
  const onFilter = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick = (filter) => {
    setSelectedFilter(filter);
    handleClose();
  };
  const onExploreSearch = (e) => {
    setSearchFilter(e.target.value);
  };
  const filtertems = ["Countries", "Product Categories", "Hallwise"];

  const handleTabClick = (item) => {
    navigate(`/visitor/explore/${getPageByTab(item)}`);
  };

  useEffect(() => {
    if (page === "explore") {
      setActiveTab(itemsA[0]);
    } else if (page === "exhibitors") {
      setActiveTab(itemsA[0]);
    } else if (page === "products") {
      setActiveTab(itemsA[1]);
    } else if (page === "ai-matching") {
      setActiveTab(itemsA[2]);
    } else if (page === "visitors") {
      setActiveTab(itemsA[3]);
    } else {
      setActiveTab(itemsA[4]);
    }
  }, [page]);
  function getPageByTab(tab) {
    switch (tab) {
      case itemsA[0]:
        return "exhibitors";
      case itemsA[1]:
        return "products";
      case itemsA[2]:
        return "ai-matching";
      case itemsA[3]:
        return "visitors";
      case itemsA[4]:
        return "news"; // Replace with an appropriate default value
      default:
        return "news";
    }
  }

  function getTabByPage(pg) {
    if (pg === "explore") {
      return itemsA[0];
    } else if (page === "exhibitors") {
      return itemsA[0];
    } else if (page === "products") {
      return itemsA[1];
    } else if (page === "ai-matching") {
      return itemsA[2];
    } else if (page === "visitors") {
      return itemsA[3];
    } else {
      return itemsA[4];
    }
  }
  return (
    <div className="rd-dash-content">
      <div
        style={{ display: currentView === PARENT_VIEW ? "flex" : "none" }}
        className="explore-tabs-holder"
      >
        {itemsA.map((item, i) => (
          <Button
            className={
              item === getTabByPage(page)
                ? `explore-select-btn active`
                : `explore-select-btn`
            }
            startIcon={iconsA[i]}
            onClick={() => handleTabClick(item)}
            variant="outlined"
          >
            {item}
          </Button>
        ))}
        <div style={{ flex: 1 }}></div>
        <SearchBar
          onSearch={onExploreSearch}
          label="Search by Product or Company"
          size="7px"
          boxAlign="inline-flex"
          mystyle={{
            display: "inline-flex",
            backgroundColor: "white",
            width: "276px",
          }}
          cstyle={{ flex: 1, marginLeft: "6px" }}
        />

        <Button
          className="explore-select-btn"
          sx={{ borderRadius: "24px" }}
          onClick={onFilter}
          variant="outlined"
          startIcon={<TuneIcon />}
        >
          {selectedFilter === "" ? "Filter" : selectedFilter}
        </Button>
        <MaterialMenu
          onItemSelect={handleMenuItemClick}
          menuList={filtertems}
          selectedItem={selectedFilter}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      </div>

      <div>
        {activeTab === itemsA[0] ? (
          <Exhibitors
            searchFilter={searchFilter}
            currentView={currentView}
            setCurrentView={setCurrentView}
            parentView={PARENT_VIEW}
          />
        ) : activeTab === itemsA[1] ? (
          <Products
            searchFilter={searchFilter}
            currentView={currentView}
            setCurrentView={setCurrentView}
            parentView={PARENT_VIEW}
          />
        ) : activeTab === itemsA[2] ? (
          <Matchmaking
            currentView={currentView}
            setCurrentView={setCurrentView}
            parentView={PARENT_VIEW}
          />
        ) : activeTab === itemsA[3] ? (
          <Visitors
            currentView={currentView}
            setCurrentView={setCurrentView}
            parentView={PARENT_VIEW}
          />
        ) : (
          <News />
        )}
      </div>
    </div>
  );
};

export default Explore;
