import { useQuery } from "react-query";
import axios from "axios";
import { EXHIBITOR_API } from "../../../../../constants/constants";
import { getExhibitorHeader } from "../../../../../utility/utility";
const fetchItems = (page = 1, id) => {
  return axios
    .get(
      `${EXHIBITOR_API}get-team-members-by-id?page=${page}&id=${id}`,
      getExhibitorHeader()
    )
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useMembers = (page, id) => {
  const queryInfo = useQuery(
    ["get-team-members", page, id],
    () => fetchItems(page, id),
    {
      staleTime: 0,
      retry: 3,
    }
  );

  return {
    ...queryInfo,
    refetchMembers: queryInfo.refetch, // Extract refetch function from queryInfo
  };
};

export default useMembers;
