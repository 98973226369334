import React, { useState } from "react";
import HeaderLabel from "../../../../widgets/HeaderLabel";
import AddBtn from "../../../../widgets/actionBtn/addBtn";
import RdLoadingButton from "../../../../widgets/RdLoadingButton";
import { Box, CircularProgress, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import CategoriesEdit from "./edit/CategoriesEdit";
import SimpleYesNoDialog from "../../../../widgets/SimpleYesNoDialog";
import { toast } from "react-toastify";
import { getHeader } from "../../../../utility/utility";
import { VISITOR_API } from "../../../../constants/constants";
import axios from "axios";

const Categories = ({ profile, isView = false, setProfile }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [curCat, setCurCat] = useState("");
  const onCategoryDelete = (si) => {
    setCurCat(si);
    setShowDelete(true);
  };
  const cxItems = {
    display: "inline-flex",
    fontWeight: "500",
    fontSize: "14px",
    margin: "4px",
    padding: !isView ? "4px 2px 4px 8px" : "10px 12px",
  };
  const onEdit = () => {
    setShowEdit(true);
  };
  const onYesDelete = () => {
    setShowDelete(false);
    const toDelete = curCat;
    const curA = [...profile.selectedCategories];
    const newArray = curA.filter((item) => item !== toDelete);
    setDeleting(true);
    axios
      .post(
        VISITOR_API + "update-visitor",
        {
          selectedCategories: newArray,
        },
        getHeader()
      )
      .then((res) => {
        setDeleting(false);
        toast.success(res.data.message);
        setProfile((prevState) => ({
          ...prevState,
          selectedCategories: newArray,
        }));
      })
      .catch((error) => {
        setDeleting(false);
        if (error.response) {
          // The request was made and the server responded with a status code outside the range of 2xx
          console.error("Error Data:", error.response.data);
          console.error("Error Status:", error.response.status);
          console.error("Error Headers:", error.response.headers);
          toast.error(
            `Error: ${error.response.data.message || "Something went wrong"}`
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Error Request:", error.request);
          toast.error("No response from server. Please try again later.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
          toast.error("An error occurred. Please try again.");
        }
      });
  };
  return (
    <div className="profile-info-c">
      {deleting && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // center horizontally
            alignItems: "center", // center vertically
            height: "100vh", // take full viewport height
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <div style={{ display: "flex" }}>
        <RdMyModal
          showModal={showEdit}
          setShowModal={setShowEdit}
          modalC={
            <CategoriesEdit
              profile={profile}
              setProfile={setProfile}
              setShow={setShowEdit}
            />
          }
        />
        <SimpleYesNoDialog
          title="Please Confirm!"
          message="Are you sure you want to delete this?"
          onYes={onYesDelete}
          show={showDelete}
          setShow={setShowDelete}
        />
        <HeaderLabel
          onEdit={null}
          showEdit={false}
          label={"Product Categories"}
        />
        <div style={{ flex: 1 }}></div>
        {!isView && <AddBtn onClick={onEdit} label={"Add Categories"} />}
      </div>

      <div>
        {profile.selectedCategories.map((is, i) => (
          <RdLoadingButton
            textColor="#444"
            key={i}
            bgColor="rgb(234 234 234)"
            bgHoverColor="rgb(213 217 211)"
            label={is}
            iconPosition="right"
            icon={
              !isView && (
                <IconButton
                  onClick={() => onCategoryDelete(is)}
                  sx={{ padding: "2px" }}
                  size="small"
                >
                  {" "}
                  <CancelIcon sx={{ color: "#6d7c90" }} />
                </IconButton>
              )
            }
            myStyle={cxItems}
          />
        ))}
      </div>
    </div>
  );
};

export default Categories;
