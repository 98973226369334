import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

function RdDesignationsSelect({
  state,
  handleStateChange,
  label = "Designation*",
}) {
  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={state.designation}
        name="designation"
        label={label}
        onChange={handleInputChange}
      >
        {positions.map((position) => (
          <MenuItem key={position} value={position}>
            {position}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
const positions = [
  "Advisor",
  "Assistant Director",
  "Assistant Engineer",
  "Assistant Executive",
  "CEO",
  "Chairman",
  "Chief Engineer",
  "Chief General Manager",
  "Chief Technical Officer",
  "Consultant",
  "Contractor",
  "Country Head",
  "Deputy Director",
  "Director",
  "Dy. General Manager",
  "Dy. Manager",
  "Engineer",
  "Executive",
  "Executive Director",
  "General Manager",
  "Head - Production",
  "Jr. Engineer",
  "Jr. Manager",
  "Jr. Technician",
  "Machine Operator",
  "Managing Director",
  "Manager",
  "Partner",
  "Plant In-charge",
  "President",
  "Programmer",
  "Proprietor",
  "Sr. Director",
  "Sr. General Manager",
  "Sr. Vice President",
  "Trainee",
  "Vice President",
];

export default RdDesignationsSelect;
