import { Alert, Box } from "@mui/material";
import React, { useEffect } from "react";
import useExhibitor from "./explore/hooks/useExhibitor";
import ProfileDetails from "./profile/ProfileDetails";
import MuPb from "../../../widgets/MuPb";

const ExhibitorViewById = ({ id }) => {
  const {
    data,
    isLoading,
    isError,
    error,
    refetch: refetchExhibitor,
  } = useExhibitor(id);

  useEffect(() => {
    refetchExhibitor();
  }, []);
  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">Some eror occured</Alert>;
  }
  return (
    <Box>
      {data && (
        <ProfileDetails
          user={data.data}
          reloadProfile={refetchExhibitor}
          isView={true}
          id={data.data.username}
        />
      )}
    </Box>
  );
};

export default ExhibitorViewById;
