import {
  Box,
  Button,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ExpoTheme } from "../../../theme/theme";
import RegHeader from "../../../visitorregt/components/headers/regHeader";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { API_ENDPOINT, EXHIBITOR_API } from "../../../constants/constants";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import MuPb from "../../../widgets/MuPb";
import BackBtn from "../../dashboard/admin/widgets/BackBtn";
import Footer from "../../footer/footer";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import AttachFile from "@mui/icons-material/AttachFile";
import { MuiTelInput } from "mui-tel-input";
const ServicePerson = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [exhibitor, setExhibitor] = useState();
  const [selectedImage, setSelectedImage] = useState(null);

  const [phoneValue, setPhoneValue] = React.useState("");

  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    serviceBadgeType: "SERVICE BADGE",
  });

  useEffect(() => {
    document.title = "SERVICE BADGE";
  }, []);

  useEffect(() => {
    loadUser();
  }, []);
  async function loadUser() {
    setLoading(true);
    try {
      const response = await axios.get(
        EXHIBITOR_API + `get-service-badge-person?id=${id}`
      );
      if (response.status === 200) {
        setExhibitor(response.data);
      } else if (response.status === 204) {
        // Handle successful response with no content
        toast.info("No Exhibitor Found with that ID.");
      }
    } catch (error) {
      // Check if the error has a response object
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // If the response has a data object with a message, display it
        toast.error(error.response.data.message);
      } else {
        // Otherwise, fall back to a generic error message
        toast.error("An error occurred while registering. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  }
  const onClickSubmit = async () => {
    if (!formData.name.trim()) {
      toast.error("Please enter a name.");
      return;
    }

    const phoneWithoutSpaces = phoneValue.replace(/\s+/g, "");
    if (phoneWithoutSpaces.length < 10) {
      toast.error(
        "Please enter a valid mobile number with at least 10 digits."
      );
      return;
    }

    if (!selectedImage) {
      toast.error("Please select an image.");
      return;
    }

    setUploading(true);

    // First, upload the image to S3
    const imagePayload = new FormData();
    imagePayload.append("file", selectedImage);
    imagePayload.append("upload_type", "service_person_photo"); // Assuming you have a field to specify the upload type

    try {
      const imageResponse = await axios.post(
        EXHIBITOR_API + "upload-temp-image",
        imagePayload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (imageResponse.status !== 200) {
        toast.error("Image upload failed.");
        return;
      }

      const imageUrl = imageResponse.data.message; // Assuming the URL of the image is returned in this field

      // Now, submit the rest of the form data
      const formPayload = new FormData();
      formPayload.append("name", formData.name);
      formPayload.append("exhibitorId", exhibitor.exhibitorId);
      formPayload.append("companyName", exhibitor.companyName);
      formPayload.append("hallNumber", exhibitor.hallNumber);
      formPayload.append("standNumber", exhibitor.standNumber);
      formPayload.append("mobileNumber", phoneWithoutSpaces);
      formPayload.append("serviceBadgeType", formData.serviceBadgeType);
      formPayload.append("photoUrl", imageUrl); // Send the URL of the uploaded image

      const response = await axios.post(
        EXHIBITOR_API + "submit-service-person",
        formPayload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Submission Successful");
        navigate(`/service-badge-welcome/${response.data.data._id}`); // Redirect to a success page or another route as needed
      }
    } catch (error) {
      // Check if the error has a response object
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // If the response has a data object with a message, display it
        toast.error(error.response.data.message);
      } else {
        // Otherwise, fall back to a generic error message
        toast.error("An error occurred while registering. Please try again.");
      }
    } finally {
      setUploading(false);
    }
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handlePhoneChange = (newValue) => {
    setPhoneValue(newValue);
    //setFormData({ ...formData, ["mobileNumber"]: newValue });
  };

  if (loading) {
    return <MuPb />;
  }

  return (
    <ThemeProvider theme={ExpoTheme}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <RegHeader />
        {/* <Box mt={2} ml={2}>
          <BackBtn />
        </Box> */}
        <Box mt={2} width={"100%"} maxWidth={420} ml={"auto"} mr={"auto"}>
          <Box sx={{ p: 2, textAlign: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
              Exhibitor Details
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              Exhibitor ID:{" "}
              <span style={{ fontWeight: "normal" }}>
                {exhibitor?.exhibitorId}
              </span>
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              Company Name:{" "}
              <span style={{ fontWeight: "normal" }}>
                {exhibitor?.companyName}
              </span>
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              Hall Number:{" "}
              <span style={{ fontWeight: "normal" }}>
                {exhibitor?.hallNumber}
              </span>
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              Stand Number:{" "}
              <span style={{ fontWeight: "normal" }}>
                {exhibitor?.standNumber}
              </span>
            </Typography>
            <TextField
              fullWidth
              label="Enter Name"
              margin="normal"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />

            <MuiTelInput
              sx={{ mt: 2 }}
              fullWidth
              defaultCountry="IN"
              value={phoneValue}
              onChange={handlePhoneChange}
            />

            <TextField
              sx={{ mt: 3 }}
              fullWidth
              label="Service Badge Type"
              margin="normal"
              name="name"
              disabled={true}
              value={formData.serviceBadgeType}
            />

            <Box
              sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}
            >
              <Button
                variant="contained"
                component="label"
                startIcon={<AttachFile />}
              >
                Select from Files
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>
              <Button
                variant="contained"
                component="label"
                sx={{
                  backgroundColor: "#972a4c", // Normal state background color
                  "&:hover": {
                    backgroundColor: "#7a1e3a", // Darker shade for hover state
                  },
                }}
                startIcon={<PhotoCamera />}
              >
                Launch Camera
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  capture="environment"
                  onChange={handleImageChange}
                />
              </Button>
            </Box>
            {selectedImage && (
              <img
                src={URL.createObjectURL(selectedImage)}
                alt="Preview"
                style={{
                  width: "120px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  marginTop: "10px",
                }}
              />
            )}

            <LoadingButton
              fullWidth
              loading={uploading}
              sx={{
                mt: 3,
                backgroundColor: "#0074d9", // Normal state background color
                "&:hover": {
                  backgroundColor: "#005ca9", // Darker shade for hover state
                },
              }}
              onClick={onClickSubmit}
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 4,
            flexGrow: 1, // This will make the box grow to fill available space
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ServicePerson;
