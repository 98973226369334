import { Alert, Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import useExhibitorByHallStand from "../../dashboard/admin/hooks/useExhibitorByHallStand";
import MuPb from "../../../widgets/MuPb";
import ProfileDetails from "../../dashboard/exhibitor/profile/ProfileDetails";

const ExhibitorDetails = ({ hall, stand }) => {
  const {
    data,
    isLoading,
    isError,
    error,
    refetch: refetchExhibitor,
  } = useExhibitorByHallStand(hall, stand);
  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">Some eror occured</Alert>;
  }
  return (
    <Box>
      {data && (
        <ProfileDetails
          user={data}
          reloadProfile={refetchExhibitor}
          isView={true}
          id={data.username}
        />
      )}
    </Box>
  );
};

export default ExhibitorDetails;
