import React from 'react';
import NewsCard from '../../../../widgets/NewsCard';

const News = () => {
    const newsArticlesA = [
        {
            title: "This is new article header title",
            category: "Machine Tools",
            image: "https://media.istockphoto.com/id/1369150014/vector/breaking-news-with-world-map-background-vector.jpg?s=612x612&w=0&k=20&c=9pR2-nDBhb7cOvvZU_VdgkMmPJXrBQ4rB1AkTXxRIKM=",
            createdOn: new Date().toISOString(),
            creator: "Admin"
        },
        {
            title: "New Innovations in CNC Machining",
            category: "Tech",
            image: "https://images.pexels.com/photos/3871/city-night-explosion-firework.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            createdOn: new Date().toISOString(),
            creator: "Tech Reporter"
        },
        {
            title: "Environmental Impact of Industrial Manufacturing",
            category: "Environment",
            image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkvsHFOIV93zcacTGma-n4UI07jeNZWI_UdyZGlrRUWFa2mGxzzS29thTsrO6zTHWQU7s&usqp=CAU",
            createdOn: new Date().toISOString(),
            creator: "Environment Columnist"
        }
    ];
    
    return (
        <div>
            <br />
            <div className='rd-items-gallery'>
                {
                    newsArticlesA.map((article, i)=><NewsCard item={article} key={i}/>)
                }
            </div>
        </div>
    );
};

export default News;