import React from "react";
import vBg from "../../../assets/visitor_bg.png"; // Path to your image
import { Box, Button, Card } from "@mui/material";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { useReactToPrint } from "react-to-print";
import { BadgeToPrint } from "./BadgeToPrint";

const PrintBadge = ({ user }) => {
  const printRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const style = {
    badgeContainer: {
      position: "relative",
      width: "9.5cm",
      height: "14cm",
      boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
      //   backgroundImage: `url(${vBg})`,
      //   backgroundSize: "cover",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      color: "black",
      padding: "1cm", // Adjust padding as needed
      boxSizing: "border-box",
    },
    detailsContainer: {
      textAlign: "center",
      marginTop: "4cm",
    },
    qrCode: {
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
      marginTop: "0.5cm", // Adjust the margin as needed
      width: "3.5cm", // Adjust size as needed
      height: "3.5cm", // Adjust size as needed
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        justifyContent: "center",
        pt: 2,
      }}
    >
      <Button
        onClick={handlePrint}
        sx={{ position: "absolute", top: 0, right: 0, textTransform: "none" }}
        startIcon={<LocalPrintshopIcon />}
      >
        Print
      </Button>
      <BadgeToPrint isPreview={true} ref={printRef} user={user} />
    </Box>
  );
};

export default PrintBadge;
