import React from 'react';
import EditBtn from './editBtn';
import DeleteBtn from './deleteBtn';

const ProductItemAction = ({
    onEdit, onDelete
}) => {
    return (
        <div className='product-item-action'>
            <EditBtn onEdit={onEdit}/>
            <div style={{flex: 1}}></div>
            <DeleteBtn onDelete={onDelete}/>
      </div>
    );
};

export default ProductItemAction;