import React, { useState } from "react";
import {
  Paper,
  Typography,
  Box,
  Container,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Alert,
  InputLabel,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import MuPb from "../../../../widgets/MuPb";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import { useParams } from "react-router-dom";
import Supplier2 from "./reuse/Supplier2";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import axios from "axios";
import { API_ENDPOINT, EXHIBITOR_API } from "../../../../constants/constants";
import { getExhibitorHeader } from "../../../../utility/utility";
import ComingSoon from "../ComingSoon";
export const SpaceInfo = ({ data }) => {
  return (
    <Box mt={2}>
      <Box mb={2}>
        <Typography>
          <strong>Space Type:</strong> {data?.spaceType?.value}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography>
          <strong>Area:</strong> {data?.standSize?.value}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          <strong>Company Name:</strong> {data?.companyName?.value}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography>
          <strong>Hall No:</strong> {data?.hallNumber?.value}
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography>
          <strong>Stall No:</strong> {data?.standNumber?.value}
        </Typography>
      </Box>
    </Box>
  );
};
export const spaceTemplate = (data) => {
  return `<div class="box"> <p><strong>Space Type:</strong> ${data.spaceType.value}</p></div><div class="box"> <p><strong>Area:</strong> ${data?.standSize?.value}</p></div><div class="box"> <p><strong>Company Name:</strong> ${data?.companyName?.value}</p></div><div class="box"> <p><strong>Hall No:</strong> ${data?.hallNumber?.value}</p></div><div class="box"> <p><strong>Stall No:</strong> ${data?.standNumber?.value}</p></div>`;
};
const SetupSchedule = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();
  const { id } = useParams();
  const [supplier, setSupplier] = useState();
  const [agreed, setAgreed] = useState(false);
  const [siteAgency, setSiteAgency] = useState();
  const [agencies, setAgencies] = useState([
    "P S Bedi & Company Pvt. Ltd",
    "R.E. Rogers India Pvt. Ltd",
    "Schenker India Pvt. Ltd",
    "Self",
  ]);
  const [submitting, setSubmitting] = useState(false);

  function reformatDate(inputDate) {
    // Create a Date object from the input date string
    const date = new Date(inputDate);

    // Define month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract day, month, and year components from the Date object
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Create the formatted date string
    const formattedDate = `${day} ${month}, ${year}`;

    return formattedDate;
  }

  if (isLoading) {
    return <MuPb />;
  }
  const onSubmit = async () => {
    if (!siteAgency) {
      toast.info("Please select Site handling agency");
      return;
    }
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Tempplate</title> <style>.box{margin-bottom: 16px;font-size: 18px; /* 2rem equivalent */}strong{font-weight: bold;}</style> </head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p><strong>To:</strong>Company: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div>${spaceTemplate(
      data
    )}<div class="box"> <p> <strong>Exhibits Arrival Date:</strong> ${
      data?.exhibitArrival?.value ? reformatDate(data.exhibitArrival.value) : ""
    }</p></div><div class="box"> <p> <strong>Stall Material Date:</strong> ${
      data?.materialArrival?.value
        ? reformatDate(data.materialArrival.value)
        : ""
    }</p></div><div class="box"> <p><strong>Dismantling Date:</strong> ${
      data?.dismantling?.value ? reformatDate(data.dismantling.value) : ""
    }</p></div><div class="box"> <p><strong>Site Handling Agency:</strong> ${siteAgency}</p></div><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;
    const toSubmitData = {
      template,
      name: "Setup and Dismantling Schedule",
      emails: [
        data.companyEmail.value,
        supplier.email_id,
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "Setup and Dismantling Schedule Services",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  return (
    <Container>
      <Paper elevation={3} style={{ padding: "2rem" }}>
        <Box mb={2}>
          <Typography variant="h5">Setup and Dismantling Schedule</Typography>
        </Box>

        {/* <Alert severity="warning">
          <Typography variant="subtitle1">
            Page not yet submitted, the deadline is 23 Dec, 2024.
          </Typography>
        </Alert> */}

        <Typography mt={2} gutterBottom>
          Please read carefully and note the below schedule and space
          information. Kindly select your Site Handling Agency and submit form.
        </Typography>

        <Typography fontWeight={"bold"} fontSize={"17px"} gutterBottom>
          IMTEX Forming 2024, Tooltech 2024, Digital Manufacturing 2024 and
          WeldExpo 2024.
        </Typography>

        <SpaceInfo data={data} />
        <Box mb={2}>
          <Typography>
            <strong>Exhibits Arrival Date:</strong>{" "}
            {data?.exhibitArrival?.value
              ? reformatDate(data.exhibitArrival.value)
              : ""}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            <strong>Stall Material Date:</strong>{" "}
            {data?.materialArrival?.value
              ? reformatDate(data.materialArrival.value)
              : ""}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography>
            <strong>Dismantling Date:</strong>{" "}
            {data?.dismantling?.value
              ? reformatDate(data.dismantling.value)
              : ""}
          </Typography>
        </Box>
        <Box mb={2}>
          <FormControl size="small" fullWidth variant="outlined">
            <InputLabel>Confirm your site Handling Agency*</InputLabel>
            <Select
              label="Confirm your site Handling Agency*"
              value={siteAgency}
              onChange={(e) => setSiteAgency(e.target.value)}
            >
              {agencies.map((sp, i) => (
                <MenuItem key={i} value={sp}>
                  {sp}
                </MenuItem>
              ))}
              {/* Add other suppliers as required */}
            </Select>
          </FormControl>
        </Box>

        <Supplier2
          user={data}
          id={parseInt(id) + 1}
          supplier={supplier}
          setSupplier={setSupplier}
        />

        <Box my={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreed}
                onChange={(e) => setAgreed((prev) => !prev)}
                name="agree"
                color="primary"
              />
            }
            label="Please confirm that you have read the dates and agree *"
          />
        </Box>

        <Box mt={3} display="flex" justifyContent="center">
          <Box>
            <LoadingButton
              loading={submitting}
              onClick={onSubmit}
              disabled={!agreed || !supplier}
              variant="contained"
              color="primary"
              style={{ marginRight: "10px" }}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default SetupSchedule;
