import React, { useState } from "react";
import ExhibitorCard from "../../../../widgets/ExhibitorCard";
import { Box, Button, Grid, Pagination, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ProfileDetails from "../../exhibitor/profile/ProfileDetails";
import useMatchedExhibitors from "../hooks/useMatchedExhibitors";
import MuPb from "../../../../widgets/MuPb";
import {
  EXHIBITOR,
  IMTEX_EXHIBITOR,
  INTERACT_FAVORITE,
  INTERACT_MEETING,
  INTERACT_MESSAGE,
  VISITOR,
} from "../../../../constants/constants";
import { useNavigate } from "react-router-dom";
import useLoginProfile from "../hooks/useLoginProfile";
const Matchmaking = ({ currentView, setCurrentView, parentView }) => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const {
    data: profileData,
    isLoading: profileLoading,
    isError: isProfileError,
    error: profileError,
    refetchExhibitorProfile,
  } = useLoginProfile();
  const { data, isLoading, isError, error } = useMatchedExhibitors(page);
  const [openMessage, setOpenMessage] = useState(false);
  const [senderMessage, setSenderMessage] = useState();
  const [recipientMessage, setRecipientMessage] = useState();
  const EXHIBITOR_VIEW = "exhibitor_view";
  const [currentUser, setCurrentUser] = useState(null);

  const onExhibitorSelect = (cuser) => {
    setCurrentUser(cuser);
    setCurrentView(EXHIBITOR_VIEW);
  };
  if (isLoading) {
    return <MuPb />;
  }
  const onPageChange = (e, page) => {
    setPage(page);
  };
  const onInteract = (type, cuser) => {
    switch (type) {
      case INTERACT_MESSAGE:
        setSenderMessage(VISITOR);
        setRecipientMessage({ username: cuser, type: EXHIBITOR });
        setOpenMessage(true);
        break;
      case INTERACT_MEETING:
        navigate(
          `/meeting-scheduler?uid=${profileData.message.email}&type=visitors&tid=${cuser.username}&tType=${IMTEX_EXHIBITOR}`
        );
        break;
      case INTERACT_FAVORITE:
        console.log("fav");
        break;
      default:
        console.log("nothing selected");
    }
  };
  return (
    <div>
      {currentView !== parentView && (
        <Button
          sx={{ marginTop: "10px", marginLeft: "8px" }}
          onClick={() => setCurrentView(parentView)}
          variant="outlined"
          startIcon={<ArrowBackIosNewIcon />}
          size="small"
        >
          Back
        </Button>
      )}
      {currentView === parentView ? (
        <div>
          <div style={{ color: "green", margin: "8px" }}>
            B2B Matching AI streamlines connections between visitors and
            exhibitors by employing sophisticated algorithms to analyze user
            profiles, preferences, and business requirements, ensuring effective
            and personalized interactions at events and exhibitions.
          </div>
          <Box>
            <Grid
              pl={2}
              pr={2}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h6">Matched Exhibitors</Typography>
              </Grid>
              <Grid item>
                <Pagination
                  count={Math.ceil(data.totalItems / 30)}
                  page={page}
                  onChange={onPageChange}
                  color="primary"
                />
              </Grid>
            </Grid>
            {data.data.map((exh, i) => (
              <ExhibitorCard
                key={i}
                item={exh}
                onInteract={onInteract}
                onSelectExhibitor={onExhibitorSelect}
              />
            ))}
          </Box>

          {data.data.length === 0 && (
            <div className="items-not-found">{"No Match Found!"}</div>
          )}
        </div>
      ) : (
        <>
          {currentUser && (
            <ProfileDetails
              id={currentUser.username}
              isView={true}
              user={currentUser}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Matchmaking;
