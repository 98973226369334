import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Divider,
  Container,
  Paper,
  Box,
  Alert,
  InputLabel,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import DeleteIcon from "@mui/icons-material/Delete";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import MuPb from "../../../../widgets/MuPb";
import { LoadingButton } from "@mui/lab";
import Supplier2 from "./reuse/Supplier2";
import { EXHIBITOR_API } from "../../../../constants/constants";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import axios from "axios";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
import { SubmittedStatus, oesMenus } from "./Services";
import { useParams } from "react-router-dom";
function InvitationInaguaration() {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();
  const { id } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [employees, setEmployees] = useState([{ name: "", designation: "" }]);
  const areAllFieldsNonEmpty = () => {
    for (let employee of employees) {
      if (employee.name.trim() === "" || employee.designation.trim() === "") {
        return false;
      }
    }
    return true;
  };
  const employeeRows = employees
    .map(
      (employee, index) =>
        `<tr key=${index}>
      <td>${employee.name}</td>
      <td>${employee.designation}</td>
    </tr>`
    )
    .join("");
  if (isLoading) {
    return <MuPb />;
  }
  const handleInputChange = (index, field, value) => {
    const updatedEmployees = [...employees];
    updatedEmployees[index][field] = value;
    setEmployees(updatedEmployees);
  };

  const addRow = () => {
    setEmployees([...employees, { name: "", designation: "" }]);
  };

  const removeRow = (index) => {
    const updatedEmployees = [...employees];
    updatedEmployees.splice(index, 1);
    setEmployees(updatedEmployees);
  };

  const onSubmit = async () => {
    if (!areAllFieldsNonEmpty()) {
      toast.error("Please fill all the fields");
      return;
    }
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div>${spaceTemplate(
      data
    )}<table> <tr><th>Name</th><th>Designation</th></tr>${employeeRows}</table> <br/> <img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "4.2 Invitation for Inauguration",
      emails: [
        data.companyEmail.value,
        supplier.email_id,
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "4.2 Invitation for Inauguration",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };

  return (
    <Container>
      <Paper elevation={3} style={{ padding: "2rem" }}>
        <Typography variant="h5" gutterBottom>
          4.2 Invitation for Inauguration
        </Typography>

        {/* <Box mb={4}>
          <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 23 Dec, 2024.
            </Typography>
          </Alert>
        </Box> */}

        <Typography variant="body1" gutterBottom>
          <p>
            Fax/Mail to reach IMTMA before 15 Dec 2023. Please collect
            invitation cards from respective Hall Directors booth located in
            Halls from 12 Jan 2024 onwards.
          </p>
          <p>
            The following executives from our organization would be attending
            the inauguration of IMTEX Forming 2024, Tooltech 2024, Digital
            Manufacturing 2024 and WeldExpo 2024. Please issue invitation cards
            for inauguration.
          </p>
        </Typography>

        <SpaceInfo data={data} />

        <Box
          mt={6}
          mb={3}
          p={2}
          border="1px solid grey"
          borderRadius="8px"
          position="relative"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Designation</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((employee, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={employee.name}
                      onChange={(e) =>
                        handleInputChange(idx, "name", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={employee.designation}
                      onChange={(e) =>
                        handleInputChange(idx, "designation", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => removeRow(idx)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            variant="contained"
            color="primary"
            onClick={addRow}
            style={{ marginTop: "10px" }}
          >
            Add Row
          </Button>
        </Box>
        {/* Supplier */}
        <Supplier2
          user={data}
          id={5}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            loading={submitting}
            disabled={!supplier}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Paper>
    </Container>
  );
}

export default InvitationInaguaration;
