import { useQuery } from "react-query";
import axios from "axios";
import { VISITOR_API } from "../../../../constants/constants";
const fetchCountries = () => {
  return axios
    .get(`${VISITOR_API}get-all-countries`)
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useCountries = () => {
  return useQuery(["get-countries"], () => fetchCountries(), {
    staleTime: 5 * 60 * 1000, // Data will be considered stale after 5 minutes
    retry: 3, // If the query fails, it will retry up to 3 times
  });
};

export default useCountries;
