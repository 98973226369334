import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  Card,
} from "@mui/material";
import BackBtn from "../../dashboard/admin/widgets/BackBtn";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CircleIcon from "@mui/icons-material/Circle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { LoadingButton } from "@mui/lab";
import {
  Circle,
  Group,
  Image,
  Layer,
  Line,
  Rect,
  Stage,
  Transformer,
} from "react-konva";
import { SketchPicker } from "react-color";
import Draggable from "react-draggable";
import CloseButton from "../../../widgets/actionBtn/CloseButton";
import ConfirmationModal from "./ConfirmationModal";
import { toast } from "react-toastify";
import UploadHall from "./UploadHall";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useHallPlan from "../hooks/useHallPlan";
import { StatusCodes } from "http-status-codes";
import { API_ENDPOINT, TRANSPARENCY } from "../../../constants/constants";
import { getAdminHeader, hexToRGBA } from "../../../utility/utility";
import axios from "axios";
import RdMyModal from "../../../widgets/myModal/RdMyModal";
import StandZoneForm from "./StandZoneForm";
import Konva from "konva";
export const CONTAINER_WIDTH = 1280;
export const CONTAINER_HEIGHT = 560;
export const CONTAINER_AR = 1280 / 600;
export const getRelativePointerPosition = (node) => {
  const transform = node.getAbsoluteTransform().copy();
  transform.invert();
  const pos = node.getStage().getPointerPosition();
  return transform.point(pos);
};
const AddStand = () => {
  const { id } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const editId = queryParams.get("editId");
  const navigate = useNavigate();
  const { data, isLoading, isError, error, refetchHallPlans } = useHallPlan(id);
  const RECTANGLE = "Rectangle";
  const POLYGON = "Polygon";
  const ADD_HALL = "add_hall";
  const SAVE_HALL = "save_hall";
  const [currentView, setCurrentView] = useState(ADD_HALL);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationV, setConfirmationV] = useState("");
  const [showStand, setShowStand] = useState(false);
  const canvasRef = useRef();
  const innerHeight = useRef(
    window.innerHeight + window.screen.availHeight - window.outerHeight
  );
  const [image, setImage] = useState(null);

  const [stageScale, setStageScale] = useState(1);
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const [points, setPoints] = useState([]);
  const [dots, setDots] = useState([]);
  const [rectangle, setRectangle] = useState(null);

  const [currentHall, setCurrentHall] = useState();

  const [polygonClosed, setPolygonClosed] = useState(false);
  const [selectedMode, setSelectedMode] = useState("");
  const [drawing, setDrawing] = useState(false);
  const [resizing, setResizing] = useState(false);
  const transformerRef = React.useRef(null);
  const [stagePos, setStagePos] = useState({ x: 0, y: 0 });
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [strokeWidth, setStrokeWidth] = useState(0);
  const [strokeColor, setStrokeColor] = useState("#1876d1");
  const stageRef = useRef(null);

  const increaseStrokeWidth = () => {
    setStrokeWidth((prevCount) => prevCount + 1);
  };

  const decreaseStrokeWidth = () => {
    if (strokeWidth > 0) {
      setStrokeWidth((prevCount) => prevCount - 1);
    }
  };
  useEffect(() => {
    refetchHallPlans();
  }, []);

  useEffect(() => {
    if (data) {
      const img = new window.Image();
      img.src = data.hall.image;

      img.onload = () => {
        setImage({
          img: img,
          width: img.width,
          height: img.height,
        });
      };
    }
  }, [data]);

  useEffect(() => {
    if (editId && data?.stands) {
      // Find the hall that matches the `hallId`
      const hallToEdit = data.stands.find((hall) => hall._id === editId);
      if (hallToEdit) {
        // Set the application state to edit mode with the details of `hallToEdit`
        // For example, if you have a state to hold the current hall in edit mode:
        setCurrentHall(hallToEdit);

        // Depending on your application's logic, set other relevant states
        // For example, if you're editing the shape or properties of the hall:
        if (hallToEdit.shape.type === POLYGON) {
          const hallPoints = hallToEdit.shape.shape; // Assuming this is an array of points
          setPoints(hallPoints);

          const formattedDots = [];
          for (let i = 0; i < hallPoints.length; i += 2) {
            formattedDots.push({ x: hallPoints[i], y: hallPoints[i + 1] });
          }
          setStrokeWidth(hallToEdit.strokeWidth);
          setStrokeColor(hallToEdit.color);
          setDots(formattedDots);
          setPolygonClosed(true);
          setSelectedMode(POLYGON);
        } else if (hallToEdit.shape.type === RECTANGLE) {
          setRectangle({
            x: hallToEdit.shape.shape.x,
            y: hallToEdit.shape.shape.y,
            width: hallToEdit.shape.shape.width,
            height: hallToEdit.shape.shape.height,
          });
          setStrokeWidth(hallToEdit.strokeWidth);
          setStrokeColor(hallToEdit.color);
          setSelectedMode(RECTANGLE);
        }
        // ... other properties as needed
      }
    }
  }, [editId, data?.stands]);

  const handleStageClick = (e) => {
    if (polygonClosed || selectedMode !== POLYGON) return;
    if (strokeWidth < 1) {
      toast.info("Please add stroke width");
      return;
    }
    const stage = e.target.getStage();
    const mousePos = getRelativePointerPosition(stage);

    // Account for the zoom level and offsets
    const newPoints = [...points, mousePos.x, mousePos.y];
    setPoints(newPoints);
    setDots([...dots, { x: mousePos.x, y: mousePos.y }]);

    // Check if user clicked near the starting point to close the polygon
    if (newPoints.length >= 6) {
      const dx = newPoints[0] - mousePos.x;
      const dy = newPoints[1] - mousePos.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance < 10) {
        // Set the last point to be equal to the first point for perfect closure
        const adjustedPoints = [...newPoints];
        adjustedPoints[adjustedPoints.length - 2] = adjustedPoints[0];
        adjustedPoints[adjustedPoints.length - 1] = adjustedPoints[1];

        setPoints(adjustedPoints);
        setDots([...dots, { x: adjustedPoints[0], y: adjustedPoints[1] }]);

        setPolygonClosed(true);
      }
    }
  };
  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.01;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const pointer = stage.getPointerPosition();

    // Calculate the new scale
    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // Update the stage scale
    setStageScale(newScale);

    // Calculate the new position
    const newPos = {
      x: pointer.x - ((pointer.x - stage.x()) * newScale) / oldScale,
      y: pointer.y - ((pointer.y - stage.y()) * newScale) / oldScale,
    };

    setStagePos(newPos); // Update the stage position
    setStageX(newPos.x); // Assuming these are used for something else
    setStageY(newPos.y);
  };

  const startDrawing = (e) => {
    if (currentHall) {
      const targetRect = e.target;
      const hallShape = currentHall.shape.shape;
      if (
        targetRect instanceof Konva.Rect &&
        targetRect.x() === hallShape.x &&
        targetRect.y() === hallShape.y &&
        targetRect.width() === hallShape.width &&
        targetRect.height() === hallShape.height
      ) {
        transformerRef.current.nodes([e.target]);
        transformerRef.current.getLayer().batchDraw();
        setResizing(true);
      } else {
        console.log("Target is not the current hall's rectangle");
      }
    }
    if (rectangle || selectedMode !== RECTANGLE) return;
    setDrawing(true);

    const pos = getRelativePointerPosition(stageRef.current);

    console.log(pos, "drawing");

    setRectangle({
      x: pos.x,
      y: pos.y,
      width: 0,
      height: 0,
    });
  };
  const updateDrawing = (e) => {
    if (!drawing || !rectangle) return;
    const pos = getRelativePointerPosition(stageRef.current);

    const newRect = {
      ...rectangle,
      width: pos.x - rectangle.x,
      height: pos.y - rectangle.y,
    };

    setRectangle(newRect);
  };
  const endDrawing = () => {
    setDrawing(false);
  };
  const handleDragMove = (e) => {
    if (resizing) {
      const node = e.target;
      const scaleX = node.scaleX();
      const scaleY = node.scaleY();

      // Update the actual width and height based on the scale factors
      setRectangle({
        x: node.x(),
        y: node.y(),
        width: Math.max(50, node.width() * scaleX),
        height: Math.max(50, node.height() * scaleY),
      });

      // Reset scale factors
      node.scaleX(1);
      node.scaleY(1);
    }
  };

  const renderedDots = dots.map((dot, index) => (
    <Circle
      key={index}
      x={dot.x}
      y={dot.y}
      radius={5}
      fill="red"
      draggable
      onDragMove={(e) => handleDragMovePolygon(index, e)}
    />
  ));
  const handleDragMovePolygon = (index, event) => {
    const newDots = dots.slice(); // Create a copy of the dots array
    newDots[index] = {
      x: event.target.x(),
      y: event.target.y(),
    };

    // Update the points array for the polygon
    const newPoints = newDots.reduce(
      (acc, dot) => acc.concat([dot.x, dot.y]),
      []
    );
    setPoints(newPoints);
    setDots(newDots);
  };
  const handleModeChange = (event) => {
    let cm = event.target.value;
    if (selectedMode !== "") {
      setOpenConfirmation(true);
      setConfirmationV(cm);
    } else {
      setSelectedMode(cm);
    }
  };

  const chooseColor = () => {
    setShowColorPicker(true);
  };
  const onColorChange = (color, event) => {
    setStrokeColor(color.hex);
    console.log(color);
  };
  const onConfirmSwitchTool = () => {
    setOpenConfirmation(false);
    setSelectedMode(confirmationV);
    setPoints([]);
    setDots([]);
    setRectangle(null);
    setPolygonClosed(false);
  };
  const onSave = async () => {
    if (
      selectedMode === "" ||
      (!(points.length >= 6 && polygonClosed) && !rectangle)
    ) {
      toast.error(`Please draw the shape first ${points.length >= 6}}`);
    } else {
      if (editId && currentHall) {
        const transformedRect = transformerRef.current.nodes()[0];
        const newRect = {
          x: transformedRect.x(),
          y: transformedRect.y(),
          width: transformedRect.width() * transformedRect.scaleX(),
          height: transformedRect.height() * transformedRect.scaleY(),
        };

        transformedRect.scaleX(1);
        transformedRect.scaleY(1);
        const shape = {
          type: selectedMode,
          shape: selectedMode === RECTANGLE ? newRect : points,
        };
        const dataToEdit = {
          color: strokeColor,
          strokeWidth: strokeWidth,
          shape: shape,
        };
        const postBody = {
          id: currentHall._id,
          toEdit: dataToEdit,
        };
        try {
          const { data } = await axios.post(
            `${API_ENDPOINT}floorplan/update-stand`,
            postBody,
            getAdminHeader()
          );
          toast.success(data.message);
          refetchHallPlans();
        } catch (error) {
          toast.error(`Error: ${error.message}`);
        }
      } else {
        setShowStand(true);
      }
    }
  };
  const onBack = () => {
    navigate(-1);
  };
  const onAdded = () => {
    //refetchHallPlans();
    document.location.reload();
  };
  return (
    <Box maxWidth={1280} m="8px auto">
      <RdMyModal
        showModal={showStand}
        setShowModal={setShowStand}
        modalC={
          <StandZoneForm
            color={strokeColor}
            strokeWidth={strokeWidth}
            hallId={id}
            shape={{
              type: selectedMode,
              shape: selectedMode === "Rectangle" ? rectangle : points,
            }}
            onUpdated={onAdded}
            show={showStand}
            setShow={setShowStand}
          />
        }
      />
      {currentView === ADD_HALL ? (
        <Box>
          <ConfirmationModal
            open={openConfirmation}
            handleClose={() => setOpenConfirmation(false)}
            onCancel={() => setOpenConfirmation(false)}
            onConfirm={onConfirmSwitchTool}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" flex={1} alignItems="center" gap={2}>
              <BackBtn />
              <Typography variant="h6">
                {" "}
                {editId && currentHall
                  ? `Edit ${currentHall.name}`
                  : "Add Stand"}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="center" flex={1} gap={3}>
              <Paper
                sx={{
                  paddingLeft: 2,
                  paddingRight: 2,
                  backgroundColor: "#E8F4F9",
                }}
              >
                <Stack direction="column" alignItems="center">
                  <Typography variant="body2" align="center">
                    Color
                  </Typography>
                  <IconButton
                    onClick={chooseColor}
                    sx={{ color: strokeColor }}
                    size="small"
                  >
                    <CircleIcon />
                  </IconButton>
                  {showColorPicker && (
                    <Draggable>
                      <div style={{ position: "absolute", zIndex: 100 }}>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <CloseButton
                            myStyle={{ bottom: "-12px", left: "12px" }}
                            onClose={() => setShowColorPicker(false)}
                          />
                        </div>
                        <SketchPicker
                          color={strokeColor}
                          onChange={onColorChange}
                        />
                      </div>
                    </Draggable>
                  )}
                </Stack>
              </Paper>

              <Paper
                sx={{
                  paddingLeft: 2,
                  paddingRight: 2,
                  backgroundColor: "#E8F4F9",
                }}
              >
                <Typography variant="body2" align="center">
                  Stroke Width
                </Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <IconButton
                    onClick={decreaseStrokeWidth}
                    color="primary"
                    size="small"
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                  <Typography variant="subtitle1">{strokeWidth}</Typography>
                  <IconButton
                    onClick={increaseStrokeWidth}
                    color="primary"
                    size="small"
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </Paper>

              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: 2,
                  paddingRight: 2,
                  backgroundColor: "#E8F4F9",
                }}
              >
                <RadioGroup
                  row
                  value={selectedMode}
                  onChange={handleModeChange}
                >
                  <FormControlLabel
                    value={RECTANGLE}
                    control={<Radio />}
                    label={RECTANGLE}
                  />
                  <FormControlLabel
                    value={POLYGON}
                    control={<Radio />}
                    label={POLYGON}
                  />
                </RadioGroup>
              </Paper>
            </Box>

            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                onClick={onSave}
                size="small"
                variant="contained"
                startIcon={<SaveAsIcon />}
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
          <Card
            variant="outlined"
            sx={{
              width: image
                ? `${CONTAINER_HEIGHT * (image.width / image.height)}px`
                : `auto`,
              height: `${CONTAINER_HEIGHT}px`,
              mt: 1,
              ml: "auto",
              mr: "auto",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {image && (
              <Stage
                width={CONTAINER_HEIGHT * (image.width / image.height)}
                height={CONTAINER_HEIGHT}
                scaleX={stageScale}
                scaleY={stageScale}
                ref={stageRef}
                x={stagePos.x}
                y={stagePos.y}
                onWheel={handleWheel}
                draggable={!drawing}
                onMouseDown={startDrawing}
                onMouseMove={updateDrawing}
                onMouseUp={endDrawing}
                onClick={handleStageClick}
              >
                <Layer>
                  <Image
                    image={image.img}
                    width={CONTAINER_HEIGHT * (image.width / image.height)}
                    height={CONTAINER_HEIGHT}
                  />

                  {data?.stands
                    ?.filter((hall) => hall.shape.type === "Polygon")
                    .map((hall) => (
                      <Group>
                        <Line
                          points={hall.shape.shape}
                          fill={hexToRGBA(hall.color, TRANSPARENCY)}
                          stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                          strokeWidth={hall.strokeWidth}
                          closed={true}
                          onClick={() => {
                            // This is where your click logic goes
                            console.log(`Clicked on hall with id: ${hall}`);
                          }}
                        />
                      </Group>
                    ))}
                  {data?.stands
                    ?.filter((hall) => hall.shape.type === "Rectangle")
                    .map((hall) => (
                      <Rect
                        x={hall.shape.shape.x}
                        y={hall.shape.shape.y}
                        width={hall.shape.shape.width}
                        height={hall.shape.shape.height}
                        fill={hexToRGBA(hall.color, TRANSPARENCY)}
                        stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                        strokeWidth={hall.strokeWidth}
                        onClick={(e) => {
                          navigate(`/fp-add-stand/${id}?editId=${hall._id}`);
                        }}
                      />
                    ))}

                  {selectedMode === POLYGON && (
                    <Group draggable={true}>
                      <Line
                        points={points}
                        fill={hexToRGBA(strokeColor, TRANSPARENCY)}
                        stroke={hexToRGBA(strokeColor, TRANSPARENCY)}
                        strokeWidth={strokeWidth}
                        closed={polygonClosed}
                      />
                      {renderedDots}
                    </Group>
                  )}

                  {rectangle && selectedMode === RECTANGLE && (
                    <Rect
                      x={rectangle.x}
                      y={rectangle.y}
                      width={rectangle.width}
                      height={rectangle.height}
                      fill={hexToRGBA(strokeColor, TRANSPARENCY)}
                      draggable
                      onDragStart={() => setResizing(false)}
                      onDragEnd={handleDragMove}
                      onClick={(e) => {
                        e.cancelBubble = true;
                        transformerRef.current.nodes([e.target]);
                        transformerRef.current.getLayer().batchDraw();
                        setResizing(true);
                      }}
                    />
                  )}
                  {rectangle && selectedMode === RECTANGLE && (
                    <Transformer
                      ref={transformerRef}
                      rotateEnabled={true} // Disable rotation
                      boundBoxFunc={(oldBox, newBox) => {
                        if (newBox.width < 5 || newBox.height < 5) {
                          return oldBox;
                        }
                        return newBox;
                      }}
                    />
                  )}
                </Layer>
              </Stage>
            )}
          </Card>
        </Box>
      ) : (
        <UploadHall
          color={strokeColor}
          strokeWidth={strokeWidth}
          originalDimen={{ width: 1280, height: innerHeight.current - 79 }}
          onBack={onBack}
          points={points}
          rectangle={rectangle}
          selectedMode={selectedMode}
        />
      )}{" "}
    </Box>
  );
};

export default AddStand;
