import { toast } from "react-toastify";

export function navToElementMarker(elem, msg) {
  if (elem.current) {
    elem.current.scrollIntoView({ behavior: "smooth", block: "center" });
    elem.current.parentElement.style.border = "1px solid red";
    setTimeout(() => {
      elem.current.parentElement.style.border = ""; // Revert to the original style
    }, 3000);
    toast.error(msg);
  }
}
export function toastError(error) {
  if (error.response && error.response.data && error.response.data.message) {
    toast.error(error.response.data.message);
  } else {
    toast.error("An error occurred. Please try again.");
  }
}
