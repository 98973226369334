import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { VISITOR_API } from "../../constants/constants";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import { Box, Button } from "@mui/material";
import BackBtn from "../dashboard/admin/widgets/BackBtn";
import { BadgeToPrint } from "../dashboard/visitor/BadgeToPrint";
import { useReactToPrint } from "react-to-print";
import RegHeader from "../../visitorregt/components/headers/regHeader";
import ReactDOMServer from "react-dom/server";
import { isValidMongoDbId } from "../../utility/utility";
class PrintableRowComponent extends React.Component {
  render() {
    const { row } = this.props;
    const vCard = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `NOTE: Barcode ID: ${row._id}`,
      `N:${row.lastName};${row.firstName}${row.name} ;;;`,
      `FN:${row.title} ${row.firstName} ${row.lastName}${row.name}`,
      `ORG:${row.companyName}${row.instituteName}`,
      `TITLE:${row.designation}${row.education}`,
      `EMAIL;TYPE=PREF,INTERNET:${row.email}`,
      "END:VCARD",
    ].join("\n");
    return (
      <div
        style={{
          textAlign: "center",
          width: "9.8cm",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div
            style={{ marginTop: "5.6cm", fontWeight: "bold", fontSize: "21px" }}
          >
            {row.firstName?.toUpperCase()} {row.lastName?.toUpperCase()}
            {row.name?.toUpperCase()}
          </div>
          <div style={{ fontSize: "19px", marginTop: "12px" }}>
            {row.companyName?.toUpperCase()}
            {row.instituteName?.toUpperCase()}
          </div>
        </div>
        <div style={{ marginTop: "16px" }}>
          <QRCode value={vCard} size={130} />
        </div>
      </div>
    );
  }
}
function ScanBadge() {
  const [currentUser, setCurrentUser] = useState();
  const [barcodeId, setBarcodeId] = useState("");
  const printRef = useRef();
  const [isPrinting, setIsPrinting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    // If not printing, focus the input field
    if (!isPrinting && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isPrinting]);

  const handleScanInput = (event) => {
    const scannedValue = event.target.value;
    const barcodeId = extractBarcodeId(scannedValue);
    if (barcodeId && (barcodeId.length === 14 || isValidMongoDbId(barcodeId))) {
      if (!isLoading && !isPrinting) {
        fetchUserDetails(barcodeId);
      }
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      inputRef.current.value = "";
      inputRef.current?.focus();
    }
  };
  function extractBarcodeId(vCardString) {
    const noteRegex = /Barcode ID: ((\d{14})|([a-f0-9]{24}))/i;
    const match = vCardString.match(noteRegex);
    return match ? match[1] : null;
  }

  const fetchUserDetails = async (scannedData) => {
    setIsLoading(true);
    const barcodeId = scannedData; //extractBarcodeId(scannedData);
    try {
      const { data } = await axios.get(
        `${VISITOR_API}get-visitor?barcode_id=${barcodeId}`
      );
      printRow(data);
    } catch (error) {
      toast.error(error.message);
      inputRef.current.value = "";
    } finally {
      setIsLoading(false);
      inputRef.current.value = "";
      inputRef.current?.focus();
    }
  };

  const printRow = (row) => {
    setIsPrinting(true);
    const printableContent = ReactDOMServer.renderToString(
      <PrintableRowComponent row={row} />
    );
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${printableContent}
      </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus(); // For some browsers to trigger the print dialog
    printWindow.print();
    printWindow.close();
    inputRef.current.value = "";
    setIsPrinting(false);
  };

  return (
    <div
      style={
        {
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center",
          // justifyContent: "center",
          // height: "100vh",
          // background: "#f0f0f0",
        }
      }
    >
      <RegHeader />
      <Box ml={2} mt={2}>
        <BackBtn />
      </Box>

      <Box
        mt={2}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          {currentUser && <BadgeToPrint ref={printRef} user={currentUser} />}
          <h2>The Cursor should be in the Input Field while Scanning.</h2>
          <Box display={"table"} ml={"auto"} mr={"auto"}>
            <input
              ref={inputRef}
              id="barcodeInput"
              type="text"
              // value={barcodeId}
              onChange={handleScanInput}
              onKeyDown={handleKeyDown}
              placeholder="Scan QR Code"
              style={{
                padding: "10px",
                fontSize: "16px",
                width: "300px",
                textAlign: "center",
                borderRadius: "4px",
                border: "2px solid #000",
              }}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default ScanBadge;
