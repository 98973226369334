import React, { useEffect, useState } from "react";
import PreviewComponent from "./PreviewComponent";
import PreviewPageRegister from "./PreviewPageRegister";

const PreviewFormContainer = () => {
  const [state, setState] = useState({
    title: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    companyName: "",
    address: "",
    selectedCountry: "",
    selectedState: "",
    selectedCity: "",
    pin: "",
    website: "",
    telephone: "",
    fbCheck: false,
    instaCheck: false,
    twitterCheck: false,
    linkedInCheck: false,
    fbLink: "",
    instaLink: "",
    twitterLink: "",
    linkedInLink: "",
    designation: "",
    department: "",
    selectedEventSource: "",
    selectedIndustrySector: "",
    eventsList: [],
    selectedCategories: [],
  });
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleStateChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  return (
    <div>
      {isPopupVisible && (
        <div style={styles.popup}>
          <div style={styles.popupContent}>
            <h3>Registration is closed</h3>
          </div>
        </div>
      )}
      <PreviewPageRegister
        state={state}
        handleStateChange={handleStateChange}
      />
    </div>
  );
};
const styles = {
  popup: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)", // semi-transparent black
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000, // to ensure the popup is on top of everything else
  },
  popupContent: {
    width: "300px",
    padding: "20px",
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    textAlign: "center",
  },
};

export default PreviewFormContainer;
