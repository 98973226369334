import {
  Box,
  Button,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { ExpoTheme } from "../../../theme/theme";
import RegHeader from "../../../visitorregt/components/headers/regHeader";
import Footer from "../../footer/footer";
import { toast } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import axios from "axios";
import { VISITOR_API } from "../../../constants/constants";
import ReactDOMServer from "react-dom/server";
class PrintableComponent extends React.Component {
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          width: "9.8cm",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {this.props?.data?.map((row, index) => {
          const vCard = [
            "BEGIN:VCARD",
            "VERSION:3.0",
            `NOTE: Barcode ID: ${row._id}`,
            `N:${row.lastName};${row.firstName};;;`,
            `FN:${row.title} ${row.firstName} ${row.lastName}`,
            `ORG:${row.companyName}`,
            `TITLE:${row.designation}`,
            `EMAIL;TYPE=PREF,INTERNET:${row.email}`,
            "END:VCARD",
          ].join("\n");
          return (
            <div key={index} style={{ pageBreakAfter: "always" }}>
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    marginTop: "5.6cm",
                    fontWeight: "bold",
                    fontSize: "21px",
                  }}
                >
                  {row.firstName.toUpperCase()} {row.lastName.toUpperCase()}
                </div>
                <div style={{ fontSize: "19px", marginTop: "12px" }}>
                  {row.companyName.toUpperCase()}
                </div>
                <div style={{ marginTop: "16px" }}>
                  <QRCode value={vCard} size={130} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

class PrintableRowComponent extends React.Component {
  render() {
    const { row } = this.props;

    const vCard = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `NOTE: Barcode ID: ${row._id}`,
      `N:${row.lastName};${row.firstName};;;`,
      `FN:${row.title} ${row.firstName} ${row.lastName}`,
      `ORG:${row.companyName}`,
      `TITLE:${row.designation}`,
      `EMAIL;TYPE=PREF,INTERNET:${row.email}`,
      "END:VCARD",
    ].join("\n");
    return (
      <div style={{ textAlign: "center" }}>
        <div
          style={{ marginTop: "5.6cm", fontWeight: "bold", fontSize: "21px" }}
        >
          {row.firstName.toUpperCase()} {row.lastName.toUpperCase()}
        </div>
        <div style={{ fontSize: "19px", marginTop: "12px" }}>
          {row.companyName.toUpperCase()}
        </div>
        <div style={{ marginTop: "16px" }}>
          <QRCode value={vCard} size={130} />
        </div>
      </div>
    );
  }
}
const ForwardPrintableComponent = React.forwardRef((props, ref) => (
  <PrintableComponent ref={ref} {...props} />
));

const BulkStudent = () => {
  const [prefix, setPrefix] = useState("");
  const [institutionName, setInstitutionName] = useState("");
  const [numBadges, setNumBadges] = useState("");
  const [groupName, setGroupName] = useState("");
  const [students, setStudents] = useState();
  const [saving, setSaving] = useState(false);

  const printComponentRef = useRef();

  const handleProceed = async () => {
    // Validate institution name
    if (!institutionName.trim()) {
      toast.error("Institution name is required.");
      return; // Exit the function if validation fails
    }
    if (!groupName.trim()) {
      toast.error("Group name is required.");
      return; // Exit the function if validation fails
    }
    // Validate number of badges
    if (!numBadges) {
      toast.error("Number of badges is required.");
      return; // Exit the function if validation fails
    } else if (isNaN(numBadges) || numBadges < 1) {
      toast.error("Number of badges must be a positive integer.");
      return; // Exit the function if validation fails
    }
    const dataToSave = {
      num: numBadges,
      prefix: prefix,
      group_name: groupName,
      companyName: institutionName,
    };
    try {
      const { data } = await axios.post(
        VISITOR_API + `save-bulk-student-num`,
        dataToSave
      );
      setStudents(data);
    } catch (error) {
      toast.error(error.message);
    }

    // // Generate numBadges student objects
    // const students = [];
    // for (let i = 1; i <= numBadges; i++) {
    //   const timestamp = new Date().getTime(); // Get current timestamp
    //   const uniqueId = `${timestamp}${i}`; // Create a unique ID by combining timestamp and index

    //   students.push({
    //     barcode_id: uniqueId, // Unique numeric ID
    //     firstName: `${prefix} ${i}`,
    //     lastName: "", // Assuming last name is blank for this example
    //     companyName: { value: institutionName },
    //     designation: prefix,
    //     email: `${prefix}${i}@gmail.com`,
    //   });
    // }
    //setStudents(students);
  };

  const printRow = (row) => {
    const printableContent = ReactDOMServer.renderToString(
      <PrintableRowComponent row={row} />
    );
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${printableContent}
      </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus(); // For some browsers to trigger the print dialog
    printWindow.print();
    printWindow.close();
  };

  return (
    <ThemeProvider theme={ExpoTheme}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <RegHeader />
        <div style={{ display: "none" }}>
          <ForwardPrintableComponent ref={printComponentRef} data={students} />
        </div>
        <Box
          mt={1}
          width={"100%"}
          maxWidth={700}
          pl={1}
          pr={1}
          ml={"auto"}
          mr={"auto"}
        >
          <Typography
            textAlign={"center"}
            color={"rgb(151, 42, 76)"}
            fontWeight={"bold"}
            fontSize={"28px"}
          >
            BULK BADGES
          </Typography>
        </Box>
        <Box
          mt={4}
          sx={{
            maxWidth: 600,
            ml: "auto",
            mr: "auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <TextField
            fullWidth
            size="small"
            sx={{ mt: 2 }}
            label="Group Name"
            variant="outlined"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
          <TextField
            fullWidth
            size="small"
            sx={{ mt: 2 }}
            label="Name/Prefix"
            variant="outlined"
            value={prefix}
            onChange={(e) => setPrefix(e.target.value)}
          />
          <TextField
            fullWidth
            size="small"
            sx={{ mt: 2 }}
            label="Institution Name"
            variant="outlined"
            value={institutionName}
            onChange={(e) => setInstitutionName(e.target.value)}
          />
          <TextField
            fullWidth
            size="small"
            sx={{ mt: 2 }}
            label="Number of Badges"
            variant="outlined"
            type="number"
            value={numBadges}
            onChange={(e) => setNumBadges(e.target.value)}
          />
          <Button
            size="small"
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
            color="primary"
            onClick={handleProceed}
          >
            Proceed
          </Button>
        </Box>
        {students && (
          <Box maxWidth={1200} width={"100%"} mt={3} mx="auto">
            <Box display={"flex"}>
              <Typography variant="h6" flex={1}>
                No. of Students: {students.length}
              </Typography>
              <ReactToPrint
                trigger={() => (
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={<PrintIcon />}
                  >
                    Save & Print All
                  </Button>
                )}
                content={() => printComponentRef.current}
              />
            </Box>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Barcode ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Institution</TableCell>
                    <TableCell>Designation</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Print</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students.map((student, index) => (
                    <TableRow key={index}>
                      <TableCell>{student.barcode_id}</TableCell>
                      <TableCell>
                        {student.firstName} {student.lastName}
                      </TableCell>
                      <TableCell>{student.companyName}</TableCell>
                      <TableCell>{student.designation}</TableCell>
                      <TableCell>{student.email}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            printRow(student);
                          }}
                          size="small"
                          variant="contained"
                          startIcon={<PrintIcon />}
                        >
                          Print
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
        <Box
          sx={{
            mt: 4,
            flexGrow: 1, // This will make the box grow to fill available space
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default BulkStudent;
