import React from "react";
import regLogo from "../../../assets/imtex_header.png";
import mLogo from "../../assets/mlogo.png";
import lLogo from "../../assets/llogo.png";
import headerC from "../../../assets/imtex_header1.png";
import headerBg from "../../../assets/header_bg.png";
import "./regHeader.css";

const RegHeader = () => {
  return (
    <a href="https://www.imtex.in/" target="_blank">
      <div
        className="newhcontainer"
        style={{
          backgroundImage: `url(${headerBg})`,
        }}
      >
        <img className="newhheader-image" src={headerC} alt="App Header" />
      </div>
    </a>
  );
};

export default RegHeader;
