import React, { useState } from "react";
import { Paper, Typography, Collapse, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

function FAQItem({ question, answer }) {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Paper elevation={1} sx={{ mt: 2, mb: 1, padding: "1em" }}>
      <Typography
        variant="h6"
        onClick={toggleOpen}
        sx={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {question}
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Typography>
      <Collapse in={open}>
        <Typography sx={{ marginTop: "0.5em" }}>{answer}</Typography>
      </Collapse>
    </Paper>
  );
}

const CollapseIn = () => {
  const faqs = [
    {
      question: "What is MUI?",
      answer:
        "MUI is a popular React UI framework that provides ready-to-use components that are also customizable.",
    },
    {
      question: "Why use MUI?",
      answer:
        "It helps in building a UI fast and efficiently with less boilerplate code, ensuring consistency across your application.",
    },
    {
      question: "How to customize MUI components?",
      answer:
        "MUI components can be customized via the theme configuration, using the `sx` prop, or by overriding styles.",
    },
  ];

  return (
    <div>
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default CollapseIn;
