import { useQuery } from "react-query";
import axios from "axios";
import { getAdminHeader } from "../../../../utility/utility";
import { ADMIN_API } from "../../../../constants/constants";

const fetchVisitor = (id) => {
  return axios
    .get(`${ADMIN_API}/get-visitor?userId=${id}`, getAdminHeader())
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useVisitor = (id) => {
  return useQuery(["get-visitor", id], () => fetchVisitor(id), {
    staleTime: 5 * 60 * 1000, // Data will be considered stale after 5 minutes
    retry: 3, // If the query fails, it will retry up to 3 times
  });
};

export default useVisitor;
