import { useQuery } from "react-query";
import axios from "axios";
import { getVisitorHeader } from "../../../../utility/utility";
import { VISITOR_API } from "../../../../constants/constants";

const fetchItems = (page = 1, searchQ) => {
  return axios
    .get(
      `${VISITOR_API}get-matched-exhibitors?page=${page}}`,
      getVisitorHeader()
    )
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useMatchedExhibitors = (page, searchQ) => {
  const queryInfo = useQuery(
    ["get-matched-exhibitors", page, searchQ],
    () => fetchItems(page, searchQ),
    {
      staleTime: 0, // Data will be considered stale after 5 minutes
      retry: 3,
    }
  );
  return {
    ...queryInfo,
    refetchVisitors: queryInfo.refetch, // Extract refetch function from queryInfo
  };
};

export default useMatchedExhibitors;
