import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RdDesignationsSelect from "../../../../../visitorregt/components/previewpage/RdDesignationsSelect";
import PhoneInput from "react-phone-number-input";
import {
  TITLES,
  getExhibitorHeader,
  getHeader,
} from "../../../../../utility/utility";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { isValidEmail } from "../../../../../visitorregt/utils/utils";
import axios from "axios";
import { EXHIBITOR_API, VISITOR_API } from "../../../../../constants/constants";
import CountriesCom from "../../../../../visitorregt/components/previewpage/CountriesCom";
import StatesCom from "../../../../../visitorregt/components/previewpage/StatesCom";
import CitiesCom from "../../../../../visitorregt/components/previewpage/CitiesCom";
import StatesSelect from "./StatesSelect";
import CitiesSelect from "./CitiesSelect";
import CountriesSelect from "./CountriesSelect";

const ContactInfoEdit = ({ user, onUpdated, show, setShow }) => {
  const [contactInfo, setContactInfo] = useState({
    contactSalutation: "",
    contactFirstName: "",
    contactLastName: "",
    contactDesignation: "",
    mobile: "",
    contactEmail: "",
  });
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    let companyO = {
      contactSalutation: user.contactSalutation.value,
      contactFirstName: user.contactFirstName.value,
      contactLastName: user.contactLastName.value,
      contactDesignation: user.contactDesignation.value,
      mobile: user.mobile.value,
      contactEmail: user.contactEmail.value,
    };
    setContactInfo(companyO);
  }, [show]);
  const updateProperty = (e) => {
    setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
  };
  const update = async () => {
    const toUpdateObject = {
      contactSalutation: {
        label: user.contactSalutation.label,
        value: contactInfo.contactSalutation,
      },
      contactFirstName: {
        label: user.contactFirstName.label,
        value: contactInfo.contactFirstName,
      },
      contactLastName: {
        label: user.contactLastName.label,
        value: contactInfo.contactLastName,
      },
      contactDesignation: {
        label: user.contactDesignation.label,
        value: contactInfo.contactDesignation,
      },
      mobile: {
        label: user.mobile.label,
        value: contactInfo.mobile,
      },
      contactEmail: {
        label: user.contactEmail.label,
        value: contactInfo.contactEmail,
      },
    };
    try {
      const { data } = await axios.post(
        `${EXHIBITOR_API}update-exhibitor`,
        toUpdateObject,
        getExhibitorHeader()
      );
      setSaving(false);
      setShow(false);
      toast.success(data.message);
      onUpdated();
    } catch (error) {
      setSaving(false);
      if (error.response) {
        // The request was made and the server responded with a status code outside the range of 2xx
        console.error("Error Data:", error.response.data);
        console.error("Error Status:", error.response.status);
        console.error("Error Headers:", error.response.headers);
        toast.error(
          `Error: ${error.response.data.message || "Something went wrong"}`
        );
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error Request:", error.request);
        toast.error("No response from server. Please try again later.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message);
        toast.error("An error occurred. Please try again.");
      }
    }
  };
  return (
    <Container>
      <Typography
        mb={2}
        fontSize={18}
        color={"#444"}
        fontWeight={600}
        variant="body1"
      >
        Update Contact Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">
              {user.contactSalutation.label}*
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={contactInfo.contactSalutation}
              name="contactSalutation"
              label={user.contactSalutation.label}
              onChange={updateProperty}
            >
              {TITLES.map((position, i) => (
                <MenuItem key={i} value={position}>
                  {position}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.contactFirstName.label}
            name="contactFirstName"
            value={contactInfo.contactFirstName}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.contactLastName.label}
            name="contactLastName"
            value={contactInfo.contactLastName}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.contactDesignation.label}
            name="contactDesignation"
            value={contactInfo.contactDesignation}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.mobile.label}
            name="mobile"
            value={contactInfo.mobile}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.contactEmail.label}
            name="contactEmail"
            value={contactInfo.contactEmail}
            onChange={updateProperty}
          />
        </Grid>
      </Grid>

      <LoadingButton
        loading={saving}
        onClick={update}
        sx={{
          mt: 4,
          ml: "auto",
          mr: "auto",
          display: "block",
          mb: 2,
          width: 120,
        }}
        variant="contained"
      >
        Save
      </LoadingButton>
    </Container>
  );
};

export default ContactInfoEdit;
