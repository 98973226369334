import React, { useEffect, useState } from "react";
import "../dashboard.css";
import Footer from "../../footer/footer";
import DashHeader from "../header/DashHeader";
import {
  DEFAULT_DP,
  MOBILE_THRESHOLD,
  VISITOR,
  VISITOR_API,
} from "../../../constants/constants";
import Dashboard from "./Dashboard";
import {
  EXHIBITOR_MENU_LIST,
  VISITOR_MENU_LIST,
  VISITOR_ROUTES,
  deleteUser,
  getHeader,
  isLoggedIn,
  timeAgo,
} from "../../../utility/utility";
import RdMenuList from "../../../widgets/RdMenuList";
import { useNavigate, useParams } from "react-router-dom";
import ProfileDetails from "./ProfileDetails";
import FloorPlan from "./FloorPlan";
import MySchedule from "./MySchedule";
import Favorites from "./Favorites";
import Reports from "./Reports";
import Settings from "./Settings";
import Explore from "./explore/Explore";
import axios from "axios";
import NewDashHeader from "../header/NewDashHeader";
import SideMenuList from "../exhibitor/profile/SideMenuList";
import MuPb from "../../../widgets/MuPb";
import PrintBadge from "./PrintBadge";
import MobileSideMenuList from "../exhibitor/profile/MobileSideMenuList";
import useLoginProfile from "./hooks/useLoginProfile";
import useMeetings from "../exhibitor/hooks/useMeetings";
import useNotifications from "../exhibitor/hooks/useNotifications";
import AllMeetings from "../exhibitor/meeting/AllMeetings";

const VisitorDashboard = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [reloadProfile, setReloadProfile] = useState(false);
  const [collapse, setCollapse] = useState(false);

  const {
    data: notificationsData,
    isLoading: notificationsLoading,
    isError: isNotificationError,
    refetch: refetchNotifications,
  } = useNotifications(user?.email);

  function checkLoginStatus() {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }
  const onLogout = () => {
    deleteUser();
    checkLoginStatus();
  };

  useEffect(() => {
    getProfile();
  }, [reloadProfile]);
  function getProfile() {
    axios.get(VISITOR_API + "get-profile", getHeader()).then((res) => {
      let d = res.data;
      if (d.success) {
        setUser(d.message);
      }
    });
  }
  const onMenuClick = () => {
    setShowSideMenu(!showSideMenu);
  };
  if (!user) {
    return <MuPb />;
  }

  const notificationsA = [
    { title: "Welcome to IMTEX FORMING 2024", subtitle: "" },
    {
      title: `You changed password ${timeAgo(user.createdOn)}`,
      subtitle: timeAgo(user.createdOn),
    },
  ];

  return (
    <div className="rd-expo-dash-c">
      <NewDashHeader
        profileLink={`/visitor/profile`}
        onMenuClick={onMenuClick}
        notifications={notificationsData}
        onLogout={onLogout}
        profileImg={user.profile_image ? user.profile_image : DEFAULT_DP}
      />
      <div className="rd-dashboard-c">
        <div style={{ display: "flex" }}>
          <SideMenuList
            showSideMenu={showSideMenu}
            onLogout={onLogout}
            category={VISITOR}
            menuList={VISITOR_MENU_LIST}
            page={page}
          />
          <MobileSideMenuList
            showSideMenu={showSideMenu}
            setShowSideMenu={setShowSideMenu}
            onLogout={onLogout}
            category={VISITOR}
            menuList={VISITOR_MENU_LIST}
            page={page}
          />
          <div style={{ flex: 1, width: "100%" }}>
            {page === "dashboard" || page === undefined ? (
              <Dashboard user={user} />
            ) : page === "profile" ? (
              <ProfileDetails
                user={user}
                setReloadProfile={setReloadProfile}
                setUser={setUser}
              />
            ) : page === "explore" ||
              page === "exhibitors" ||
              page === "products" ||
              page === "ai-matching" ||
              page === "visitors" ? (
              <Explore page={page} />
            ) : page === "floor-plan" ? (
              <FloorPlan />
            ) : page === "all-meetings" ? (
              <AllMeetings user={user} />
            ) : page === "my-schedule" ? (
              <MySchedule />
            ) : page === "print-badge" ? (
              <PrintBadge user={user} />
            ) : page === "favorites" ? (
              <Favorites />
            ) : page === "reports" ? (
              <Reports />
            ) : page === "settings" ? (
              <Settings />
            ) : (
              <></>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default VisitorDashboard;
