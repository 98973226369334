import axios from "axios";
import React, { useEffect, useState } from "react";
import { EXHIBITOR_API } from "../../../../constants/constants";
import { getExhibitorHeader, getHeader } from "../../../../utility/utility";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import ProductItem from "../../../../widgets/ProductItem";
import { toast } from "react-toastify";
import ProductDetail from "../ProductDetail";
import { Box, Button, Grid, Pagination, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useProducts from "../hooks/useProducts";
import MuPb from "../../../../widgets/MuPb";
import useExploreProducts from "../hooks/getExploreProducts";

const Products = ({
  searchFilter,
  currentView,
  setCurrentView,
  parentView,
}) => {
  const DETAIL_VIEW = "detail_view";
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, error, refetchProducts } =
    useExploreProducts(page, searchFilter);
  const [currentProduct, setCurrentProduct] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);

  // useEffect(() => {
  //     if (products.length > 0) {
  //         const results = products.filter(product =>
  //             product.name.toLowerCase().includes(searchFilter.toLowerCase())
  //         );
  //         setFilteredProducts(results);
  //     } else {
  //         setFilteredProducts([]);
  //     }
  // }, [searchFilter, products]);
  // useEffect(()=>{
  //     loadProducts()
  // }, [])

  // function loadProducts(){
  //     axios.get(EXHIBITOR_API+'get-all-products', getExhibitorHeader()).then(res=>{
  //         setLoading(false)
  //         let d = res.data
  //         if(d.success){
  //             setProducts(d.message)
  //         }
  //     }).catch(err => {
  //         setLoading(false)
  //         toast.error(`An error occurred while fetching exhibitors: ${err}`)
  //     })
  // }
  const onEditProduct = () => {};
  const onDeleteProduct = () => {};
  const onSelectProduct = (product) => {
    setCurrentProduct(product);
    setCurrentView(DETAIL_VIEW);
  };
  const onBack = () => {
    setCurrentView(parentView);
  };
  if (isLoading) {
    return <MuPb />;
  }
  const onPageChange = (e, page) => {
    setPage(page);
  };
  return (
    <div>
      {currentView === parentView ? (
        <>
          {data?.data.length > 0 ? (
            <Box>
              <Grid
                pl={2}
                pr={2}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h6">Products</Typography>
                </Grid>
                <Grid item>
                  <Pagination
                    count={Math.ceil(data?.totalItems / 30)}
                    page={page}
                    onChange={onPageChange}
                    color="primary"
                  />
                </Grid>
              </Grid>
              {data?.data.map((product, i) => (
                <ProductItem
                  viewMode={true}
                  onSelectProduct={onSelectProduct}
                  onEditProduct={onEditProduct}
                  onDeleteProduct={onDeleteProduct}
                  key={i}
                  product={product}
                />
              ))}
            </Box>
          ) : (
            <div className="items-not-found">No Products Found</div>
          )}
        </>
      ) : (
        <>
          <Button
            onClick={onBack}
            variant="outlined"
            sx={{ margin: "12px 0" }}
            size="small"
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </Button>

          <ProductDetail product={currentProduct} />
        </>
      )}
    </div>
  );
};

export default Products;
