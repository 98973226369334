import { useQuery } from "react-query";
import axios from "axios";
import { getCommonHeaderVE } from "../../../utility/utility";
import { EXHIBITOR_API } from "../../../constants/constants";

const fetchItems = (hallId) => {
  return axios
    .get(
      `${EXHIBITOR_API}get-exhibitors-by-hall-id?hallId=${hallId}`,
      getCommonHeaderVE()
    )
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useExhibitorByHallId = (hallId) => {
  return useQuery(["exhibitors-by-hall-id", hallId], () => fetchItems(hallId), {
    staleTime: 5 * 60 * 1000, // Data will be considered stale after 5 minutes
    retry: 3, // If the query fails, it will retry up to 3 times
  });
};

export default useExhibitorByHallId;
