import React from "react";
import "./ExhibitorCard.css";
import { IconButton } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StarIcon from "@mui/icons-material/Star";
import { toast } from "react-toastify";
import {
  DEFAULT_DP,
  INTERACT_FAVORITE,
  INTERACT_MEETING,
  INTERACT_MESSAGE,
} from "../constants/constants";
import ForumIcon from "@mui/icons-material/Forum";
import EastIcon from "@mui/icons-material/East";
const VisitorCard = ({ item, onInteract, onVisitorSelect }) => {
  const onClickVisitor = () => {
    onVisitorSelect(item);
  };
  return (
    <div className="exhibitor-card-c visitor-card">
      <img
        src={item.profile_image ? item.profile_image : DEFAULT_DP}
        style={{
          width: "90px",
          borderRadius: "50%",
          display: "block",
          margin: "auto",
          border: "1px solid #fdca48",
        }}
      />
      <div style={{ margin: "12px 0", textAlign: "center" }}>
        <div>
          <div
            style={{
              fontWeight: 500,
              userSelect: "none",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              lineHeight: "1.2em", // Adjust based on actual line height
              height: "2.4em", // Adjust based on actual line height
              width: "100%", // Ensure enough width for two lines
            }}
          >
            {item.firstName} {item.lastName}
          </div>

          <div
            style={{
              userSelect: "none",
              marginTop: "8px",
              marginBottom: "8px",
              color: "#777",
              fontSize: "12px",
            }}
          >
            {item.designation}
          </div>

          <div style={{ display: "flex", margin: "4px 0" }}>
            <div style={{ flex: 1, display: "flex", gap: "12px" }}>
              <IconButton
                onClick={() => onInteract(INTERACT_MESSAGE, item.email)}
                sx={{ backgroundColor: "#dbedff" }}
                aria-label="message"
                size="small"
              >
                <ForumIcon sx={{ color: "#00a2d9" }} fontSize="inherit" />
              </IconButton>
              <IconButton
                onClick={() => onInteract(INTERACT_MEETING, item)}
                sx={{ backgroundColor: "#ffead2" }}
                aria-label="message"
                size="small"
              >
                <CalendarMonthIcon
                  sx={{ color: "#ff981f" }}
                  fontSize="inherit"
                />
              </IconButton>
              <IconButton
                onClick={() => onInteract(INTERACT_FAVORITE, item.email_id)}
                sx={{ backgroundColor: "#ffead2" }}
                aria-label="message"
                size="small"
              >
                <StarIcon sx={{ color: "#fb4d24" }} fontSize="inherit" />
              </IconButton>
            </div>
            <IconButton
              onClick={onClickVisitor}
              aria-label="arrow"
              size="small"
            >
              <EastIcon fontSize="inherit" sx={{ color: "#3cb4e0" }} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorCard;
