import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { PRODUCT_CATEGORIES } from "../../../constants/constants";

const CategoriesSelectFree = ({ categoriesA, setCategoriesA }) => {
  const deriveCheckedSubcategories = (selectedCategories) => {
    return selectedCategories.reduce((acc, cat) => {
      const [code, _] = cat.split(" - ");
      acc[code] = true;
      return acc;
    }, {});
  };
  const [openMainCategories, setOpenMainCategories] = useState({});
  const [openCategories, setOpenCategories] = useState({});
  const [checkedSubcategories, setCheckedSubcategories] = useState(
    deriveCheckedSubcategories(categoriesA)
  );

  const toggleMainCategory = (code) => {
    setOpenMainCategories((prev) => ({ ...prev, [code]: !prev[code] }));
  };

  const toggleCategory = (code) => {
    setOpenCategories((prev) => ({ ...prev, [code]: !prev[code] }));
  };

  const toggleSubcategory = (code) => {
    setCheckedSubcategories((prev) => ({ ...prev, [code]: !prev[code] }));
  };

  // Group data by MainCategory and then by Category
  const groupedData = PRODUCT_CATEGORIES.reduce((acc, curr) => {
    if (!acc[curr.MainCategory.code]) {
      acc[curr.MainCategory.code] = { ...curr.MainCategory, categories: {} };
    }
    if (!acc[curr.MainCategory.code].categories[curr.Category.code]) {
      acc[curr.MainCategory.code].categories[curr.Category.code] = {
        ...curr.Category,
        subcategories: [],
      };
    }
    acc[curr.MainCategory.code].categories[
      curr.Category.code
    ].subcategories.push(curr.Subcategory);
    return acc;
  }, {});
  const subcategoryCodeToData = {};

  Object.values(groupedData).forEach((mainCategory) => {
    Object.values(mainCategory.categories).forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        subcategoryCodeToData[subcategory.code] = {
          code: subcategory.code,
          name: subcategory.name,
        };
      });
    });
  });

  useEffect(() => {
    const selectedSubcategories = Object.keys(checkedSubcategories)
      .filter((code) => checkedSubcategories[code]) // Only get checked codes
      .map((checkedCode) => {
        const data = subcategoryCodeToData[checkedCode];
        return `${data?.code} - ${data?.name}`; // Concatenate code and name with a hyphen
      });
    if (selectedSubcategories.length != categoriesA.length) {
      setCategoriesA(selectedSubcategories);
    }
  }, [checkedSubcategories]);
  return (
    <List
      style={{
        border: "1px solid #E0E0E0",
        borderRadius: 5,
        overflow: "hidden",
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      {Object.values(groupedData).map((mainCategory) => (
        <React.Fragment key={mainCategory.code}>
          {/* Main Category */}
          <ListItemButton
            onClick={() => toggleMainCategory(mainCategory.code)}
            style={{
              backgroundColor: "#F7F7F7",
              borderBottom: "1px solid #E0E0E0",
            }}
          >
            <ListItemText primary={<>{mainCategory.name}</>} />
            {openMainCategories[mainCategory.code] ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>

          {/* Categories */}
          <Collapse
            in={openMainCategories[mainCategory.code]}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {Object.values(mainCategory.categories).map((category) => (
                <React.Fragment key={category.code}>
                  <ListItemButton
                    onClick={() => toggleCategory(category.code)}
                    style={{
                      paddingLeft: 30,
                      backgroundColor: "#E9E9E9",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <ListItemText
                      primary={
                        <>
                          {category.code} - {category.name}
                        </>
                      }
                    />
                    {openCategories[category.code] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>

                  {/* Subcategories */}
                  <Collapse
                    in={openCategories[category.code]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {category.subcategories.map((subcategory) => (
                        <ListItemButton
                          key={subcategory.code}
                          role={undefined}
                          dense
                          onClick={() => toggleSubcategory(subcategory.code)}
                          style={{
                            paddingLeft: 60,
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                          onMouseOver={(e) =>
                            (e.currentTarget.style.backgroundColor = "#F0F0F0")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.style.backgroundColor = "")
                          }
                        >
                          <Checkbox
                            size="small"
                            edge="start"
                            checked={
                              checkedSubcategories[subcategory.code] || false
                            }
                            tabIndex={-1}
                            disableRipple
                            color="primary"
                          />
                          <ListItemText
                            primary={`${subcategory.code} - ${subcategory.name}`}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </List>
  );
};

export default CategoriesSelectFree;
