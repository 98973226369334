import React, { useState } from "react";
import { Grid, Paper, Typography, IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ItemsCarousel from "react-items-carousel";
import ExhibitorCard from "../../../widgets/ExhibitorCard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  EXHIBITOR,
  IMTEX_EXHIBITOR,
  INTERACT_FAVORITE,
  INTERACT_MEETING,
  INTERACT_MESSAGE,
  VISITORS,
} from "../../../constants/constants";
import MessagePopup from "./MessagePopup";
import useLoginProfile from "./hooks/useLoginProfile";
import { useNavigate } from "react-router-dom";
import useProfileType from "./meeting/hooks/useProfileType";
export default function HorizontalScrollWithArrows({
  items,
  type: userType,
  onSelect,
}) {
  const navigate = useNavigate();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  const [openMessage, setOpenMessage] = useState(false);
  const [senderMessage, setSenderMessage] = useState();
  const [recipientMessage, setRecipientMessage] = useState();
  const {
    data: profileData,
    isLoading: profileLoading,
    isError: isProfileError,
    error: profileError,
    refetchExhibitorProfile,
  } = useProfileType(userType);
  const onInteract = (type, cuser) => {
    switch (type) {
      case INTERACT_MESSAGE:
        setSenderMessage(userType);
        setRecipientMessage({ username: cuser, type: EXHIBITOR });
        setOpenMessage(true);
        break;
      case INTERACT_MEETING:
        navigate(
          `/meeting-scheduler?uid=${
            userType === VISITORS
              ? profileData.message.email
              : profileData.username
          }&type=${userType}&tid=${cuser.username}&tType=${IMTEX_EXHIBITOR}`
        );
        break;
      case INTERACT_FAVORITE:
        console.log("fav");
        break;
      default:
        console.log("nothing selected");
    }
  };
  return (
    <div style={{ padding: `0 ${chevronWidth}px`, maxWidth: "800px" }}>
      <MessagePopup
        sender={senderMessage}
        recipient={recipientMessage}
        open={openMessage}
        setOpen={setOpenMessage}
      />
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={3}
        gutter={20}
        leftChevron={
          <IconButton size="small" sx={{ bgcolor: "#d5d5d5" }}>
            <ArrowBackIosNewIcon fontSize="small" />
          </IconButton>
        }
        rightChevron={
          <IconButton size="small" sx={{ bgcolor: "#d5d5d5" }}>
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        }
        outsideChevron
        chevronWidth={chevronWidth}
      >
        {items?.data.map((exh, i) => (
          <ExhibitorCard
            key={i}
            item={exh}
            onInteract={onInteract}
            onSelectExhibitor={() => onSelect(exh)}
          />
        ))}
      </ItemsCarousel>
    </div>
  );
}
