import React, { useState } from "react";
import "../dashboard.css";
import InfoCard from "../../../widgets/InfoCard";
import pvsIcon from "../../../assets/views.png";
import meetingIcon from "../../../assets/meetings.png";
import stallsIcon from "../../../assets/stalls.png";
import { exhibitorFilledPercentage } from "../../../utility/utility";
import connectionsIcon from "../../../assets/connections.png";
import { CircularProgressbar } from "react-circular-progressbar";
import DashButton from "../../../widgets/DashButton";
import bgBanner from "../../../assets/db_gif.gif";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import useTeamMembers from "./hooks/useTeamMembers";
import {
  Alert,
  Avatar,
  AvatarGroup,
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import useSimilarExhibitors from "./explore/hooks/useSimilarExhibitors";
import useMatchedVisitors from "./explore/hooks/useMatchedVisitors";
import HorizontalScrollWithArrows from "./HorizontalScrollWithArrows";
import ExhibitorViewById from "./ExhibitorViewById";
import { IMTEX_EXHIBITOR } from "../../../constants/constants";
import MeetingCard from "../../../widgets/MeetingCard";
import useMeetings from "./hooks/useMeetings";

export const SimilarExhibitors = ({
  items,
  type,
  onSelect,
  isLoading,
  isError,
}) => {
  if (isLoading && !isError) {
    return <Typography variant="body1">Loading...</Typography>;
  }
  if (isError) {
    return <Alert severity="error">No data found</Alert>;
  }

  const onExhibitorSelect = () => {};
  return (
    <Box>
      {/* <Box sx={{ fontWeight: 600, fontSize: "16px", color: "#004490" }}>
        Exhibitors
      </Box>
      <Box>
        {items?.data?.map((item, i) => (
          <ExhibitorCard
            key={i}
            item={item}
            // onSelectExhibitor={onExhibitorSelect}
          />
        ))}

        {items?.length === 0 && (
          <Box
            sx={{ color: "#ccc", fontWeight: 600, textAlign: "center", m: 2 }}
          >
            No Data yet
          </Box>
        )}
      </Box> */}
      <HorizontalScrollWithArrows
        type={type}
        onSelect={onSelect}
        items={items}
      />
      {items?.data?.length === 0 && (
        <Box sx={{ color: "#ccc", fontWeight: 600, textAlign: "center", m: 2 }}>
          No Data yet
        </Box>
      )}
    </Box>
  );
};
const Dashboard = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentExhibitor, setCurrentExhibitor] = useState();
  const PARENT_VIEW = "parent_view";
  const DETAIL_VIEW = "detail_view";
  const [currentView, setCurrentView] = useState(PARENT_VIEW);

  const { data, isLoading, isError, error } = useMatchedVisitors(1);
  const {
    data: similarExhibitors,
    isLoading: isSimilarLoading,
    isError: isSerror,
    error: sError,
  } = useSimilarExhibitors(1);

  const {
    data: meetingsData,
    isLoading: isMloading,
    isError: isMerror,
    error: mError,
  } = useMeetings(user.username);
  const { data: teamMembers } = useTeamMembers(1);
  const navigate = useNavigate();
  const markFav = () => {};
  const onUpdateProfile = () => {
    navigate("/exhibitor/profile");
  };
  const onAddTeam = () => {
    navigate("/exhibitor/profile?a=2");
  };
  const onClickItemView = (item) => {
    setCurrentExhibitor(item);
    setIsOpen(true);
  };

  const onSelect = (meetingItem) => {
    navigate(`/meeting/${meetingItem._id}`);
  };

  return (
    <Box>
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          width: "60%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "60%",
            boxSizing: "border-box",
          },
        }}
      >
        <IconButton
          size="small"
          onClick={() => setIsOpen(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 10,
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <Box>
          <ExhibitorViewById id={currentExhibitor?.exhibitorId} />
        </Box>
      </Drawer>
      <div className="rd-dash-content">
        <div className="dash-cards-65-35">
          <div
            className="dash-card-65"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="left-flex-right">
              <div
                className="left-item-flex"
                style={{
                  backgroundImage: `url(${bgBanner})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    fontWeight: 500,
                    fontSize: "19px",
                  }}
                >
                  Welcome!
                </div>
                <div
                  style={{
                    color: "white",
                    fontSize: "17px",
                    marginTop: "4px",
                    fontWeight: 600,
                  }}
                >
                  {user?.companyName?.value}
                </div>
                <div
                  style={{
                    color: "white",
                    fontSize: "14px",
                    marginTop: "10px",
                  }}
                >
                  Track your profile activity, leads and deals here.
                </div>
              </div>
              <div className="right-item">
                <div
                  style={{
                    width: 90,
                    height: 90,
                    display: "block",
                    margin: "auto",
                  }}
                >
                  <CircularProgressbar
                    strokeWidth={12}
                    value={exhibitorFilledPercentage(user)}
                    text={`${exhibitorFilledPercentage(user)}%`}
                  />
                </div>
                <div
                  style={{
                    color: "#666",
                    margin: "10px 12px",
                    fontWeight: 600,
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Complete your profile
                </div>
                <div style={{ flex: 1 }}></div>
                <DashButton
                  onClick={onUpdateProfile}
                  label="Update Profile"
                  color="rgb(47 146 188)"
                  hoverColor="rgb(21 80 106)"
                  bgColor="rgb(229 241 253)"
                  hoverBgColor="rgb(198 217 236)"
                  myStyle={{ width: "120px", margin: "auto" }}
                />
              </div>
              <div className="right-item">
                <div
                  style={{
                    color: "#4d688c",
                    fontWeight: 600,
                    fontSize: "14px",
                    textAlign: "center",
                    marginTop: "8px",
                  }}
                >
                  Team Members
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    fontSize: "19px",
                    margin: "8px 0",
                    color: "#4d688c",
                  }}
                >
                  {`00${teamMembers?.totalItems}`.slice(-2)}
                </div>
                <div style={{ textAlign: "center" }}>
                  <AvatarGroup
                    max={teamMembers?.totalItems}
                    sx={{ justifyContent: "center" }}
                  >
                    {teamMembers?.data?.slice(0, 3).map((av, i) => (
                      <Avatar alt={av.firstName} src={av.profile_image} />
                    ))}
                  </AvatarGroup>
                </div>
                <div style={{ flex: 1 }}></div>
                {/* <DashButton
                  onClick={onAddTeam}
                  label="Add Team"
                  color="#d17a4e"
                  hoverColor="#b55c28"
                  bgColor="#ffead2"
                  hoverBgColor="#ffbca5"
                  myStyle={{ width: "120px", margin: "auto" }}
                /> */}
              </div>
            </div>
            <div
              style={{
                margin: "12px 0",
                fontSize: "17px",
                fontWeight: 600,
                color: "#004490",
              }}
            >
              Statistics
            </div>
            <div className="dash-info-cards">
              <InfoCard
                text="Stall Views"
                color="#ff981f"
                num={0}
                icon={stallsIcon}
              />
              <InfoCard
                text="Profile Views"
                color="#fb4d24"
                num={0}
                icon={pvsIcon}
              />

              <Link
                to="all-meetings"
                component={"span"}
                style={{ textDecoration: "none", flex: "1 0 calc(25% - 20px)" }}
              >
                <InfoCard
                  text="Meeting Requests"
                  color="#004490"
                  num={meetingsData?.length}
                  icon={meetingIcon}
                />
              </Link>

              <InfoCard
                text="Mutual Connections"
                color="#539506"
                num={0}
                icon={connectionsIcon}
              />
            </div>
          </div>
          <div className="dash-card-35">
            <div className="dash-list-cont">
              <div style={{ display: "flex", padding: "12px" }}>
                <div style={{ color: "#004490", flex: 1, fontWeight: 600 }}>
                  Visitor Activity
                </div>
                <div
                  style={{
                    color: "#004490",
                    cursor: "pointer",
                    fontSize: "13px",
                    alignSelf: "center",
                  }}
                >
                  See All
                </div>
              </div>
              <div style={{ flex: 1, overflowY: "auto" }}>
                {/* {profileActivities.map((profile, i) => (
                <UserActvityItem
                  key={i}
                  imgUrl={profile.image}
                  name={profile.name}
                  action={profile.action}
                />
              ))} */}
                <div
                  style={{
                    color: "#ccc",
                    fontWeight: 600,
                    textAlign: "center",
                    margin: 16,
                  }}
                >
                  No Activity yet
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div style={{marginLeft: '10px', marginRight: '10px'}} className="rd-table-container">
                <table style={{borderCollapse: 'separate', border: 'none', borderSpacing: '0 10px'}}>
                <colgroup>
                    <col span="1" style={{width: '25%'}}/>
                    <col span="1" style={{width: '16%'}} />
                    <col span="1" style={{width: '16%'}} />
                    <col span="1" style={{width: '18%'}} />
                    <col span="1" style={{width: '25%'}} />
                </colgroup>
                    <tbody>
                    <tr>
    <th style={{backgroundColor: 'white', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px'}}>Profile</th>
    <th style={{backgroundColor: 'white'}}>Profile Views</th>
    <th style={{backgroundColor: 'white'}}>Profile Favourite</th>
    <th style={{backgroundColor: 'white'}}>Qr Scanned</th>
    <th style={{backgroundColor: 'white', borderTopRightRadius: '10px', borderBottomRightRadius: '10px'}}>Actions</th>
</tr>

{
    visitors.map((visitor, i)=><tr key={i}>
    <td style={{backgroundColor: 'white', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', border: 'none', padding: '10px'}}>
        <VisitorProfileItem profile={visitor} />
    </td>
    <td style={{backgroundColor: 'white', border: 'none', padding: '10px'}}><Checkbox checked/></td>
    <td style={{backgroundColor: 'white', border: 'none', padding: '10px'}}>{LONG_DASH}</td>
    <td style={{backgroundColor: 'white', border: 'none', padding: '10px'}}>{LONG_DASH}</td>
    <td style={{backgroundColor: 'white', borderTopRightRadius: '10px', borderBottomRightRadius: '10px', border: 'none', padding: '10px'}}>
    <div>
                    <IconButton aria-label="message" size="small">
                        <MessageIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton aria-label="message" size="small">
                        <CalendarMonthIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton onClick={markFav} aria-label="message" size="small">
                        <StarIcon fontSize="inherit" />
                    </IconButton>
                </div>
    </td>
</tr>)
}


             </tbody>
                </table>
            </div>          */}

        <div className="dash-cards-65-35">
          <div
            className="dash-card-65"
            style={{ display: "flex", gap: "0 16px" }}
          >
            {/* <div className="dash-item-flex">
            <div className="dash-list-cont">
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  margin: "16px auto 8px auto",
                }}
              >
                <DashButton
                  label="Profile Views"
                  color="rgb(47 146 188)"
                  hoverColor="rgb(21 80 106)"
                  bgColor="rgb(229 241 253)"
                  hoverBgColor="rgb(198 217 236)"
                  myStyle={{ paddingLeft: "12px", paddingRight: "12px" }}
                />
                <DashButton
                  label="Profile Favorite"
                  color="#d17a4e"
                  hoverColor="#b55c28"
                  bgColor="#ffead2"
                  hoverBgColor="#ffbca5"
                  myStyle={{ paddingLeft: "12px", paddingRight: "12px" }}
                />
                <DashButton
                  label="QR Scanned"
                  color="#fb4d24"
                  hoverColor="#f93a0b"
                  bgColor="#ffe4de"
                  hoverBgColor="#ffdad2"
                  myStyle={{ paddingLeft: "12px", paddingRight: "12px" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "12px",
                  borderTop: "0.7px solid #ccc",
                }}
              >
                <div
                  style={{
                    color: "#004490",
                    flex: 1,
                    textAlign: "center",
                    fontWeight: 500,
                  }}
                >
                  Profiles
                </div>
                <div
                  style={{
                    color: "#004490",
                    textAlign: "center",
                    flex: 1,
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Actions
                </div>
              </div>
              <div style={{ flex: 1, overflowY: "auto" }}>
                {/* {sampleProfiles.slice(0, 4).map((profile, i) => (
                  <ProfileActionItem key={i} profile={profile} />
                ))} 
                <div
                  style={{
                    color: "#ccc",
                    fontWeight: 600,
                    textAlign: "center",
                    margin: 16,
                  }}
                >
                  No Data yet
                </div>
              </div>
            </div>
          </div> */}
            <div
              style={{ backgroundColor: "white", borderRadius: "8px" }}
              className="dash-item-flex"
            >
              <Box
                sx={{
                  ml: 2,
                  mt: 2,
                  color: "#004490",
                  flex: 1,
                  fontWeight: 600,
                }}
              >
                Recommendations
              </Box>
              <SimilarExhibitors
                type={IMTEX_EXHIBITOR}
                items={similarExhibitors}
                isLoading={isSimilarLoading}
                isError={isSerror}
                onSelect={onClickItemView}
              />
            </div>
          </div>

          <div className="dash-card-35">
            <div className="dash-list-cont">
              <div style={{ display: "flex", padding: "12px" }}>
                <div style={{ color: "#004490", flex: 1, fontWeight: 600 }}>
                  Your Meetings
                </div>
                <div
                  style={{
                    color: "#004490",
                    cursor: "pointer",
                    fontSize: "13px",
                    alignSelf: "center",
                  }}
                  onClick={() => navigate(`all-meetings`)}
                >
                  See All
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  paddingBottom: "12px",
                  overflowY: "auto",
                }}
              >
                {/* <div
                  style={{
                    color: "#ccc",
                    fontWeight: 600,
                    textAlign: "center",
                    margin: 16,
                  }}
                >
                  No Meetings Yet
                </div> */}
                {isMloading && <CircularProgress />}
                {meetingsData?.slice(0, 2).map((item, i) => (
                  <MeetingCard onClick={onSelect} meetingItem={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Dashboard;
