import React, { useEffect, useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  Container,
  Paper,
  Grid,
  InputLabel,
  FormControl,
  Chip,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { styled } from "@mui/system";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import {
  DEFAULT_DP,
  EXHIBITOR_API,
  ICON_TYPE_NON_IMAGE,
} from "../../../../constants/constants";
import EditIcon from "@mui/icons-material/Edit";
import RdIconContainer from "../../../../widgets/RdIconContainer";
import MuPb from "../../../../widgets/MuPb";
import CountriesSelect from "../profile/edit/CountriesSelect";
import StatesSelect from "../profile/edit/StatesSelect";
import CitiesSelect from "../profile/edit/CitiesSelect";
import { TITLES, getExhibitorHeader } from "../../../../utility/utility";
import { mt } from "date-fns/locale";
import { toast } from "react-toastify";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import ProfileCropEdit from "../profile/ProfileCropEdit";
import Supplier2 from "./reuse/Supplier2";
import CategoriesSelectFree from "../../../../visitorregt/components/previewpage/CategoriesSelectFree";
const StyledTabs = styled(Tabs)({
  backgroundColor: "#054054", // Base color set to #054054
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  flexGrow: 1,
  ".MuiTabs-indicator": {
    backgroundColor: "#ff9800", // Tab indicator color (orange for contrast)
    height: "6px", // Increased height of the tab indicator (adjust as needed)
  },
});

const StyledTab = styled(Tab)({
  flex: 1,
  color: "white",
  "&.Mui-selected": {
    color: "white",
  },
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
});
const ExhibitionCatalogue = () => {
  const [tabValue, setTabValue] = useState(0);
  const {
    data: user,
    isLoading,
    isError,
    error,
    refetchExhibitorProfile,
  } = useExhibitorProfile();
  const [categories, setCategories] = useState();
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    companyShortName: "",
    profile_image: "",
    fascia: "",
    weAre: "",
    phone: "",
    fax: "",
    website: "",
    companyEmail: "",
    state: "",
    city: "",
    country: "",
    pin: "",
    address1: "",
    address2: "",
    address3: "",

    contactSalutation: "",
    contactFirstName: "",
    contactLastName: "",
    contactDesignation: "",
    mobile: "",
    contactEmail: "",
  });
  const [saving, setSaving] = useState(false);
  const [supplier, setSupplier] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [showImageUpload, setShowImageUpload] = useState(false);
  useEffect(() => {
    if (user) {
      let companyO = {
        companyName: user.companyName.value,
        companyShortName: user.companyShortName.value,
        fascia: user.fascia.value,
        weAre: user.weAre.value,
        phone: user.phone.value,
        fax: user.fax.value,
        website: user.website.value,
        companyEmail: user.companyEmail.value,
        country: user.country?.value?.isoCode,
        state: user.state?.value?.isoCode,
        city: user.city?.value,
        pin: user.pin.value,
        address1: user.address1.value,
        address2: user.address2.value,
        address3: user.address3.value,

        contactSalutation: user.contactSalutation.value,
        contactFirstName: user.contactFirstName.value,
        contactLastName: user.contactLastName.value,
        contactDesignation: user.contactDesignation.value,
        mobile: user.mobile.value,
        contactEmail: user.contactEmail.value,
      };
      setCategories(user.productCategories.value);
      setCompanyInfo(companyO);
    }
  }, [user]);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  if (isLoading) {
    return <MuPb />;
  }
  const updateProperty = (e) => {
    setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
  };
  const updatePropertyNamed = (property, value) => {
    setCompanyInfo({ ...companyInfo, [property]: value });
  };

  const updateCompanyInfo = async () => {
    if (!categories || (categories && categories.length === 0)) {
      toast.error("Please select categories");
      return;
    }
    const toUpdateObject = {
      companyName: {
        label: user.companyName.label,
        value: companyInfo.companyName,
      },
      companyShortName: {
        label: user.companyShortName.label,
        value: companyInfo.companyShortName,
      },
      fascia: {
        label: user.fascia.label,
        value: companyInfo.fascia,
      },
      weAre: {
        label: user.weAre.label,
        value: companyInfo.weAre,
      },
      phone: {
        label: user.phone.label,
        value: companyInfo.phone,
      },
      fax: {
        label: user.fax.label,
        value: companyInfo.fax,
      },
      website: {
        label: user.website.label,
        value: companyInfo.website,
      },
      companyEmail: {
        label: user.companyEmail.label,
        value: companyInfo.companyEmail,
      },
      country: {
        label: user.country.label,
        value: companyInfo.country,
      },
      state: {
        label: user.state.label,
        value: companyInfo.state,
      },
      city: {
        label: user.city.label,
        value: companyInfo.city,
      },
      pin: {
        label: user.pin.label,
        value: companyInfo.pin,
      },
      address1: {
        label: user.address1.label,
        value: companyInfo.address1,
      },
      address2: {
        label: user.address2.label,
        value: companyInfo.address2,
      },
      address3: {
        label: user.address3.label,
        value: companyInfo.address3,
      },

      contactSalutation: {
        label: user.contactSalutation.label,
        value: companyInfo.contactSalutation,
      },
      contactFirstName: {
        label: user.contactFirstName.label,
        value: companyInfo.contactFirstName,
      },
      contactLastName: {
        label: user.contactLastName.label,
        value: companyInfo.contactLastName,
      },
      contactDesignation: {
        label: user.contactDesignation.label,
        value: companyInfo.contactDesignation,
      },
      mobile: {
        label: user.mobile.label,
        value: companyInfo.mobile,
      },
      contactEmail: {
        label: user.contactEmail.label,
        value: companyInfo.contactEmail,
      },
      productCategories: {
        label: user.productCategories.label,
        value: categories,
      },
    };

    try {
      const { data } = await axios.post(
        `${EXHIBITOR_API}update-company-info`,
        toUpdateObject,
        getExhibitorHeader()
      );
      setSaving(false);
      toast.success(data.message);
      refetchExhibitorProfile();
    } catch (error) {
      setSaving(false);
      if (error.response) {
        // The request was made and the server responded with a status code outside the range of 2xx
        console.error("Error Data:", error.response.data);
        console.error("Error Status:", error.response.status);
        console.error("Error Headers:", error.response.headers);
        toast.error(
          `Error: ${error.response.data.message || "Invalid request"}`
        );
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error Request:", error.request);
        toast.error("No response from server. Please try again later.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message);
        toast.error("An error occurred. Please try again.");
      }
    }
  };
  const onEditProfileClick = () => {
    setShowImageUpload(true);
  };
  const onUpdated = () => {
    refetchExhibitorProfile();
  };

  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>Email Template</title> <style>body{font-family: Arial, sans-serif; background-color: #f4f4f4; margin: 0; padding: 0;}.container{max-width: 800px; margin: 0 auto; padding: 20px; background-color: #ffffff;}.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}.profile-image{width: 90px; display: block; margin: 0 auto;}table{width: 100%; border-collapse: collapse; margin-top: 20px;}th, td{padding: 10px; text-align: left; border-bottom: 1px solid #ddd;}th{background-color: #f2f2f2;}</style> </head> <body> <div class="container"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong> Company: ${
      companyInfo.companyName
    }, Name: ${companyInfo.contactSalutation}${companyInfo.contactFirstName}${
      companyInfo.contactLastName
    }, Phone: ${companyInfo.mobile}, Email: ${
      companyInfo.contactEmail
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong> Company: ${
      supplier.supplier_name
    },<br/> Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div><img src="${
      user.profile_image
    }" alt="Company Logo" class="profile-image"/> <table> <tr> <th>Attribute</th> <th>Value</th> </tr><tr> <td><strong>Company Name:</strong></td><td>${
      companyInfo.companyName
    }</td></tr><tr> <td><strong>Fascia:</strong></td><td>${
      companyInfo.fascia
    }</td></tr><tr> <td><strong>Company Short Name:</strong></td><td>${
      companyInfo.companyShortName
    }</td></tr><tr> <td><strong>Address1:</strong></td><td>${
      companyInfo.address1
    }</td></tr><tr> <td><strong>Address2:</strong></td><td>${
      companyInfo.address2
    }</td></tr><tr> <td><strong>Address3:</strong></td><td>${
      companyInfo.address3
    }</td></tr><tr> <td><strong>Country:</strong></td><td>${
      user.country?.value?.name
    }</td></tr><tr> <td><strong>State:</strong></td><td>${
      user.state?.value?.name
    }</td></tr><tr> <td><strong>City:</strong></td><td>${
      user.city?.value
    }</td></tr><tr> <td><strong>Pin:</strong></td><td>${
      companyInfo.pin
    }</td></tr><tr> <td><strong>Phone:</strong></td><td>${
      companyInfo.phone
    }</td></tr><tr> <td><strong>Fax:</strong></td><td>${
      companyInfo.fax
    }</td></tr><tr> <td><strong>Company Email:</strong></td><td>${
      companyInfo.companyEmail
    }</td></tr><tr> <td><strong>Website:</strong></td><td> <a href="${
      companyInfo.website
    }" target="_blank" >${
      companyInfo.website
    }</a > </td></tr><tr> <td><strong>Contact Name:</strong></td><td> ${
      companyInfo.contactSalutation
    }${companyInfo.contactFirstName}${
      companyInfo.contactLastName
    }</td></tr><tr> <td><strong>Contact Designation:</strong></td><td>${
      companyInfo.contactDesignation
    }</td></tr><tr> <td><strong>Direct Mobile:</strong></td><td>${
      companyInfo.mobile
    }</td></tr><tr> <td><strong>Contact Email:</strong></td><td>${
      companyInfo.contactEmail
    }</td></tr><tr> <td><strong>Product Categories:</strong></td><td>${categories.join(
      ","
    )}</td></tr></table> <img src="https://apis.expoplanner.in/images/email_footer_oes.png" style="display: block; margin: 0 auto; width: 100%"/> </div></body></html>`;
    const toSubmitData = {
      template,
      name: "Exhibition Catalogue",
      emails: [
        user.companyEmail.value,
        supplier.email_id,
        "christopher@imtma.in",
        "info@imtex.in",
      ],
      title: "Exhibition Catalogue",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };

  return (
    <Container sx={{ ml: "auto", maxWidth: "1200px", mt: 1, mr: "auto" }}>
      <RdMyModal
        showModal={showImageUpload}
        setShowModal={setShowImageUpload}
        modalC={
          <ProfileCropEdit
            show={showImageUpload}
            setShow={setShowImageUpload}
            upload_type="profile_image"
            onUpdated={onUpdated}
          />
        }
      />
      <Paper sx={{ padding: "2rem", mb: 2 }}>
        <StyledTabs
          value={tabValue}
          onChange={handleTabChange}
          variant="fullWidth"
        >
          <StyledTab label="Company Details" />
          <StyledTab label="Contact Details" />
        </StyledTabs>

        {tabValue === 0 && (
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  name="companyName"
                  label={user.companyName.label}
                  value={companyInfo.companyName}
                  onChange={updateProperty}
                  size="small"
                />
                <TextField
                  fullWidth
                  label={user.fascia.label}
                  value={companyInfo.fascia}
                  name="fascia"
                  onChange={updateProperty}
                  style={{ marginTop: "1rem" }}
                  size="small"
                />
                <TextField
                  fullWidth
                  label={user.companyShortName.label}
                  value={companyInfo.companyShortName}
                  onChange={updateProperty}
                  style={{ marginTop: "1rem" }}
                  size="small"
                  name="companyShortName"
                />
                <TextField
                  fullWidth
                  label={user.address1.label}
                  value={companyInfo.address1}
                  name="address1"
                  onChange={updateProperty}
                  style={{ marginTop: "1rem" }}
                  size="small"
                />
                <TextField
                  fullWidth
                  name="address2"
                  label={user.address2.label}
                  value={companyInfo.address2}
                  onChange={updateProperty}
                  style={{ marginTop: "1rem" }}
                  size="small"
                />
                <TextField
                  name="address3"
                  fullWidth
                  label={user.address3.label}
                  value={companyInfo.address3}
                  onChange={updateProperty}
                  sx={{ marginTop: "1rem", mb: 2 }}
                  size="small"
                />

                <CountriesSelect
                  state={companyInfo}
                  handleStateChange={updatePropertyNamed}
                />

                <Box mt={2}>
                  <StatesSelect
                    state={companyInfo}
                    handleStateChange={updatePropertyNamed}
                  />
                </Box>
                <Box mt={2}>
                  <CitiesSelect
                    state={companyInfo}
                    handleStateChange={updatePropertyNamed}
                  />
                </Box>

                <Box mt={2}>
                  <Typography variant="h6" gutterBottom>
                    Product Categories
                  </Typography>
                  {categories && (
                    <CategoriesSelectFree
                      categoriesA={categories}
                      setCategoriesA={setCategories}
                    />
                  )}
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box
                  sx={{ mt: 1.7, marginBottom: "2rem", textAlign: "center" }}
                >
                  <div
                    style={{
                      position: "relative",
                      display: "table",
                      margin: "auto",
                    }}
                  >
                    <img
                      src={user.profile_image ? user.profile_image : DEFAULT_DP}
                      alt="profile"
                      style={{
                        width: "90px",
                        height: "90px",
                        borderRadius: "50%",
                        border: "1px solid #b68337",
                        cursor: "pointer",
                      }}
                    />

                    <RdIconContainer
                      icon={{
                        icon: <EditIcon sx={{ color: "#777" }} />,
                        type: ICON_TYPE_NON_IMAGE,
                        desc: "edit icon",
                        show: true,
                      }}
                      bg="transparent"
                      myStyle={{ position: "absolute", top: 0, right: "-36px" }}
                      onClick={onEditProfileClick}
                    />
                  </div>
                  <Box>
                    <Typography variant="subtitle1">Company Logo</Typography>
                    <Typography variant="body2">
                      Supported formats: PNG, JPG, JPEG
                    </Typography>
                    <Typography variant="body2">Aspect Ratio: 1:1</Typography>
                  </Box>
                </Box>
                <TextField
                  fullWidth
                  name="pin"
                  label={user.pin.label}
                  value={companyInfo.pin}
                  onChange={updateProperty}
                  style={{ marginTop: "1rem" }}
                  size="small"
                />
                <TextField
                  fullWidth
                  name="phone"
                  label={user.phone.label}
                  value={companyInfo.phone}
                  sx={{ marginTop: "1rem" }}
                  onChange={updateProperty}
                  size="small"
                />
                <TextField
                  fullWidth
                  required
                  name="companyEmail"
                  label={user.companyEmail.label}
                  value={companyInfo.companyEmail}
                  onChange={updateProperty}
                  style={{ marginTop: "1rem" }}
                  size="small"
                />
                <TextField
                  fullWidth
                  name="fax"
                  label={user.fax.label}
                  value={companyInfo.fax}
                  onChange={updateProperty}
                  style={{ marginTop: "1rem" }}
                  size="small"
                />
                <TextField
                  fullWidth
                  name="website"
                  label={user.website.label}
                  value={companyInfo.website}
                  onChange={updateProperty}
                  style={{ marginTop: "1rem" }}
                  size="small"
                />
                <Box mt={2}>
                  <Typography variant="h6" gutterBottom>
                    Selected Categories
                  </Typography>
                  {categories &&
                    categories.map((category, i) => (
                      <Chip
                        key={i}
                        label={category}
                        sx={{
                          mr: 1,
                          mb: 1,
                        }}
                        // onDelete={() => {}}
                        // deleteIcon={<CloseIcon />}
                      />
                    ))}
                </Box>
                {/* <TextField
                  fullWidth
                  label="Social Links"
                  defaultValue="https://www.facebook.com/IndianMachineToolManufacturersAssociation"
                  style={{ marginTop: "1rem" }}
                  size="small"
                /> */}
                {/* Add more social link fields as needed */}
              </Grid>
            </Grid>
            <Box mt={3} display="flex" justifyContent="center">
              <LoadingButton
                loading={saving}
                onClick={updateCompanyInfo}
                variant="contained"
                color="primary"
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        )}

        {tabValue === 1 && (
          <Box mt={3} maxWidth={500}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                {user.contactSalutation.label}*
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={companyInfo.contactSalutation}
                name="contactSalutation"
                label={user.contactSalutation.label}
                onChange={updateProperty}
              >
                {TITLES.map((position, i) => (
                  <MenuItem key={i} value={position}>
                    {position}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              size="small"
              label={user.contactFirstName.label}
              name="contactFirstName"
              value={companyInfo.contactFirstName}
              onChange={updateProperty}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              size="small"
              label={user.contactLastName.label}
              name="contactLastName"
              value={companyInfo.contactLastName}
              onChange={updateProperty}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              size="small"
              label={user.contactDesignation.label}
              name="contactDesignation"
              value={companyInfo.contactDesignation}
              onChange={updateProperty}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              size="small"
              label={user.mobile.label}
              name="mobile"
              value={companyInfo.mobile}
              onChange={updateProperty}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              size="small"
              label={user.contactEmail.label}
              name="contactEmail"
              value={companyInfo.contactEmail}
              onChange={updateProperty}
            />
            <Box mt={3} display="flex" justifyContent="center">
              <LoadingButton
                loading={saving}
                onClick={updateCompanyInfo}
                variant="contained"
                color="primary"
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        )}

        <Supplier2
          user={user}
          id={100}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        <Box mt={2}>
          <LoadingButton
            disabled={!supplier}
            loading={submitting}
            onClick={onSubmit}
            variant="contained"
            color="primary"
            style={{ marginRight: "10px" }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Paper>
    </Container>
  );
};

export default ExhibitionCatalogue;
