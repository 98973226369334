import { useQuery } from "react-query";
import axios from "axios";
import { getExhibitorHeader } from "../../../../../utility/utility";
import { EXHIBITOR_API } from "../../../../../constants/constants";
const fetchItem = (username, title) => {
  return axios
    .get(
      `${EXHIBITOR_API}oes-submitted-status?username=${username}&title=${title}`,
      getExhibitorHeader()
    )
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useOesSubmitted = (username, title) => {
  return useQuery(
    ["oes-submitted-status", username, title],
    () => fetchItem(username, title),
    {
      staleTime: 0, // Data will be considered stale after 5 minutes
      retry: 3,
    }
  );
};

export default useOesSubmitted;
