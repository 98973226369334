import React from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MessageIcon from "@mui/icons-material/Message";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  DEFAULT_DP,
  HEADER_RIGHT_MESSAGE,
  HEADER_RIGHT_MODE_SETTINGS,
  HEADER_RIGHT_NOTIFICATION,
  HEADER_RIGHT_PROFILE,
  ICON_TYPE_IMAGE,
  ICON_TYPE_NON_IMAGE,
} from "../../../constants/constants";
import RdIconContainer from "../../../widgets/RdIconContainer";
import "./DashHeader.css";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import imtexLogo from "../../../assets/imtexLogo2.png";
const CommonHeader = ({ user }) => {
  const navigate = useNavigate();
  const rightIcons = {
    [HEADER_RIGHT_MODE_SETTINGS]: {
      icon: <Brightness4Icon />,
      type: ICON_TYPE_NON_IMAGE,
      desc: "settings",
      show: true,
    },
    [HEADER_RIGHT_NOTIFICATION]: {
      icon: <NotificationsNoneIcon />,
      type: ICON_TYPE_NON_IMAGE,
      desc: "notification",
      show: true,
    },
    [HEADER_RIGHT_MESSAGE]: {
      icon: <MessageIcon />,
      type: ICON_TYPE_NON_IMAGE,
      desc: "message",
      show: true,
    },
    [HEADER_RIGHT_PROFILE]: {
      icon: user?.profile_image ? user.profile_image : DEFAULT_DP,
      type: ICON_TYPE_IMAGE,
      desc: "account icon",
      show: true,
    },
  };

  const onNotifyClick = () => {};
  const onMessageClick = () => {};
  const onProfileClick = () => {
    navigate("/exhibitor");
  };
  const onMenuClick = () => {
    navigate("/exhibitor");
  };

  return (
    <div className="expp-dash-header-c">
      <div className="hd-1-item">
        <img src={imtexLogo} alt="Imtex Logo" style={{ marginLeft: "16px" }} />
      </div>
      <div className="oes-menu-btn">
        <IconButton
          onClick={onMenuClick}
          color="primary"
          aria-label="menu"
          size="large"
          sx={{ ml: 4 }}
        >
          <HomeIcon fontSize="inherhit" />
        </IconButton>
      </div>
      <div style={{ flex: 1 }}></div>
      <div className="hd-2-item">
        {/* <RdIconContainer
          icon={rightIcons[HEADER_RIGHT_MODE_SETTINGS]}
          onClick={onNotifyClick}
        /> */}
        <RdIconContainer
          icon={rightIcons[HEADER_RIGHT_NOTIFICATION]}
          onClick={onNotifyClick}
        />
        <RdIconContainer
          icon={rightIcons[HEADER_RIGHT_MESSAGE]}
          onClick={onMessageClick}
        />
        <RdIconContainer
          icon={rightIcons[HEADER_RIGHT_PROFILE]}
          onClick={onProfileClick}
        />
      </div>
    </div>
  );
};

export default CommonHeader;
