import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import HeaderLabel from "../../../../../widgets/HeaderLabel";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import {
  EXHIBITOR_API,
  MODULES,
  VISITOR_API,
} from "../../../../../constants/constants";
import { getExhibitorHeader } from "../../../../../utility/utility";
import axios from "axios";
import { StyledReactQuill, StyledReactQuill192 } from "../../AddProduct";

const EditAbout = ({ onUpdated, user, show, setShow }) => {
  const [editorHtml, setEditorHtml] = useState("");
  const [loading, setLoading] = useState(false);
  function handleChange(html) {
    setEditorHtml(html);
  }
  useEffect(() => {
    if (user.about) {
      setEditorHtml(user.about.value);
    }
  }, [show]);
  const handleUpdate = async () => {
    setLoading(true);
    let data = {
      about: {
        label: "About",
        value: editorHtml,
      },
    };
    try {
      const response = await axios.post(
        `${EXHIBITOR_API}update-exhibitor`,
        data,
        getExhibitorHeader()
      );
      setLoading(false);
      switch (response.status) {
        case 200: // OK
          toast.success("Updated successfully");
          onUpdated();
          setShow(false);
          break;
        case 304: // Not Modified
          toast.info("Nothing to update");
          setShow(false);
          break;
        default:
          console.log("Unexpected status code:", response.status);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Oops! some error occured");
    }
  };
  function isContentEmpty() {
    const strippedContent = editorHtml.replace(/<[^>]*>/g, "").trim();
    return !strippedContent;
  }
  const save = () => {
    if (isContentEmpty()) {
      toast.info("Please type something first");
    } else {
      handleUpdate();
    }
  };

  return (
    <div style={{ padding: "16px" }}>
      <HeaderLabel label={"Edit About"} showEdit={false} />
      <StyledReactQuill192
        modules={MODULES}
        value={editorHtml}
        onChange={handleChange}
      />
      <Box display="flex" justifyContent="flex-end">
        <LoadingButton loading={loading} onClick={save} variant="contained">
          Save
        </LoadingButton>
      </Box>
    </div>
  );
};

export default EditAbout;
