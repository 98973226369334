import { useQuery } from "react-query";
import axios from "axios";
import { getCommonHeaderVE } from "../../../../utility/utility";
import { INTERACTION_API } from "../../../../constants/constants";

const fetchItem = (id) => {
  return axios
    .get(`${INTERACTION_API}get-meeting?id=${id}`, getCommonHeaderVE())
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useMeeting = (id) => {
  return useQuery(["get-meeting", id], () => fetchItem(id), {
    staleTime: 5 * 60 * 1000, // Data will be considered stale after 5 minutes
    retry: 1, // If the query fails, it will retry up to 3 times
  });
};

export default useMeeting;
