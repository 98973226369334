import React, { useState } from "react";
import MuPb from "../../../../../widgets/MuPb";
import { Alert, Box, Grid, Pagination, Typography } from "@mui/material";
import SearchBar from "../../../../../widgets/searchBar";
import { DEFAULT_DP } from "../../../../../constants/constants";
import { Link } from "react-router-dom";
import GetAppIcon from "@mui/icons-material/GetApp";
import { LoadingButton } from "@mui/lab";
import useExhibitors from "../../hooks/useExhibitors";
import { useAllExhibitors } from "../../hooks/useAllExhibitors";
import Header from "../header";
import BackBtn from "../../widgets/BackBtn";

const Exhibitors = () => {
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, error } = useExhibitors(page); // Fetch visitors for page 1
  const {
    allExhibitors,
    loadingExhibitors,
    exhibitorsFetchError,
    errorDetails,
    refetchAllExhibitors,
  } = useAllExhibitors();
  if (isLoading) return <MuPb />;
  if (isError) return <Alert severity="error">Error: {error.message}</Alert>;
  const pageChange = (e, page) => {
    setPage(page);
  };
  const handleExport = () => {
    refetchAllExhibitors();
  };
  return (
    <Box>
      <Header />
      <Box sx={{ ml: "auto", mr: "auto", maxWidth: "1280px", mt: 2 }}>
        <BackBtn />
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={8} md={5}>
            <Box display="flex" gap={2} alignItems="center">
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                All Exhibitors(
                <span style={{ fontWeight: "bold" }}>{data.totalItems}</span>)
              </Typography>
              <LoadingButton
                sx={{
                  backgroundColor: "#054059",
                  "&:hover": {
                    backgroundColor: "#042D45", // A darker shade for hover
                  },
                }}
                loading={loadingExhibitors}
                startIcon={<GetAppIcon />}
                variant="contained"
                color="primary"
                onClick={handleExport}
              >
                Export
              </LoadingButton>
              <LoadingButton
                sx={{
                  backgroundColor: "#054059",
                  "&:hover": {
                    backgroundColor: "#042D45", // A darker shade for hover
                  },
                }}
                startIcon={<GetAppIcon />}
                variant="contained"
                color="primary"
              >
                Update
              </LoadingButton>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <SearchBar
              label="Search Exhibitors"
              cstyle={{ margin: "0 auto" }}
              mystyle={{ borderRadius: 0 }}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <Box display="flex" justifyContent="flex-end">
              <Pagination
                page={page}
                onChange={pageChange}
                count={Math.ceil(data.totalItems / 30)}
              />
            </Box>
          </Grid>
        </Grid>
        <table style={{ width: "100%" }} className="expo-table">
          <thead>
            <tr>
              <th>Photo</th>
              <th>Name</th>
              <th>Designation</th>
              <th>Company</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Fascia</th>
              <th>WeAre</th>
              <th>Industry/Sector</th>
            </tr>
          </thead>
          <tbody>
            {data.data.map((item, i) => (
              <tr key={i}>
                <td data-label="Image">
                  <img
                    src={item.profile_image ? item.profile_image : DEFAULT_DP}
                    alt={`${item.firstName} Logo`}
                  />
                </td>
                <td data-label="Name">
                  <Link to={`/admin/exhibitor/${item.exhibitorId}`}>
                    {item.contactSalutation.value} {item.contactFirstName.value}{" "}
                    {item.contactLastName.value}
                  </Link>
                </td>
                <td data-label={item.contactDesignation.label}>
                  {item.contactDesignation.value}
                </td>
                <td data-label={item.companyName.label}>
                  {item.companyName.value}
                </td>
                <td data-label={item.contactEmail.label}>
                  {item.contactEmail.value}
                </td>
                <td data-label={item.mobile.label}>{`${item.mobile.value}`}</td>
                <td data-label={item.fascia.label}>{item.fascia.value}</td>
                <td data-label={item.weAre.label}>{item.weAre.value}</td>
                <td data-label={item.industrySector.label}>
                  {item.industrySector.label}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default Exhibitors;
