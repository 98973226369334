import React, { useEffect, useState } from "react";
import {
  DEFAULT_DP,
  EXHIBITOR_API,
  ICON_TYPE_NON_IMAGE,
} from "../../../constants/constants";
import RdIconContainer from "../../../widgets/RdIconContainer";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import RdLoadingButton from "../../../widgets/RdLoadingButton";
import axios from "axios";
import { getExhibitorHeader } from "../../../utility/utility";
import { toast } from "react-toastify";
import MyPhoneInput from "../../../widgets/MyPhoneInput";
import RdMyModal from "../../../widgets/myModal/RdMyModal";
import ProductImageCrop from "./ProductImageCrop";
import DesignationsSelect from "../../../widgets/DesignationSelect";

const AddTeamMember = ({
  onAdded,
  member = null,
  show,
  setShow,
  mode = "add",
}) => {
  const [showImageCrop, setShowImageCrop] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [adding, setAdding] = useState(false);
  const onEditImageClick = () => {
    setShowImageCrop(true);
  };
  useEffect(() => {
    if (member !== null) {
      setName(member.firstName);
      setLastName(member.lastName);
      setPhoneNumber(member.phone);
      setProfileImage(member.profile_image);
      setEmail(member.email);
      setRole(member.role);
      setJobTitle(member.jobTitle);
    } else {
      setName("");
      setLastName("");
      setPhoneNumber("");
      setProfileImage("");
      setEmail("");
      setRole("");
      setJobTitle("");
    }
  }, [show]);
  const onSubmit = async () => {
    if (!name.trim()) {
      toast.error("Please enter a valid name.");
      return;
    }
    if (!lastName.trim()) {
      toast.error("Please enter a valid last name.");
      return;
    }
    if (!isValidPhoneNumber(phoneNumber)) {
      toast.error("Please enter a valid phone number.");
      return;
    }
    if (!email.trim() || !/^\S+@\S+\.\S+$/.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    if (!role) {
      toast.error("Please select a role.");
      return;
    }
    if (!jobTitle.trim()) {
      toast.error("Please enter a valid job title.");
      return;
    }
    const teamMember = {
      firstName: name,
      lastName: lastName,
      phone: phoneNumber,
      email: email,
      role: role,
      jobTitle: jobTitle,
      profile_image: profileImage,
    };
    setAdding(true);
    const url = `${EXHIBITOR_API}${
      mode === "add" ? "add-team-member" : `edit-team-member?id=${member._id}`
    }`;
    try {
      const { data } = await axios.post(url, teamMember, getExhibitorHeader());
      onAdded();
      setAdding(false);
      toast.success(data.message);
    } catch (error) {
      setAdding(false);
      toast.error(error.message);
    }
  };
  const onCancel = () => {
    setShow((prev) => !prev);
  };
  const onImageAdded = (imgUrl) => {
    setProfileImage(imgUrl);
  };
  return (
    <Box sx={{ pt: 1, pb: 1, pl: 2, pr: 2 }}>
      <RdMyModal
        showModal={showImageCrop}
        setShowModal={setShowImageCrop}
        modalC={
          <ProductImageCrop
            show={showImageCrop}
            onUpdated={onImageAdded}
            setShow={setShowImageCrop}
          />
        }
      />
      <div
        style={{
          fontWeight: 600,
          fontSize: "20px",
          color: "#444",
          margin: "16px 0",
        }}
      >
        Add Team Member
      </div>
      <div style={{ display: "flex", padding: "12px" }}>
        <div style={{ flex: 0.4 }}>
          <div
            style={{ position: "relative", display: "table", margin: "auto" }}
          >
            <img
              src={profileImage === "" ? DEFAULT_DP : profileImage}
              alt="profile"
              style={{
                width: "90px",
                height: "90px",
                borderRadius: "50%",
                border: "1px solid #b68337",
                cursor: "pointer",
              }}
            />

            <RdIconContainer
              icon={{
                icon: <EditIcon sx={{ color: "#777" }} />,
                type: ICON_TYPE_NON_IMAGE,
                desc: "edit icon",
                show: true,
              }}
              bg="transparent"
              myStyle={{ position: "absolute", top: 0, right: "-36px" }}
              onClick={onEditImageClick}
            />
          </div>
        </div>
        <div style={{ flex: 0.6 }}>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            size="small"
            fullWidth
            label="Name*"
          />
          <TextField
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            sx={{ marginTop: "16px" }}
            variant="outlined"
            size="small"
            fullWidth
            label="Last Name*"
          />
          <div style={{ marginTop: "16px" }}>
            <MyPhoneInput
              label="Phone Number*"
              phone={phoneNumber}
              setPhone={setPhoneNumber}
            />
          </div>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ marginTop: "16px" }}
            variant="outlined"
            size="small"
            fullWidth
            label="Email*"
          />
          <FormControl fullWidth sx={{ marginTop: "16px" }} size="small">
            <InputLabel id="demo-simple-select-label">Role*</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="role"
              label="Role*"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <MenuItem value="member">Member</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>

          <Box mt={2}>
            <DesignationsSelect
              designation={jobTitle}
              setDesignation={setJobTitle}
            />
          </Box>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <RdLoadingButton
          onClick={onCancel}
          bgColor="rgb(121 118 118)"
          bgHoverColor="rgb(156 156 156)"
          label="Cancel"
          btnWidth="150px"
        />
        <RdLoadingButton
          loading={adding}
          onClick={onSubmit}
          label="Submit"
          btnWidth="150px"
        />
      </div>
    </Box>
  );
};

export default AddTeamMember;
