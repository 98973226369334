import { useQuery } from "react-query";
import axios from "axios";
import { ADMIN_API, API_ENDPOINT } from "../../../constants/constants";
import { getAdminHeader } from "../../../utility/utility";

const fetchItems = (id) => {
  return axios
    .get(`${API_ENDPOINT}floorplan/get-stands?id=${id}`)
    .then((res) => res.data)
    .catch((error) => {
      if (error.response) {
        switch (error.response.status) {
          case 404:
            throw new Error("Not Found");
          case 500:
            throw new Error("Internal Server Error");
          default:
            throw new Error(error.response.data);
        }
      } else {
        // If there's no error.response, it might be a network issue or something else
        throw error;
      }
    });
};

export const useHallPlan = (id) => {
  const {
    data,
    error,
    isError,
    isLoading,
    refetch: refetchHallPlans,
  } = useQuery(["get-floor-hall", id], () => fetchItems(id), {
    enabled: false,
    staleTime: 5 * 60 * 1000, // Data will be considered stale after 5 minutes
    retry: 0, // If the query fails, it won't retry
  });

  return { data, error, isError, isLoading, refetchHallPlans };
};

export default useHallPlan;
