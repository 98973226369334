import { useQuery } from "react-query";
import axios from "axios";
import { getAdminHeader } from "../../../../utility/utility";
import { ADMIN_API } from "../../../../constants/constants";

const fetchItems = (page = 1, searchQ) => {
  return axios
    .get(
      `${ADMIN_API}/get-visitors?page=${page}&sq=${encodeURIComponent(
        searchQ
      )}`,
      getAdminHeader()
    )
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useVisitors = (page, searchQ) => {
  const queryInfo = useQuery(
    ["get-visitors", page, searchQ],
    () => fetchItems(page, searchQ),
    {
      staleTime: 5 * 60 * 1000, // Data will be considered stale after 5 minutes
      retry: 3, // If the query fails, it will retry up to 3 times
    }
  );
  return {
    ...queryInfo,
    refetchVisitors: queryInfo.refetch, // Extract refetch function from queryInfo
  };
};

export default useVisitors;
