import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RdDesignationsSelect from "../../../../../visitorregt/components/previewpage/RdDesignationsSelect";
import PhoneInput from "react-phone-number-input";
import {
  TITLES,
  getExhibitorHeader,
  getHeader,
} from "../../../../../utility/utility";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { isValidEmail } from "../../../../../visitorregt/utils/utils";
import axios from "axios";
import { EXHIBITOR_API, VISITOR_API } from "../../../../../constants/constants";
import CountriesCom from "../../../../../visitorregt/components/previewpage/CountriesCom";
import StatesCom from "../../../../../visitorregt/components/previewpage/StatesCom";
import CitiesCom from "../../../../../visitorregt/components/previewpage/CitiesCom";
import StatesSelect from "./StatesSelect";
import CitiesSelect from "./CitiesSelect";
import CountriesSelect from "./CountriesSelect";

const CompanyInfoEdit = ({ user, onUpdated, show, setShow }) => {
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    companyShortName: "",
    fascia: "",
    weAre: "",
    phone: "",
    fax: "",
    website: "",
    companyEmail: "",
    state: "",
    city: "",
    country: "",
    pin: "",
    address1: "",
    address2: "",
    address3: "",
  });
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    let companyO = {
      companyName: user.companyName.value,
      companyShortName: user.companyShortName.value,
      fascia: user.fascia.value,
      weAre: user.weAre.value,
      phone: user.phone.value,
      fax: user.fax.value,
      website: user.website.value,
      companyEmail: user.companyEmail.value,
      country: user.country?.value?.isoCode,
      state: user.state?.value?.isoCode,
      city: user.city?.value,
      pin: user.pin.value,
      address1: user.address1.value,
      address2: user.address2.value,
      address3: user.address3.value,
    };
    setCompanyInfo(companyO);
  }, [show]);
  const updateProperty = (e) => {
    setCompanyInfo({ ...companyInfo, [e.target.name]: e.target.value });
  };
  const updatePropertyNamed = (property, value) => {
    setCompanyInfo({ ...companyInfo, [property]: value });
  };
  const update = async () => {
    const toUpdateObject = {
      companyName: {
        label: user.companyName.label,
        value: companyInfo.companyName,
      },
      companyShortName: {
        label: user.companyShortName.label,
        value: companyInfo.companyShortName,
      },
      fascia: {
        label: user.fascia.label,
        value: companyInfo.fascia,
      },
      weAre: {
        label: user.weAre.label,
        value: companyInfo.weAre,
      },
      phone: {
        label: user.phone.label,
        value: companyInfo.phone,
      },
      fax: {
        label: user.fax.label,
        value: companyInfo.fax,
      },
      website: {
        label: user.website.label,
        value: companyInfo.website,
      },
      companyEmail: {
        label: user.companyEmail.label,
        value: companyInfo.companyEmail,
      },
      country: {
        label: user.country.label,
        value: companyInfo.country,
      },
      state: {
        label: user.state.label,
        value: companyInfo.state,
      },
      city: {
        label: user.city.label,
        value: companyInfo.city,
      },
      pin: {
        label: user.pin.label,
        value: companyInfo.pin,
      },
      address1: {
        label: user.address1.label,
        value: companyInfo.address1,
      },
      address2: {
        label: user.address2.label,
        value: companyInfo.address2,
      },
      address3: {
        label: user.address3.label,
        value: companyInfo.address3,
      },
    };
    try {
      const { data } = await axios.post(
        `${EXHIBITOR_API}update-company-info`,
        toUpdateObject,
        getExhibitorHeader()
      );
      setSaving(false);
      setShow(false);
      toast.success(data.message);
      onUpdated();
    } catch (error) {
      setSaving(false);
      if (error.response) {
        // The request was made and the server responded with a status code outside the range of 2xx
        console.error("Error Data:", error.response.data);
        console.error("Error Status:", error.response.status);
        console.error("Error Headers:", error.response.headers);
        toast.error(
          `Error: ${error.response.data.message || "Something went wrong"}`
        );
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error Request:", error.request);
        toast.error("No response from server. Please try again later.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message);
        toast.error("An error occurred. Please try again.");
      }
    }
  };
  return (
    <Container>
      <Typography
        mb={2}
        fontSize={18}
        color={"#444"}
        fontWeight={600}
        variant="body1"
      >
        Update Company Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.companyName.label}
            name="companyName"
            value={companyInfo.companyName}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.companyShortName.label}
            name="companyShortName"
            value={companyInfo.companyShortName}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.fascia.label}
            name="fascia"
            value={companyInfo.fascia}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.weAre.label}
            name="weAre"
            value={companyInfo.weAre}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.phone.label}
            name="phone"
            value={companyInfo.phone}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.fax.label}
            name="fax"
            value={companyInfo.fax}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.website.label}
            name="website"
            value={companyInfo.website}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.companyEmail.label}
            name="companyEmail"
            value={companyInfo.companyEmail}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <CountriesSelect
            state={companyInfo}
            handleStateChange={updatePropertyNamed}
          />
        </Grid>
        <Grid item xs={6}>
          <StatesSelect
            state={companyInfo}
            handleStateChange={updatePropertyNamed}
          />
        </Grid>
        <Grid item xs={6}>
          <CitiesSelect
            state={companyInfo}
            handleStateChange={updatePropertyNamed}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.pin.label}
            name="pin"
            value={companyInfo.pin}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.address1.label}
            name="address1"
            value={companyInfo.address1}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.address2.label}
            name="address2"
            value={companyInfo.address2}
            onChange={updateProperty}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label={user.address3.label}
            name="address3"
            value={companyInfo.address3}
            onChange={updateProperty}
          />
        </Grid>
      </Grid>
      <LoadingButton
        loading={saving}
        onClick={update}
        sx={{
          mt: 4,
          ml: "auto",
          mr: "auto",
          display: "block",
          mb: 2,
          width: 120,
        }}
        variant="contained"
      >
        Save
      </LoadingButton>
    </Container>
  );
};

export default CompanyInfoEdit;
