import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import { API_ENDPOINT, EXHIBITOR_API } from "../../../constants/constants";
import {
  getCommonHeaderMultipartVE,
  getExhibitorHeader,
} from "../../../utility/utility";
import { toast } from "react-toastify";

function HelpdeskPopup({ open, setOpen }) {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setMessage("");
    setSelectedFile(null);
  };

  const handleMessageChange = (event) => setMessage(event.target.value);
  const handleFileChange = async (event) => {
    const imageFile = event.target.files[0];
    setSelectedFile(event.target.files[0]);
    const fd = new FormData();
    fd.append("image", imageFile);
    setUploading(true);
    try {
      const { data } = await axios.post(
        API_ENDPOINT + "uploads/upload-image-file",
        fd,
        getCommonHeaderMultipartVE()
      );
      setImageUrl(data.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUploading(false);
    }
  };

  const handleSend = async () => {
    if (subject === "") {
      toast.warning("Subject is required");
    } else if (message.trim() === "") {
      toast.warning("Please type something!");
    } else if (imageUrl === "") {
      toast.warning("Please add image");
    } else {
      setLoading(true);
      const dataToSend = {
        subject,
        message,
        imageUrl,
      };
      try {
        const { data } = await axios.post(
          `${EXHIBITOR_API}issue-helpdesk`,
          dataToSend,
          getExhibitorHeader()
        );
        toast.success(data.message);
        setSubject("");
        setMessage("");
        setSelectedFile(null);
        setImageUrl("");
        setOpen(true);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
      PaperProps={{ style: { width: "100%" } }}
    >
      <DialogTitle>Helpdesk</DialogTitle>
      <DialogContent>
        <Box pb={2}>
          <TextField
            autoFocus
            size="small"
            label="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          />
          <TextField
            margin="dense"
            id="message"
            label="What is your query?"
            type="text"
            fullWidth
            variant="outlined"
            value={message}
            onChange={handleMessageChange}
            multiline
            rows={4}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file"
            type="file"
            onChange={handleFileChange}
          />

          <label htmlFor="raised-button-file">
            {uploading ? (
              <CircularProgress />
            ) : (
              <IconButton
                color="primary"
                aria-label="upload file"
                component="span"
              >
                <AttachFileIcon />
              </IconButton>
            )}
          </label>
          {selectedFile && <Box ml={2}>{selectedFile.name}</Box>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSend} color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HelpdeskPopup;
