import { useQuery } from "react-query";
import axios from "axios";
import {
  getCommonHeaderVE,
  getExhibitorHeader,
} from "../../../../utility/utility";
import { EXHIBITOR_API } from "../../../../constants/constants";
const fetchItems = (page = 1, id) => {
  let url = `${EXHIBITOR_API}get-team-members?page=${page}`;
  if (id) {
    url = `${EXHIBITOR_API}get-team-members-by-id?page=${page}&id=${id}`;
  }

  return axios
    .get(url, id ? getCommonHeaderVE() : getExhibitorHeader())
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const useTeamMembers = (page, id) => {
  const queryInfo = useQuery(
    ["get-team-members", page, id],
    () => fetchItems(page, id),
    {
      staleTime: 0,
      retry: 3,
    }
  );

  return {
    ...queryInfo,
    refetchMembers: queryInfo.refetch, // Extract refetch function from queryInfo
  };
};

export default useTeamMembers;
