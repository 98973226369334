import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Box,
  Typography,
  Chip,
  Divider,
  Paper,
  Autocomplete,
  Checkbox,
  ThemeProvider,
  Alert,
  duration,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  getCommonHeaderVE,
  getExhibitorHeader,
  getHeader,
  useQuery,
} from "../../../../utility/utility";
import BackBtn from "../../admin/widgets/BackBtn";
import DoneIcon from "@mui/icons-material/Done";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ExpoTheme } from "../../../../theme/theme";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import useProfile from "./hooks/useProfile";
import MuPb from "../../../../widgets/MuPb";
import {
  EXHIBITOR,
  IMTEX_EXHIBITOR,
  INTERACTION_API,
} from "../../../../constants/constants";
import useMembers from "./hooks/useMembers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { toast } from "react-toastify";
import axios from "axios";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
function MeetingScheduler() {
  const query = useQuery();
  const ownerType = query.get("type");
  const uid = query.get("uid");
  const tid = query.get("tid");
  const tType = query.get("tType");
  const { data, isLoading, isError, error } = useProfile(uid, ownerType);
  const {
    data: targetData,
    isLoading: tIsLoading,
    isError: tIsError,
    error: terror,
  } = useProfile(tid, tType);
  const {
    data: members,
    isLoading: isMloading,
    isError: ismError,
    error: merror,
    refetchMembers,
  } = useMembers(1, ownerType === IMTEX_EXHIBITOR ? uid : tid);

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [requesting, setRequesting] = useState(false);
  const [meeting_type, setMeetingType] = useState("");
  const [duration, setDuration] = useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [membersData, setMembersData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date("2024-01-19T10:00:00")
  );
  // Define the minimum and maximum date and times
  const minDateTime = new Date("2024-01-19T10:00:00");
  const maxDateTime = new Date("2024-01-23T17:00:00");
  useEffect(() => {
    if (targetData && data) {
      if (ownerType === IMTEX_EXHIBITOR) {
        if (members) {
          const ms = [...members.data];
          const exhO = data;
          const mainContact = {
            id: uid,
            name: `${exhO.contactFirstName.value} ${exhO.contactLastName.value}`,
            profile_image: exhO.profile_image,
            email: exhO.companyEmail.value,
          };
          setSelectedMembers([mainContact]);
          const msA = [mainContact];
          ms.map((m) => {
            msA.push({
              id: m._id,
              name: `${m.firstName} ${m.lastName}`,
              email: m.email,
              profile_image: m.profile_image,
            });
          });
          setMembersData(msA);
        }
      } else {
        const mainContact = {
          id: data.email,
          name: `${data.firstName} ${data.lastName}`,
          profile_image: data.profile_image,
          email: tType,
        };
        setSelectedMembers([mainContact]);
      }
    }
  }, [members, targetData, data]);

  const handleMembersChange = (event, newValue) => {
    // newValue is the array of selected options
    setSelectedMembers(newValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedMembers.length) {
      toast.error("Please add at least one member.");
      return; // Stop the execution if this condition fails
    }
    if (!meeting_type) {
      toast.error("Please select a meeting type.");
      return; // Stop the execution if this condition fails
    }
    if (!duration) {
      toast.error("Please select the duration of the meeting.");
      return; // Stop the execution if this condition fails
    }
    if (!subject.trim()) {
      toast.error("Please enter a subject.");
      return; // Stop the execution if this condition fails
    }
    if (!message.trim()) {
      toast.error("Please enter a message.");
      return; // Stop the execution if this condition fails
    }
    if (!selectedDate || selectedDate < new Date()) {
      toast.error("Please select a valid date and time for the meeting.");
      return; // Stop the execution if this condition fails
    }
    const dataToSubmit = {
      sender:
        ownerType === IMTEX_EXHIBITOR
          ? {
              id: uid,
              username: uid,
              companyName: data.companyName.value,
              firstName: data.contactFirstName.value,
              lastName: data.contactLastName.value,
              email: data.companyEmail.value,
              hallNumber: data.hallNumber.value,
              standNumber: data.standNumber.value,
              profile_image: data.profile_image,
            }
          : {
              id: data.email,
              email: data.email,
              companyName: data.companyName,
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              profile_image: data.profile_image,
            },
      recipient:
        tType === IMTEX_EXHIBITOR
          ? {
              id: tid,
              username: tid,
              companyName: targetData.companyName.value,
              firstName: targetData.contactFirstName.value,
              lastName: targetData.contactLastName.value,
              email: targetData.companyEmail.value,
              hallNumber: data.hallNumber?.value,
              standNumber: data.standNumber?.value,
              profile_image: targetData.profile_image,
            }
          : {
              id: targetData.email,
              email: targetData.email,
              companyName: targetData.companyName,
              firstName: targetData.firstName,
              lastName: targetData.lastName,
              email: targetData.email,
              profile_image: targetData.profile_image,
            },
      data: {
        selectedDate,
        selectedMembers,
        meeting_type,
        duration,
        subject,
        message,
      },
    };
    try {
      setRequesting(true);
      const { data } = await axios.post(
        `${INTERACTION_API}schedule-meeting`,
        dataToSubmit,
        getCommonHeaderVE()
      );
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setRequesting(false);
    }
  };

  if (isLoading || tIsLoading) {
    return <MuPb />;
  }
  if (isError || tIsError) {
    return <Alert security="error">Some Error occured</Alert>;
  }

  /*
  
    19 to 23 Jan
    Time 10 am to 4pm
  */
  return (
    <ThemeProvider theme={ExpoTheme}>
      <Box p={2}>
        <Box sx={{ maxWidth: "1100px", ml: "auto", mr: "auto" }}>
          <BackBtn />
          <Typography fontWeight={"bold"} mt={2} variant="h5">
            Meeting Scheduler
          </Typography>
          <Box mt={2} container spacing={2}>
            <Grid item xs={12}>
              <Typography fontSize={"17px"} mb={1}>
                You are scheduling a meeting with
              </Typography>
              <Chip
                label={
                  tType === IMTEX_EXHIBITOR
                    ? targetData?.companyName.value
                    : `${targetData?.firstName} ${targetData?.lastName}`
                }
                onClick={() => {}}
                onDelete={() => {}}
                deleteIcon={<DoneIcon sx={{ color: "green" }} />}
              />
            </Grid>

            <Box>
              <Box ml={2} mt={2}>
                <Divider />
                <Typography mt={2} fontWeight={"bold"} variant="h5">
                  Meeting Data
                </Typography>
                <Typography fontSize={"17px"}>
                  Please fill the following information for the meeting
                </Typography>
              </Box>
            </Box>
            <Paper style={{ padding: 16 }}>
              <Grid container spacing={2}>
                {/* Left Block */}
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    {/* Location */}
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        disabled={ownerType !== IMTEX_EXHIBITOR}
                        value={selectedMembers}
                        options={membersData}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Add who will join from your side*"
                            placeholder="Add member"
                          />
                        )}
                        onChange={handleMembersChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Meeting Type*</InputLabel>
                        <Select
                          label="Meeting Type*"
                          value={meeting_type}
                          onChange={(e) => setMeetingType(e.target.value)}
                        >
                          <MenuItem disabled value="Online Meeting">
                            Online Meeting
                          </MenuItem>
                          <MenuItem value="OnSite Meeting">
                            OnSite Meeting
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* Duration */}
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="duration-label">
                          Duration of the meeting*
                        </InputLabel>
                        <Select
                          labelId="duration-label"
                          id="duration"
                          label="Duration of the meeting*"
                          value={duration}
                          onChange={(e) => setDuration(e.target.value)}
                        >
                          <MenuItem value={15}>15 mins</MenuItem>
                          <MenuItem value={30}>30 mins</MenuItem>
                          <MenuItem value={45}>45 mins</MenuItem>
                          <MenuItem value={60}>60 mins</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Right Block */}
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    {/* Subject */}
                    <Grid item xs={12}>
                      <TextField
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        fullWidth
                        label="Subject"
                        required
                      />
                    </Grid>
                    {/* Message */}
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Message"
                        required
                        multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        inputProps={{ maxLength: 200 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      minDateTime={minDateTime}
                      maxDateTime={maxDateTime}
                      sx={{ width: "100%", mt: 2 }}
                      label="Select Date and Time*"
                      value={selectedDate}
                      onChange={(newValue) => {
                        setSelectedDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Box
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"end"}
                  pt={2}
                  pb={2}
                >
                  <Button
                    onClick={handleSubmit}
                    startIcon={<CalendarMonthIcon />}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Send Request
                  </Button>
                </Box>
              </Grid>
            </Paper>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
export default MeetingScheduler;
