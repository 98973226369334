import React, { useEffect, useState } from "react";
import "./login.css";
import Header from "../header/header";
import DisplayNotification from "../notification/DisplayNotification";
import Footer from "../footer/footer";
import loginImg from "../../assets/login_img.png";
import RdLoadingButton from "../../widgets/RdLoadingButton";
import {
  Box,
  Button,
  Card,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import axios from "axios";
import {
  API_ENDPOINT,
  EXHIBITOR_API,
  EXHIBITOR_TOKEN,
  VISITOR_API,
} from "../../constants/constants";
import {
  saveExhibitor,
  saveExhibitorToken,
  saveUser,
} from "../../utility/utility";
import loginBanner from "../../assets/login_banner2.jpg";
import { LoadingButton } from "@mui/lab";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import imtexLogo from "../../assets/imtex_log_header_01.png";
import expoPlannerLogoH from "../../assets/exp_planner_logo_h.png";
import RdMyModal from "../../widgets/myModal/RdMyModal";
import PasswordReset from "./PasswordReset";
const Login = () => {
  const navigate = useNavigate();
  const EXHIBITOR = "exhibitor";
  const VISITOR = "visitor";
  const [currentLogin, setCurrentLogin] = useState(VISITOR);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [logging, setLogging] = useState(false);
  const [rememberChecked, setRememberChecked] = useState(false);

  const LOGIN_URL =
    currentLogin === VISITOR ? `${VISITOR_API}login` : `${EXHIBITOR_API}login`;

  const onExhibitorLogin = () => {
    setCurrentLogin(EXHIBITOR);
  };
  const onVisitorLogin = () => {
    setCurrentLogin(VISITOR);
  };
  const goToDashboard = (user) => {
    if (currentLogin === EXHIBITOR) {
      saveExhibitorToken(user);
      navigate(`/${EXHIBITOR}`);
    } else {
      saveUser(user);
      navigate(`/${VISITOR}`);
    }
  };
  const handleRegistrationClick = () => {
    navigate("/visitor-registration");
  };
  const onLogin = () => {
    if (username === "") {
      toast("Enter username");
    } else if (password === "") {
      toast("Enter password");
    } else {
      setLogging(true);
      axios
        .post(LOGIN_URL, { username: username, password: password })
        .then((res) => {
          setLogging(false);
          let d = res.data;
          if (d.success) {
            goToDashboard(d.message.user);
          } else {
            toast.error(d.message);
          }
        })
        .catch((error) => {
          setLogging(false);
          console.log(error.response);
        });
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const rememberPwd = () => {
    setRememberChecked((prev) => !prev);
  };
  const handleForgotPwd = () => {
    setShowPasswordReset(true);
  };
  return (
    <div className="expp-login-ca">
      <RdMyModal
        mWidth={"500px"}
        showModal={showPasswordReset}
        setShowModal={setShowPasswordReset}
        modalC={<PasswordReset setShow={setShowPasswordReset} />}
      />
      <div className="login-box">
        <Card
          sx={{
            borderRadius: 0,
            backgroundImage: `url(${loginBanner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className="login-flex-left"
        ></Card>
        <div className="login-flex-right">
          <div className="login-action-box">
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <div className="top-line">
                Welcome to <b>IMTEX 2024!</b>
              </div> */}
              <Box>
                <Box
                  sx={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: 600,
                    mb: 2,
                  }}
                >
                  Welcome to
                </Box>

                <img src={expoPlannerLogoH} alt="" />
              </Box>
            </div>
            {/* <div style={{ flex: 1 }}></div> */}
            {/* <img
              src={imtexLogo}
              alt=""
              style={{ display: "block", margin: "10px auto", width: "100%" }}
            /> */}

            <div className="login-select-c">
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "17px",
                  alignSelf: "center",
                }}
              >
                Login as
              </div>
              <div style={{ flex: 1, display: "flex", gap: "16px" }}>
                <Button
                  className={
                    currentLogin === VISITOR
                      ? "login-select-btn-visitor active"
                      : "login-select-btn-visitor"
                  }
                  onClick={onVisitorLogin}
                  size="small"
                  variant="outlined"
                >
                  Visitor
                </Button>
                <Button
                  className={
                    currentLogin === EXHIBITOR
                      ? "login-select-btn-exh active"
                      : "login-select-btn-exh"
                  }
                  onClick={onExhibitorLogin}
                  size="small"
                  variant="outlined"
                >
                  Exhibitor
                </Button>
              </div>
            </div>
            <div style={{ marginTop: "28px" }}>
              <TextField
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                variant="outlined"
                size="small"
                label={currentLogin === EXHIBITOR ? "User Id" : "Email"}
                fullWidth
              />
              <FormControl
                variant="outlined"
                size="small"
                required
                fullWidth
                sx={{ marginTop: 3 }}
              >
                <InputLabel htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="standard-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <div style={{ marginTop: "28px" }}>
                <div style={{ display: "flex" }}>
                  <FormControlLabel
                    sx={{
                      flex: 1,
                      color: "#777",
                      "& .MuiTypography-root": {
                        fontSize: "15px",
                      },
                      "& .MuiCheckbox-root": {
                        "& .MuiSvgIcon-root": {
                          fontSize: "18px", // Adjust the checkbox icon size
                        },
                      },
                    }}
                    label="Remember Password"
                    control={
                      <Checkbox
                        checked={rememberChecked}
                        onChange={rememberPwd}
                      />
                    }
                  />
                  <div
                    style={{
                      textDecoration: "underline",
                      fontSize: "15px",
                      alignSelf: "center",
                      display: "flex",
                      justifyContent: "end",
                      cursor: "pointer",
                      color: "#888",
                    }}
                    onClick={handleForgotPwd}
                  >
                    Forgot your password?
                  </div>
                </div>

                <div style={{ marginTop: "16px" }}>
                  <LoadingButton
                    onClick={onLogin}
                    loading={logging}
                    fullWidth
                    sx={{
                      backgroundColor: "#054054",
                      "&:hover": {
                        backgroundColor: "#0B6B6B", // A lighter shade of teal for hover
                      },
                    }}
                    variant="contained"
                  >
                    Sign in
                  </LoadingButton>
                </div>
                <Box sx={{ mt: 2 }}>
                  <LoadingButton
                    sx={{
                      backgroundColor: "#ed3436",
                      "&:hover": {
                        backgroundColor: "#d32f2f", // Adjust the color to your preference
                      },
                    }}
                    onClick={handleRegistrationClick}
                    fullWidth
                    variant="contained"
                  >
                    Visitor Registration
                  </LoadingButton>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
