import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Card,
  Divider,
  Alert,
  FormControl,
  InputLabel,
  Container,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import MuPb from "../../../../widgets/MuPb";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import { LoadingButton } from "@mui/lab";
import { EXHIBITOR_API } from "../../../../constants/constants";
import axios from "axios";
import { getExhibitorHeader } from "../../../../utility/utility";
import { toast } from "react-toastify";
import Supplier2 from "./reuse/Supplier2";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";
import { SubmittedStatus, oesMenus } from "./Services";
import { useParams } from "react-router-dom";

const UtilityDetails = () => {
  const { id } = useParams();
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();
  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [connections, setConnections] = useState([
    {
      title: "Power Connection",
      items: [
        { label: "Single Phase Indian Electric Power(KW)", value: 0 },
        { label: "Three Phase Indian Electric Power(KW)", value: 0 },
        { label: "Single Phase Foreign Electric Power(KW)", value: 0 },
        { label: "Three Phase Foreign Electric Power(KW)", value: 0 },
      ],
    },
    {
      title: "Compressed Air Connection",
      items: [
        { label: "Indian Compressed Air Connection", value: 0 },
        { label: "Foreign Compressed Air Connection", value: 0 },
      ],
    },
    {
      title: "Water Connection",
      items: [
        { label: "Indian Water Connection", value: 0 },
        { label: "Foreign Water Connection", value: 0 },
      ],
    },
  ]);
  if (isLoading) {
    return <MuPb />;
  }
  const handleValueChange = (sectionIdx, itemIdx, newValue) => {
    const updatedConnections = [...connections];
    updatedConnections[sectionIdx].items[itemIdx].value = newValue;
    setConnections(updatedConnections);
  };

  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Tempplate</title> <style>.box{margin-bottom: 16px; /* 2rem equivalent */ font-size: 18px;}strong{font-weight: bold;}</style> </head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div>${spaceTemplate(
      data
    )}<h1>Power Connection</h1> <hr/> <div class="box"> <p> <strong>Single Phase Indian Electric Power(KW):</strong> ${
      connections[0].items[0].value
    }</p><p> <strong>Three Phase Indian Electric Power(KW):</strong> ${
      connections[0].items[1].value
    }</p><p> <strong>Single Phase Foreign Electric Power(KW):</strong> ${
      connections[0].items[2].value
    }</p><p> <strong>Three Phase Foreign Electric Power(KW):</strong> ${
      connections[0].items[3].value
    }</p></div><h1>Compressed Air Connection</h1> <hr/> <div class="box"> <p> <strong>Indian Compressed Air Connection:</strong> ${
      connections[1].items[0].value
    }</p><p> <strong>Foreign Compressed Air Connection:</strong> ${
      connections[1].items[1].value
    }</p></div><h1>Water Connection</h1> <hr/> <div class="box"> <p> <strong>Indian Water Connection:</strong> ${
      connections[2].items[0].value
    }</p><p> <strong>Foreign Water Connection:</strong> ${
      connections[2].items[1].value
    }</p></div><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "Utility Details (Power, Air, Water)",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "Utility Details (Power, Air, Water)",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  return (
    <Container>
      <Card
        elevation={4}
        style={{
          padding: "24px",
          margin: "auto",
        }}
      >
        <Typography variant="h5" mb={1}>
          Utility Details (Power, Air, Water)
        </Typography>

        {/* <Box mb={4}>
          <Alert severity="warning">
            <Typography variant="subtitle1">
              Page not yet submitted, the deadline is 23 Dec, 2024.
            </Typography>
          </Alert>
        </Box> */}
        <SpaceInfo data={data} />
        <Box mt={3}>
          {connections.map((section, sectionIdx) => (
            <Box
              key={section.title}
              mt={sectionIdx > 0 ? 6 : 0}
              mb={3}
              p={2}
              border="1px solid grey"
              borderRadius="8px"
              position="relative"
            >
              <Typography
                variant="h6"
                style={{
                  position: "absolute",
                  top: "-12px",
                  left: "12px",
                  background: "white",
                  padding: "0 8px",
                }}
              >
                {section.title}
              </Typography>
              <Box display="flex" gap={2} justifyContent="space-between" mt={2}>
                {section.items.map((item, itemIdx) => (
                  <Box key={item.label} flex={1} width="23%">
                    <Typography variant="body1">{item.label}</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      type="number"
                      value={item.value}
                      onChange={(e) =>
                        handleValueChange(sectionIdx, itemIdx, e.target.value)
                      }
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
        <Supplier2
          user={data}
          id={3}
          supplier={supplier}
          setSupplier={setSupplier}
        />
        {/* Submit Button */}
        <Box mt={4} display="flex" justifyContent="center">
          <LoadingButton
            onClick={onSubmit}
            disabled={!supplier}
            loading={submitting}
            variant="contained"
            color="primary"
            type="submit"
          >
            Submit
          </LoadingButton>
        </Box>
      </Card>
    </Container>
  );
};

export default UtilityDetails;
