import React from "react";
import ComingSoon from "../exhibitor/ComingSoon";
import GeneralView from "../../floorplan/view/GeneralView";

const FloorPlan = () => {
  return (
    <div className="rd-dash-content">
      <GeneralView />
    </div>
  );
};

export default FloorPlan;
