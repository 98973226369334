import { Avatar, IconButton } from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TeamMembers from "../components/dashboard/exhibitor/profile/TeamMembers";
import ProductItemAction from "./actionBtn/productItemAction";

const TeamMember = ({ item, onEdit, onDelete, viewMode = false }) => {
  return (
    <div className="product-item team-member">
      {/* <img
        src={item.profile_image}
        alt={item.firstName}
        className="team-member-img"
      /> */}
      <Avatar
        sx={{ margin: "12px auto auto auto", height: "56px", width: "56px" }}
        alt={item.firstName}
        src={item.profile_image}
      />
      <div style={{ textAlign: "center" }} className="product-item-name">
        {item.firstName} {item.lastName}
      </div>
      <div
        style={{
          textAlign: "center",
          color: "#777",
          marginBottom: "12px",
          fontWeight: "500",
          fontSize: "14px",
        }}
      >
        {item.jobTitle}
      </div>
      {!viewMode && (
        <ProductItemAction
          onEdit={() => onEdit(item)}
          onDelete={() => onDelete(item)}
        />
      )}
    </div>
  );
};

export default TeamMember;
