import React from "react";
import { useParams } from "react-router-dom";
import MuPb from "../../../widgets/MuPb";
import { Alert, Box, Container, Grid } from "@mui/material";
import ProfileDetails from "./ProfileDetails";
import useVisitor from "../admin/hooks/useVisitor";
import BackBtn from "../admin/widgets/BackBtn";

const ProfileById = () => {
  const params = useParams();
  const { id } = params;
  const { data, isLoading, isError, error } = useVisitor(id);
  if (isLoading) return <MuPb />;
  if (isError) return <Alert severity="error">Error: {error.message}</Alert>;

  return (
    <Container>
      <Box mt={3} mb={3}>
        <BackBtn />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ProfileDetails user={data.data} setUser={{}} isView={true} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfileById;
