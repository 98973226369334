import {
  Box,
  Typography,
  ThemeProvider,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ExpoTheme } from "../../../theme/theme";
import RegHeader from "../../../visitorregt/components/headers/regHeader";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { API_ENDPOINT, EXHIBITOR_API } from "../../../constants/constants";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import MuPb from "../../../widgets/MuPb";
import BackBtn from "../../dashboard/admin/widgets/BackBtn";
import Footer from "../../footer/footer";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import AttachFile from "@mui/icons-material/AttachFile";
import { MuiTelInput } from "mui-tel-input";
import { EbadgeService } from "../../dashboard/visitor/EbadgeService";
const ServiceWelcomeMessage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [exhibitor, setExhibitor] = useState();

  useEffect(() => {
    document.title = "SERVICE BADGE";
  }, []);

  useEffect(() => {
    loadUser();
  }, []);
  async function loadUser() {
    setLoading(true);
    try {
      const response = await axios.get(
        EXHIBITOR_API + `get-service-badge-mobile?id=${id}`
      );
      if (response.status === 200) {
        setExhibitor(response.data);
      } else if (response.status === 204) {
        // Handle successful response with no content
        toast.info("No Exhibitor Found with Mobile number.");
      }
    } catch (error) {
      // Check if the error has a response object
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // If the response has a data object with a message, display it
        toast.error(error.response.data.message);
      } else {
        // Otherwise, fall back to a generic error message
        toast.error("An error occurred while registering. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <MuPb />;
  }

  return (
    <ThemeProvider theme={ExpoTheme}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <RegHeader />
        <Box mt={2} width={"100%"} maxWidth={500} ml={"auto"} mr={"auto"}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", mb: 2, textAlign: "center" }}
          >
            THANK YOU FOR REGISTERING. PLEASE SHOW THIS BADGE AT THE SECURITY TO
            ENTER.
          </Typography>
          {exhibitor && <EbadgeService user={exhibitor} isPreview={true} />}
        </Box>
        <Box
          sx={{
            mt: 4,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ServiceWelcomeMessage;
