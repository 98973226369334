import { IconButton } from '@mui/material';
import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TeamMembers from '../components/dashboard/exhibitor/profile/TeamMembers';
import ProductItemAction from './actionBtn/productItemAction';

const BrochureItem = ({
    item, onEdit, onDelete, onSelect, viewMode=false
}) => {
    return (
      <div
      className='product-item'
      onClick={()=>onSelect(item)}
      >
        <img 
              src={item.preview} 
              alt=""
              className='brochure-item-img'/>
        <div className='product-item-name'>
            {item.title}
        </div>
      {!viewMode && <ProductItemAction onEdit={()=>onEdit(item)} onDelete={()=>onDelete(item)} />}
      </div>
    );
};

export default BrochureItem