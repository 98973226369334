import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-number-input";
import CountriesCom from "../visitorregt/components/previewpage/CountriesCom";
import StatesCom from "../visitorregt/components/previewpage/StatesCom";
import CitiesCom from "../visitorregt/components/previewpage/CitiesCom";
import RegHeader from "../visitorregt/components/headers/regHeader";
import Footer from "../visitorregt/components/previewpage/Footer";
import { LoadingButton } from "@mui/lab";
import { ExpoTheme } from "../theme/theme";
import { toast } from "react-toastify";
import { isValidEmail } from "../visitorregt/utils/utils";
import { API_ENDPOINT } from "../constants/constants";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const StudentRegistration = () => {
  const [registering, setRegistering] = useState(false);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    name: "",
    education: "",
    department: "",
    email: "",
    mobile: "",

    instituteName: "",
    address: "",
    selectedCountry: "",
    selectedState: "",
    selectedCity: "",
    pin: "",
  });
  const handleClose = () => {
    setOpen(false);
  };
  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };
  const handleStateChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const onClickSubmit = async () => {
    if (state.name === "") {
      toast.info("Please enter title");
      let titleField = document.getElementsByName("name")[0];
      titleField.scrollIntoView({ behavior: "smooth", block: "center" });
      titleField.parentElement.parentElement.style.border = "1px solid red";
      setTimeout(() => {
        titleField.parentElement.parentElement.style.border = ""; // Revert to the original style
      }, 3000);
      return;
    } else if (state.education === "") {
      toast.info("Please select education");
      let titleField = document.getElementsByName("education")[0];
      titleField.scrollIntoView({ behavior: "smooth", block: "center" });
      titleField.parentElement.parentElement.style.border = "1px solid red";
      setTimeout(() => {
        titleField.parentElement.parentElement.style.border = ""; // Revert to the original style
      }, 3000);
      return;
    } else if (state.department === "") {
      toast("Please enter Department");
      let titleField = document.getElementsByName("department")[0];
      titleField.scrollIntoView({ behavior: "smooth", block: "center" });
      titleField.parentElement.parentElement.style.border = "1px solid red";
      setTimeout(() => {
        titleField.parentElement.parentElement.style.border = ""; // Revert to the original style
      }, 3000);
      return;
    } else if (state.mobile === "" || state.mobile.length < 10) {
      let titleField = document.getElementsByName("mobile")[0];
      titleField.scrollIntoView({ behavior: "smooth", block: "center" });
      titleField.parentElement.parentElement.style.border = "1px solid red";
      setTimeout(() => {
        titleField.parentElement.parentElement.style.border = ""; // Revert to the original style
      }, 3000);
      toast.info("Please enter valid mobile number");
      return;
    } else if (!isValidEmail(state.email)) {
      toast.info("Please enter Email");
      let titleField = document.getElementsByName("email")[0];
      titleField.scrollIntoView({ behavior: "smooth", block: "center" });
      titleField.parentElement.parentElement.style.border = "1px solid red";
      setTimeout(() => {
        titleField.parentElement.parentElement.style.border = ""; // Revert to the original style
      }, 3000);
      return;
    } else {
      setRegistering(true);
      const dataToUpload = { ...state };
      dataToUpload.user_type = "SELF_REG_STUDENT";
      try {
        const { data } = await axios.post(
          API_ENDPOINT + "visitor/spot-register",
          dataToUpload
        );
        setOpen(true);
        //toast.success(data.message);
        const user = data.user;
        //handlePrint(user);
        setState({
          name: "",
          education: "",
          department: "",
          email: "",
          mobile: "",

          instituteName: "",
          address: "",
          selectedCountry: "",
          selectedState: "",
          selectedCity: "",
          pin: "",
        });
      } catch (error) {
        // Check if the error has a response object
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          // If the response has a data object with a message, display it
          toast.error(error.response.data.message);
        } else {
          // Otherwise, fall back to a generic error message
          toast.error("An error occurred while registering. Please try again.");
        }
      } finally {
        setRegistering(false);
      }
    }
  };

  return (
    <ThemeProvider theme={ExpoTheme}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <RegHeader />
        <Box width={"100%"} maxWidth={1200} ml={"auto"} mr={"auto"}>
          <Typography
            mt={3}
            textAlign={"center"}
            color={"rgb(151, 42, 76)"}
            fontWeight={"bold"}
            fontSize={"28px"}
          >
            Student Registration
          </Typography>
          <Box>
            <div className="visitor-form-container">
              <div className="visitor-items-h">Basic Information</div>
              <div className="visitor-items-c">
                <div className="visitor-items-first">
                  <TextField
                    required
                    size="small"
                    name="name"
                    value={state.name}
                    onChange={handleInputChange}
                    label="Student Name"
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="visitor-items-sec">
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Education*
                    </InputLabel>
                    <Select
                      value={state.education}
                      name="education"
                      label="Education"
                      onChange={handleInputChange}
                    >
                      {["Diploma", "BE/BTech", "ME/MTech", "Others"].map(
                        (position, i) => (
                          <MenuItem key={i} value={position}>
                            {position}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="visitor-items-c">
                <div className="visitor-items-first" id="department">
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Department*
                    </InputLabel>
                    <Select
                      value={state.department}
                      name="department"
                      label="Department"
                      onChange={handleInputChange}
                    >
                      {[
                        "ITI",
                        "Mechanical",
                        "E&C",
                        "EEE",
                        "Industrial Production",
                        "Aeronautical",
                        "Automobile",
                        "Others",
                      ].map((position, i) => (
                        <MenuItem key={i} value={position}>
                          {position}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div
                  className="visitor-items-sec"
                  style={{ position: "relative" }}
                >
                  {state.mobile && state.mobile.length > 0 && (
                    <label
                      style={{
                        position: "absolute",
                        top: "-12px",
                        left: "56px",
                        color: "#1876d1",
                        fontSize: "14px",
                        backgroundColor: "white",
                      }}
                      htmlFor="phone-input"
                    >
                      Mobile number*
                    </label>
                  )}
                  <PhoneInput
                    defaultCountry="IN"
                    placeholder="Mobile number*"
                    required
                    name="mobile"
                    value={state.mobile}
                    onChange={(phone) => handleStateChange("mobile", phone)}
                  />
                </div>
              </div>
              <div className="visitor-items-c">
                <div className="visitor-items-first">
                  <TextField
                    size="small"
                    name="email"
                    value={state.email}
                    onChange={handleInputChange}
                    label="Email*"
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="visitor-items-sec"></div>
              </div>
            </div>
            <div className="visitor-form-container">
              <div className="visitor-items-h">Institution Information</div>
              <div className="visitor-items-c">
                <div className="visitor-items-first">
                  <TextField
                    size="small"
                    name="instituteName"
                    value={state.instituteName}
                    onChange={handleInputChange}
                    label="Institute Name*"
                    variant="outlined"
                    fullWidth
                  />
                </div>
                <div className="visitor-items-sec">
                  <TextField
                    size="small"
                    name="address"
                    value={state.address}
                    onChange={handleInputChange}
                    label="Address"
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
              <div className="visitor-items-c">
                <div className="visitor-items-first" id="selectedCountry">
                  <CountriesCom
                    state={state}
                    handleStateChange={handleStateChange}
                    label="Select Country"
                  />
                </div>
                <div className="visitor-items-sec" id="selectedState">
                  <StatesCom
                    state={state}
                    handleStateChange={handleStateChange}
                    label="Select State"
                  />
                </div>
              </div>
              <div className="visitor-items-c">
                <div className="visitor-items-first" id="selectedCity">
                  <CitiesCom
                    state={state}
                    handleStateChange={handleStateChange}
                    label="Select City"
                  />
                </div>
                <div className="visitor-items-sec" id="pin">
                  <TextField
                    size="small"
                    name="pin"
                    value={state.pin}
                    onChange={handleInputChange}
                    label="Pin/Zip"
                    variant="outlined"
                    fullWidth
                  />
                </div>
              </div>
            </div>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{"Thank You for Registering!"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Your registration has been successfully completed.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <Box textAlign={"center"} mt={4} mb={2}>
              <LoadingButton
                loading={registering}
                sx={{ width: 200 }}
                onClick={onClickSubmit}
                variant="contained"
              >
                Submit
              </LoadingButton>
            </Box>
          </Box>
        </Box>
        <Box mt={4}>
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default StudentRegistration;
