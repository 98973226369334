import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import useCities from "../../../../../visitorregt/components/previewpage/hooks/useCities";

function CitiesSelect({ state, handleStateChange }) {
  const { data, isLoading, isError, error } = useCities(
    state.country ? state.country : "",
    state.state ? state.state : ""
  );
  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">Select City*</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name="city"
        value={state.city}
        label="Select City*"
        onChange={handleInputChange}
      >
        {data &&
          data.map((target, i) => (
            <MenuItem key={i} value={target.name}>
              {target.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default CitiesSelect;
