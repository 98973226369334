import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

function RdDepartment({ state, handleStateChange }) {
  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">Department*</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={state.department}
        name="department"
        label="Department*"
        onChange={handleInputChange}
      >
        {positions.map((position) => (
          <MenuItem key={position} value={position}>
            {position}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
const positions = [
  "Administration",
  "Design",
  "Engineering",
  "Finance",
  "HR",
  "IT",
  "Management",
  "Operations",
  "Production",
  "Production / Manufacturing",
  "Quality",
  "Safety",
  "Sales / Marketing / Servicing",
  "Training",
];

export default RdDepartment;
