import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RdDesignationsSelect from "../../../../../visitorregt/components/previewpage/RdDesignationsSelect";
import PhoneInput from "react-phone-number-input";
import {
  TITLES,
  getExhibitorHeader,
  getHeader,
} from "../../../../../utility/utility";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { isValidEmail } from "../../../../../visitorregt/utils/utils";
import axios from "axios";
import { EXHIBITOR_API, VISITOR_API } from "../../../../../constants/constants";
import CountriesCom from "../../../../../visitorregt/components/previewpage/CountriesCom";
import StatesCom from "../../../../../visitorregt/components/previewpage/StatesCom";
import CitiesCom from "../../../../../visitorregt/components/previewpage/CitiesCom";
import StatesSelect from "./StatesSelect";
import CitiesSelect from "./CitiesSelect";
import CountriesSelect from "./CountriesSelect";

const IndustrySectorEdit = ({ user, onUpdated, show, setShow }) => {
  const [selectedSectors, setSelectedSectors] = useState([]);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    setSelectedSectors(user.industrySector.value);
  }, [show]);
  const update = async () => {
    const toUpdateObject = {
      industrySector: {
        label: user.industrySector.label,
        value: selectedSectors,
      },
    };
    try {
      const { data } = await axios.post(
        `${EXHIBITOR_API}update-exhibitor`,
        toUpdateObject,
        getExhibitorHeader()
      );
      setSaving(false);
      setShow(false);
      toast.success(data.message);
      onUpdated();
    } catch (error) {
      setSaving(false);
      if (error.response) {
        // The request was made and the server responded with a status code outside the range of 2xx
        console.error("Error Data:", error.response.data);
        console.error("Error Status:", error.response.status);
        console.error("Error Headers:", error.response.headers);
        toast.error(
          `Error: ${error.response.data.message || "Something went wrong"}`
        );
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error Request:", error.request);
        toast.error("No response from server. Please try again later.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message);
        toast.error("An error occurred. Please try again.");
      }
    }
  };
  return (
    <Container>
      <Typography
        mb={2}
        fontSize={18}
        color={"#444"}
        fontWeight={600}
        variant="body1"
      >
        Update Industry Sector
      </Typography>
      <Autocomplete
        multiple
        id="tags-standard"
        options={industrySectorsList}
        value={selectedSectors}
        onChange={(event, newValue) => setSelectedSectors(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Industry Sector"
            placeholder="Select"
          />
        )}
      />

      <LoadingButton
        loading={saving}
        onClick={update}
        sx={{
          mt: 4,
          ml: "auto",
          mr: "auto",
          display: "block",
          mb: 2,
          width: 120,
        }}
        variant="contained"
      >
        Save
      </LoadingButton>
    </Container>
  );
};
const industrySectorsList = [
  "Academia and Technical Institutions",
  "Aerospace / Civil Aviation",
  "Agriculture",
  "Aluminium Extrusion",
  "Auto Components",
  "Automation",
  "Automobile",
  "Biotechnology",
  "Capital Goods",
  "Chemicals",
  "Consumer Durables",
  "Defence and Railway Units",
  "Die & Mould",
  "Earthmoving Equipment",
  "Electrical and Electronics",
  "Food Processing and Diary Equipment",
  "General Engineering",
  "Government Department",
  "Healthcare",
  "Industrial Machinery",
  "Industry 4.0",
  "Infrastructure & Earth Moving Equipment",
  "IT Industry",
  "Job Shops",
  "Logistics",
  "Machine Tools & Other Metal working Industries",
  "Maintenance & Services",
  "Material Handling",
  "Measuring Instruments & Gauges",
  "Medical Engineering",
  "Office Equipment and automation",
  "Oil & Gas equipment",
  "Pharma Equipment",
  "Plant and Machinery",
  "Plastics Processing",
  "Polymers(Rubber Plastic PVC Resin Poly)",
  "Public Sector",
  "Renewable Energy",
  "Research and development organisations",
  "Space and Nuclear",
  "Telecom Equipment",
  "Textile Machinery & Products",
  "Tractors and Farm Equipment",
  "White and Brown Goods Manufacturers",
];

export default IndustrySectorEdit;
