import React, { useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Hidden,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  ListItemButton,
  Button,
  ThemeProvider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import DateRangeIcon from "@mui/icons-material/DateRange";
import StorageIcon from "@mui/icons-material/Storage";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import GroupIcon from "@mui/icons-material/Group";
import { deleteAdmin, isAdminLoggedIn } from "../../../../utility/utility";
import { useNavigate } from "react-router-dom";
import { ExpoTheme } from "../../../../theme/theme";

function Header() {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    if (!isAdminLoggedIn()) navigate("/admin/login");
  }, []);

  const onLogout = () => {
    deleteAdmin();
    document.location.reload();
  };

  return (
    <>
      <ThemeProvider theme={ExpoTheme}>
        <AppBar position="static">
          <Toolbar>
            {/* Company Logo */}
            <img
              src="https://www.imtex.in/images/imtex-2024.png"
              alt="Company Logo"
              style={{ height: "40px", marginRight: "8px" }}
            />
            <Box sx={{ flex: 1, display: "flex", gap: 2 }}>
              <Typography
                sx={{ alignSelf: "center", fontWeight: 550, ml: 2 }}
                variant="h6"
                component="div"
              >
                <Hidden smDown implementation="css">
                  Organiser Dashboard
                </Hidden>
                <Hidden mdUp implementation="css">
                  Admin
                </Hidden>
              </Typography>
              <Hidden smDown implementation="css">
                <Box display={"flex"} gap={2}>
                  <Button
                    color="lightprimary"
                    variant="contained"
                    onClick={() => navigate("/admin/floor-plan")}
                  >
                    Floor Plan
                  </Button>
                  <Button
                    color="lightprimary"
                    variant="contained"
                    onClick={() => navigate("/admin/exhibitors")}
                  >
                    Exhibitors
                  </Button>
                </Box>
              </Hidden>
              {/* <Button
              variant="contained"
              onClick={() => navigate("/admin/allocate-hall")}
            >
              Allocate Hall
            </Button> */}
            </Box>

            <Hidden smDown implementation="css">
              <Box
                sx={{ display: "flex", justifyContent: "flex-start", gap: 2 }}
              >
                <Tooltip title="Events">
                  <IconButton color="inherit">
                    <DateRangeIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Database">
                  <IconButton color="inherit">
                    <StorageIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Apps">
                  <IconButton color="inherit">
                    <PhoneIphoneIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Accounts">
                  <IconButton color="inherit">
                    <GroupIcon />
                  </IconButton>
                </Tooltip>
                <Typography
                  sx={{ alignSelf: "center" }}
                  variant="h6"
                  component="div"
                >
                  Admin
                </Typography>
                <Tooltip title="Profile">
                  <IconButton color="inherit">
                    <AccountCircle />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Logout">
                  <IconButton onClick={onLogout} color="inherit">
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Hidden>
            <Hidden mdUp implementation="css">
              <Tooltip title="Menu">
                <IconButton
                  color="inherit"
                  edge="end"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Tooltip>
            </Hidden>
          </Toolbar>
        </AppBar>
      </ThemeProvider>

      {/* Drawer for smaller screens */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        <List>
          <ListItemButton>
            <ListItemIcon>
              <DateRangeIcon />
            </ListItemIcon>
            <ListItemText primary="Events" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <StorageIcon />
            </ListItemIcon>
            <ListItemText primary="Database" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <PhoneIphoneIcon />
            </ListItemIcon>
            <ListItemText primary="Apps" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Accounts" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemButton>
          <ListItemButton onClick={onLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
}

export default Header;
