import { Button } from '@mui/material';
import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
const AddBtn = ({onClick, label}) => {
    return (
        <Button
        variant='contained'
      size='small'
      onClick={onClick}
     sx={{backgroundColor: '#dbedff', 
     color: '#3098c4',
     height: '28px',
     paddingRight: '8px',
     textTransform: 'none',
     borderRadius: '14px',
     boxShadow: '0 0px 1px rgba(0, 0, 0, 0.1)',
     '&:hover': {
       backgroundColor: '#b9d5ef',
       boxShadow: '0 4px 7px rgba(0, 0, 0, 0.15)'
     }}}
      endIcon={<AddCircleOutlineIcon />}
    >
      {label}
    </Button>
    );
};

export default AddBtn;