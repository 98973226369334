import React from "react";
import expoFooterImg from "../../assets/expo_footer.png";
import "./footer.css";
const Footer = () => {
  return (
    <div className="expp-main-footer-c">
      <img src={expoFooterImg} alt="Expo Footer" className="footer-logo" />
      <div className="footer-text">© IMTMA 2024. All rights reserved</div>
    </div>
  );
};

export default Footer;
