import axios from "axios";
import { getCommonHeaderVE } from "../../../../utility/utility";
import { INTERACTION_API } from "../../../../constants/constants";
import { useQuery } from "react-query";
const fetchItems = (id) => {
  return axios
    .get(
      `${INTERACTION_API}get-my-notifications?id=${id}`,
      getCommonHeaderVE()
    )
    .then((res) => res.data)
    .catch((error) => {
      throw error.response.data;
    });
};

const fetchNotifications = (id) => {
  if (id === null) {
    return Promise.resolve(null);
  }
  return fetchItems(id);
};

const useNotifications = (id) => {
  const queryInfo = useQuery(
    ["get-notifications", id],
    () => fetchNotifications(id), // Correctly invoking fetchNotifications
    {
      staleTime: 0,
      retry: 1,
      enabled: id !== null,
    }
  );

  return {
    ...queryInfo,
    refetchNotifications: queryInfo.refetch, // Extract refetch function from queryInfo
  };
};

export default useNotifications;
